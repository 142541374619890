<template>
  <v-container
    fluid
    fill-height
    class="back"
    :style="{ backgroundImage: `url('${getURL}')` }"
  >
    <v-row id="content">
      <v-col cols="12">
        <v-row>
          <h1
            v-html="$t('home.title')"
            :class="{
              'margin-small': $vuetify.breakpoint.mdAndUp,
              'white--text': !imageLoading,
              'primary--text': imageLoading,
            }"
          ></h1>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <p
            v-html="$t('home.description')"
            :class="{
              'margin-small': $vuetify.breakpoint.mdAndUp,
              'white--text': !imageLoading,
              'black--text': imageLoading,
            }"
          ></p>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-btn
            depressed
            tile
            v-html="$t('home.sawCalcButton')"
            :to="{ name: 'SawCalc' }"
            class="white primary--text"
            :class="{ 'margin-small': $vuetify.breakpoint.mdAndUp }"
          ></v-btn>
        </v-row>
        <v-row v-if="!user" class="mt-5">
          <v-btn
            depressed
            tile
            v-html="$t('home.loginButton')"
            class="primary white--text"
            :class="{ 'margin-small': $vuetify.breakpoint.mdAndUp }"
            @click="login()"
          ></v-btn>
          <span
            v-html="$t('home.or')"
            class="pa-0 ml-5 mt-1"
            :class="{
              'margin-small mt-4': $vuetify.breakpoint.mdAndUp,
              'white--text': !imageLoading,
              'black--text': imageLoading,
            }"
          ></span>
          <v-btn
            depressed
            tile
            v-html="$t('home.registerButton')"
            class="primary white--text ml-4"
            :class="{ 'margin-small': $vuetify.breakpoint.mdAndUp }"
            @click="register()"
          ></v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Home",

  computed: {
    ...mapGetters(["images", "user"]),
    getURL() {
      if (this.images.home.cover.data == null) {
        return this.images.home.cover.defaultURL;
      } else {
        return this.images.home.cover.data;
      }
    },
    imageLoading() {
      if (this.images.home.cover.data == null) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    login() {
      this.$store.dispatch("showLoginDialog", true);
    },
    register() {
      this.$store.dispatch("showRegisterDialog", true);
    },
  },
};
</script>

<style lang="scss" scoped>
#content {
  margin: 0 0 0 10px;
}

#content h1 {
  font-family: map-get($theme, font-family-primary), sans-serif;
  font-weight: map-get($theme, font-weight-primary-title);
  font-size: map-get($theme, font-size-primary-title);
  line-height: 34px;
  color: map-get($theme, contrast-color);
  text-transform: uppercase;
  letter-spacing: 0px;
}

#content p {
  width: 335px;
  font-family: map-get($theme, font-family-secondary), sans-serif;
  font-weight: map-get($theme, font-weight-secondary);
  font-size: map-get($theme, font-size-secondary);
  line-height: 22px;
  color: map-get($theme, contrast-color);
  margin-bottom: 10px;
}

.back {
  background-size: cover;
}

.primary--text {
  font-family: map-get($theme, font-family-secondary), sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 34px;
  text-transform: none;
  letter-spacing: 0px;
}

.margin-small {
  margin: 10px 0 0 60px;
}
</style>
