import { navigation } from "@/constants/Navigation";
import { footer } from "@/constants/Footer";
import { recommendations } from "@/constants/Recommendations";
import i18n from "@/plugins/i18n";
import { machineDetails } from "@/helpers/MachineDetails";
import { imageConversion } from "@/helpers/ImageConversion";
import { calculations } from "@/helpers/Calculations";

export default {
  SET_DEFAULT_LANGUAGE(state, language) {
    const json = require("@/locales/" + language.locale + ".json");
    state.language.messages = json;
  },
  SET_LANGUAGES(state, list) {
    if (!list || list.length == 0) {
      state.languages = [];
      return;
    }

    let languages = list.languages.map((o) => ({
      id: o.LanguageID,
      flag: o.Flag,
      locale: o.Locale,
      title: o.Title,
      uom: o.UOM,
      messages: null,
    }));

    state.languages = languages;

    state.navigation.toolbar[navigation.toolbar.Language].items =
      state.languages;
  },
  SET_LANG(state, language) {
    if (state.languages.find((item) => item.locale === language.locale)) {
      state.language = language;

      const lang = state.languages.find(
        (item) => item.locale === language.locale
      );

      if (lang) {
        lang.messages = language.messages;
        i18n.setLocaleMessage(
          language.locale,
          language.messages.default || language.messages
        );
      }

      i18n.locale = language.locale;
    }
  },

  UPDATE_NAVIGATION_BY_LANG(state, language) {
    const json = language.messages;

    state.navigation.toolbar[navigation.toolbar.Title].title =
      json.navigation.toolbar.Title;
    state.navigation.toolbar[navigation.toolbar.Phone].title =
      json.navigation.toolbar.Phone;
    state.navigation.toolbar[
      navigation.toolbar.Phone
    ].path = json.navigation.toolbar.Phone.replace(/[^0-9]/g, "");
    state.navigation.toolbar[navigation.toolbar.Login].title =
      json.navigation.toolbar.Login;
    state.navigation.toolbar[navigation.toolbar.Logoff].title =
      json.navigation.toolbar.Logoff;

    // Public

    state.navigation.tabs[navigation.tabs.Home].title =
      json.navigation.tabs.Home;
    state.navigation.tabs[navigation.tabs.SawCalc].title =
      json.navigation.tabs.SawCalc;
    state.navigation.tabs[navigation.tabs.BandSawGuide].title =
      json.navigation.tabs.BandSawGuide;
    state.navigation.tabs[navigation.tabs.BandSawGuide].items[
      navigation.tabs.BladeTerminology
    ].title = json.navigation.tabs.BladeTerminology;
    state.navigation.tabs[navigation.tabs.BandSawGuide].items[
      navigation.tabs.BladeConstruction
    ].title = json.navigation.tabs.BladeConstruction;
    state.navigation.tabs[navigation.tabs.BandSawGuide].items[
      navigation.tabs.ToothOptions
    ].title = json.navigation.tabs.ToothOptions;
    state.navigation.tabs[navigation.tabs.BandSawGuide].items[
      navigation.tabs.CuttingFactors
    ].title = json.navigation.tabs.CuttingFactors;
    state.navigation.tabs[navigation.tabs.BandSawGuide].items[
      navigation.tabs.BeamStrength
    ].title = json.navigation.tabs.BeamStrength;
    state.navigation.tabs[navigation.tabs.BandSawGuide].items[
      navigation.tabs.Lubrication
    ].title = json.navigation.tabs.Lubrication;
    state.navigation.tabs[navigation.tabs.BandSawGuide].items[
      navigation.tabs.BreakInInstructions
    ].title = json.navigation.tabs.BreakInInstructions;
    state.navigation.tabs[navigation.tabs.BandSawGuide].items[
      navigation.tabs.BasicMaintenanceTips
    ].title = json.navigation.tabs.BasicMaintenanceTips;
    state.navigation.tabs[navigation.tabs.BandSawGuide].items[
      navigation.tabs.CommonProblems
    ].title = json.navigation.tabs.CommonProblems;
    state.navigation.tabs[navigation.tabs.BandSawGuide].items[
      navigation.tabs.GlossaryOfBandsawTerms
    ].title = json.navigation.tabs.GlossaryOfBandsawTerms;
    state.navigation.tabs[navigation.tabs.LENOXAdvantage].title =
      json.navigation.tabs.LENOXAdvantage;

    state.navigation.side[navigation.side.Home].title =
      json.navigation.tabs.Home;
    state.navigation.side[navigation.side.SawCalc].title =
      json.navigation.tabs.SawCalc;
    state.navigation.side[navigation.side.BandSawGuide].title =
      json.navigation.tabs.BandSawGuide;
    state.navigation.side[navigation.side.BandSawGuide].items[
      navigation.side.BladeTerminology
    ].title = json.navigation.tabs.BladeTerminology;
    state.navigation.side[navigation.side.BandSawGuide].items[
      navigation.side.BladeConstruction
    ].title = json.navigation.tabs.BladeConstruction;
    state.navigation.side[navigation.side.BandSawGuide].items[
      navigation.side.ToothOptions
    ].title = json.navigation.tabs.ToothOptions;
    state.navigation.side[navigation.side.BandSawGuide].items[
      navigation.side.CuttingFactors
    ].title = json.navigation.tabs.CuttingFactors;
    state.navigation.side[navigation.side.BandSawGuide].items[
      navigation.side.BeamStrength
    ].title = json.navigation.tabs.BeamStrength;
    state.navigation.side[navigation.side.BandSawGuide].items[
      navigation.side.Lubrication
    ].title = json.navigation.tabs.Lubrication;
    state.navigation.side[navigation.side.BandSawGuide].items[
      navigation.side.BreakInInstructions
    ].title = json.navigation.tabs.BreakInInstructions;
    state.navigation.side[navigation.side.BandSawGuide].items[
      navigation.side.BasicMaintenanceTips
    ].title = json.navigation.tabs.BasicMaintenanceTips;
    state.navigation.side[navigation.side.BandSawGuide].items[
      navigation.side.CommonProblems
    ].title = json.navigation.tabs.CommonProblems;
    state.navigation.side[navigation.side.BandSawGuide].items[
      navigation.side.GlossaryOfBandsawTerms
    ].title = json.navigation.tabs.GlossaryOfBandsawTerms;
    state.navigation.side[navigation.side.LENOXAdvantage].title =
      json.navigation.tabs.LENOXAdvantage;
    state.navigation.side[navigation.side.Phone].title =
      json.navigation.toolbar.Phone;
    state.navigation.side[
      navigation.side.Phone
    ].path = json.navigation.toolbar.Phone.replace(/[^0-9]/g, "");
    state.navigation.side[navigation.side.Login].title =
      json.navigation.toolbar.Login;

    // User

    state.navigation.tabsUser[navigation.tabsUser.Dashboard].title =
      json.navigation.tabs.Dashboard;
    state.navigation.tabsUser[navigation.tabsUser.SawCalc].title =
      json.navigation.tabs.SawCalc;
    state.navigation.tabsUser[navigation.tabsUser.Library].title =
      json.navigation.tabs.Library;
    state.navigation.tabsUser[navigation.tabsUser.Library].items[
      navigation.tabsUser.Machines
    ].title = json.navigation.tabs.Machines;
    state.navigation.tabsUser[navigation.tabsUser.Library].items[
      navigation.tabsUser.Materials
    ].title = json.navigation.tabs.Materials;
    // TODO: add more to menu
    state.navigation.tabsUser[navigation.tabsUser.BandSawGuide].title =
      json.navigation.tabs.BandSawGuide;
    state.navigation.tabsUser[navigation.tabsUser.BandSawGuide].items[
      navigation.tabsUser.BladeTerminology
    ].title = json.navigation.tabs.BladeTerminology;
    state.navigation.tabsUser[navigation.tabsUser.BandSawGuide].items[
      navigation.tabsUser.BladeConstruction
    ].title = json.navigation.tabs.BladeConstruction;
    state.navigation.tabsUser[navigation.tabsUser.BandSawGuide].items[
      navigation.tabsUser.ToothOptions
    ].title = json.navigation.tabs.ToothOptions;
    state.navigation.tabsUser[navigation.tabsUser.BandSawGuide].items[
      navigation.tabsUser.CuttingFactors
    ].title = json.navigation.tabs.CuttingFactors;
    state.navigation.tabsUser[navigation.tabsUser.BandSawGuide].items[
      navigation.tabsUser.BeamStrength
    ].title = json.navigation.tabs.BeamStrength;
    state.navigation.tabsUser[navigation.tabsUser.BandSawGuide].items[
      navigation.tabsUser.Lubrication
    ].title = json.navigation.tabs.Lubrication;
    state.navigation.tabsUser[navigation.tabsUser.BandSawGuide].items[
      navigation.tabsUser.BreakInInstructions
    ].title = json.navigation.tabs.BreakInInstructions;
    state.navigation.tabsUser[navigation.tabsUser.BandSawGuide].items[
      navigation.tabsUser.BasicMaintenanceTips
    ].title = json.navigation.tabs.BasicMaintenanceTips;
    state.navigation.tabsUser[navigation.tabsUser.BandSawGuide].items[
      navigation.tabsUser.CommonProblems
    ].title = json.navigation.tabs.CommonProblems;
    state.navigation.tabsUser[navigation.tabsUser.BandSawGuide].items[
      navigation.tabsUser.GlossaryOfBandsawTerms
    ].title = json.navigation.tabs.GlossaryOfBandsawTerms;
    state.navigation.tabsUser[navigation.tabsUser.LENOXAdvantage].title =
      json.navigation.tabs.LENOXAdvantage;
    state.navigation.tabsUser[navigation.tabsUser.Account].title =
      json.navigation.tabs.Account;

    state.navigation.sideUser[navigation.sideUser.Home].title =
      json.navigation.tabs.Home;
    state.navigation.sideUser[navigation.sideUser.Dashboard].title =
      json.navigation.tabs.Dashboard;
    state.navigation.sideUser[navigation.sideUser.Library].title =
      json.navigation.tabs.Library;
    state.navigation.sideUser[navigation.sideUser.Library].items[
      navigation.sideUser.Machines
    ].title = json.navigation.tabs.Machines;
    state.navigation.sideUser[navigation.sideUser.Library].items[
      navigation.sideUser.Materials
    ].title = json.navigation.tabs.Materials;
    // // TODO: add more to menu
    state.navigation.sideUser[navigation.sideUser.SawCalc].title =
      json.navigation.tabs.SawCalc;
    state.navigation.sideUser[navigation.sideUser.BandSawGuide].title =
      json.navigation.tabs.BandSawGuide;
    state.navigation.sideUser[navigation.sideUser.BandSawGuide].items[
      navigation.sideUser.BladeTerminology
    ].title = json.navigation.tabs.BladeTerminology;
    state.navigation.sideUser[navigation.sideUser.BandSawGuide].items[
      navigation.sideUser.BladeConstruction
    ].title = json.navigation.tabs.BladeConstruction;
    state.navigation.sideUser[navigation.sideUser.BandSawGuide].items[
      navigation.sideUser.ToothOptions
    ].title = json.navigation.tabs.ToothOptions;
    state.navigation.sideUser[navigation.sideUser.BandSawGuide].items[
      navigation.sideUser.CuttingFactors
    ].title = json.navigation.tabs.CuttingFactors;
    state.navigation.sideUser[navigation.sideUser.BandSawGuide].items[
      navigation.sideUser.BeamStrength
    ].title = json.navigation.tabs.BeamStrength;
    state.navigation.sideUser[navigation.sideUser.BandSawGuide].items[
      navigation.sideUser.Lubrication
    ].title = json.navigation.tabs.Lubrication;
    state.navigation.sideUser[navigation.sideUser.BandSawGuide].items[
      navigation.sideUser.BreakInInstructions
    ].title = json.navigation.tabs.BreakInInstructions;
    state.navigation.sideUser[navigation.sideUser.BandSawGuide].items[
      navigation.sideUser.BasicMaintenanceTips
    ].title = json.navigation.tabs.BasicMaintenanceTips;
    state.navigation.sideUser[navigation.sideUser.BandSawGuide].items[
      navigation.sideUser.CommonProblems
    ].title = json.navigation.tabs.CommonProblems;
    state.navigation.sideUser[navigation.sideUser.BandSawGuide].items[
      navigation.sideUser.GlossaryOfBandsawTerms
    ].title = json.navigation.tabs.GlossaryOfBandsawTerms;
    state.navigation.sideUser[navigation.sideUser.LENOXAdvantage].title =
      json.navigation.tabs.LENOXAdvantage;
    state.navigation.sideUser[navigation.sideUser.Account].title =
      json.navigation.tabs.Account;
    state.navigation.sideUser[navigation.sideUser.Phone].title =
      json.navigation.toolbar.Phone;
    state.navigation.sideUser[
      navigation.sideUser.Phone
    ].path = json.navigation.toolbar.Phone.replace(/[^0-9]/g, "");
    state.navigation.sideUser[navigation.sideUser.Logoff].title =
      json.navigation.toolbar.Logoff;

    // Admin

    state.navigation.tabsAdmin[navigation.tabsAdmin.Dashboard].title =
      json.navigation.tabs.Dashboard;
    state.navigation.tabsAdmin[navigation.tabsAdmin.SawCalc].title =
      json.navigation.tabs.SawCalc;
    state.navigation.tabsAdmin[navigation.tabsAdmin.Library].title =
      json.navigation.tabs.Library;
    state.navigation.tabsAdmin[navigation.tabsAdmin.Library].items[
      navigation.tabsAdmin.Machines
    ].title = json.navigation.tabs.Machines;
    state.navigation.tabsAdmin[navigation.tabsAdmin.Library].items[
      navigation.tabsAdmin.Materials
    ].title = json.navigation.tabs.Materials;
    state.navigation.tabsAdmin[navigation.tabsAdmin.Library].items[
      navigation.tabsAdmin.ProductLines
    ].title = json.navigation.tabs.ProductLines;
    state.navigation.tabsAdmin[navigation.tabsAdmin.Library].items[
      navigation.tabsAdmin.Recommendations
    ].title = json.navigation.tabs.Recommendations;
    state.navigation.tabsAdmin[navigation.tabsAdmin.Library].items[
      navigation.tabsAdmin.EngineParameters
    ].title = json.navigation.tabs.EngineParameters;
    state.navigation.tabsAdmin[navigation.tabsAdmin.Library].items[
      navigation.tabsAdmin.DictionaryUI
    ].title = json.navigation.tabs.DictionaryUI;
    state.navigation.tabsAdmin[navigation.tabsAdmin.Library].items[
      navigation.tabsAdmin.DictionaryWS
    ].title = json.navigation.tabs.DictionaryWS;
    state.navigation.tabsAdmin[navigation.tabsAdmin.Library].items[
      navigation.tabsAdmin.Translation
    ].title = json.navigation.tabs.Translation;
    // TODO: add more to menu
    state.navigation.tabsAdmin[navigation.tabsAdmin.BandSawGuide].title =
      json.navigation.tabs.BandSawGuide;
    state.navigation.tabsAdmin[navigation.tabsAdmin.BandSawGuide].items[
      navigation.tabsAdmin.BladeTerminology
    ].title = json.navigation.tabs.BladeTerminology;
    state.navigation.tabsAdmin[navigation.tabsAdmin.BandSawGuide].items[
      navigation.tabsAdmin.BladeConstruction
    ].title = json.navigation.tabs.BladeConstruction;
    state.navigation.tabsAdmin[navigation.tabsAdmin.BandSawGuide].items[
      navigation.tabsAdmin.ToothOptions
    ].title = json.navigation.tabs.ToothOptions;
    state.navigation.tabsAdmin[navigation.tabsAdmin.BandSawGuide].items[
      navigation.tabsAdmin.CuttingFactors
    ].title = json.navigation.tabs.CuttingFactors;
    state.navigation.tabsAdmin[navigation.tabsAdmin.BandSawGuide].items[
      navigation.tabsAdmin.BeamStrength
    ].title = json.navigation.tabs.BeamStrength;
    state.navigation.tabsAdmin[navigation.tabsAdmin.BandSawGuide].items[
      navigation.tabsAdmin.Lubrication
    ].title = json.navigation.tabs.Lubrication;
    state.navigation.tabsAdmin[navigation.tabsAdmin.BandSawGuide].items[
      navigation.tabsAdmin.BreakInInstructions
    ].title = json.navigation.tabs.BreakInInstructions;
    state.navigation.tabsAdmin[navigation.tabsAdmin.BandSawGuide].items[
      navigation.tabsAdmin.BasicMaintenanceTips
    ].title = json.navigation.tabs.BasicMaintenanceTips;
    state.navigation.tabsAdmin[navigation.tabsAdmin.BandSawGuide].items[
      navigation.tabsAdmin.CommonProblems
    ].title = json.navigation.tabs.CommonProblems;
    state.navigation.tabsAdmin[navigation.tabsAdmin.BandSawGuide].items[
      navigation.tabsAdmin.GlossaryOfBandsawTerms
    ].title = json.navigation.tabs.GlossaryOfBandsawTerms;
    state.navigation.tabsAdmin[navigation.tabsAdmin.LENOXAdvantage].title =
      json.navigation.tabs.LENOXAdvantage;
    state.navigation.tabsAdmin[navigation.tabsAdmin.Account].title =
      json.navigation.tabs.Account;

    state.navigation.sideAdmin[navigation.sideAdmin.Home].title =
      json.navigation.tabs.Home;
    state.navigation.sideAdmin[navigation.sideAdmin.Dashboard].title =
      json.navigation.tabs.Dashboard;
    state.navigation.sideAdmin[navigation.sideAdmin.Library].title =
      json.navigation.tabs.Library;
    state.navigation.sideAdmin[navigation.sideAdmin.Library].items[
      navigation.sideAdmin.Machines
    ].title = json.navigation.tabs.Machines;
    state.navigation.sideAdmin[navigation.sideAdmin.Library].items[
      navigation.sideAdmin.Materials
    ].title = json.navigation.tabs.Materials;
    state.navigation.sideAdmin[navigation.sideAdmin.Library].items[
      navigation.sideAdmin.ProductLines
    ].title = json.navigation.tabs.ProductLines;
    state.navigation.sideAdmin[navigation.sideAdmin.Library].items[
      navigation.sideAdmin.Recommendations
    ].title = json.navigation.tabs.Recommendations;
    state.navigation.sideAdmin[navigation.sideAdmin.Library].items[
      navigation.sideAdmin.EngineParameters
    ].title = json.navigation.tabs.EngineParameters;
    state.navigation.tabsAdmin[navigation.tabsAdmin.Library].items[
      navigation.tabsAdmin.DictionaryUI
    ].title = json.navigation.tabs.DictionaryUI;
    state.navigation.tabsAdmin[navigation.tabsAdmin.Library].items[
      navigation.tabsAdmin.DictionaryWS
    ].title = json.navigation.tabs.DictionaryWS;
    state.navigation.tabsAdmin[navigation.tabsAdmin.Library].items[
      navigation.tabsAdmin.Translation
    ].title = json.navigation.tabs.Translation;
    // // TODO: add more to menu
    state.navigation.sideAdmin[navigation.sideAdmin.SawCalc].title =
      json.navigation.tabs.SawCalc;
    state.navigation.sideAdmin[navigation.sideAdmin.BandSawGuide].title =
      json.navigation.tabs.BandSawGuide;
    state.navigation.sideAdmin[navigation.sideAdmin.BandSawGuide].items[
      navigation.sideAdmin.BladeTerminology
    ].title = json.navigation.tabs.BladeTerminology;
    state.navigation.sideAdmin[navigation.sideAdmin.BandSawGuide].items[
      navigation.sideAdmin.BladeConstruction
    ].title = json.navigation.tabs.BladeConstruction;
    state.navigation.sideAdmin[navigation.sideAdmin.BandSawGuide].items[
      navigation.sideAdmin.ToothOptions
    ].title = json.navigation.tabs.ToothOptions;
    state.navigation.sideAdmin[navigation.sideAdmin.BandSawGuide].items[
      navigation.sideAdmin.CuttingFactors
    ].title = json.navigation.tabs.CuttingFactors;
    state.navigation.sideAdmin[navigation.sideAdmin.BandSawGuide].items[
      navigation.sideAdmin.BeamStrength
    ].title = json.navigation.tabs.BeamStrength;
    state.navigation.sideAdmin[navigation.sideAdmin.BandSawGuide].items[
      navigation.sideAdmin.Lubrication
    ].title = json.navigation.tabs.Lubrication;
    state.navigation.sideAdmin[navigation.sideAdmin.BandSawGuide].items[
      navigation.sideAdmin.BreakInInstructions
    ].title = json.navigation.tabs.BreakInInstructions;
    state.navigation.sideAdmin[navigation.sideAdmin.BandSawGuide].items[
      navigation.sideAdmin.BasicMaintenanceTips
    ].title = json.navigation.tabs.BasicMaintenanceTips;
    state.navigation.sideAdmin[navigation.sideAdmin.BandSawGuide].items[
      navigation.sideAdmin.CommonProblems
    ].title = json.navigation.tabs.CommonProblems;
    state.navigation.sideAdmin[navigation.sideAdmin.BandSawGuide].items[
      navigation.sideAdmin.GlossaryOfBandsawTerms
    ].title = json.navigation.tabs.GlossaryOfBandsawTerms;
    state.navigation.sideAdmin[navigation.sideAdmin.LENOXAdvantage].title =
      json.navigation.tabs.LENOXAdvantage;
    state.navigation.sideAdmin[navigation.sideAdmin.Account].title =
      json.navigation.tabs.Account;
    state.navigation.sideAdmin[navigation.sideAdmin.Phone].title =
      json.navigation.toolbar.Phone;
    state.navigation.sideAdmin[
      navigation.sideAdmin.Phone
    ].path = json.navigation.toolbar.Phone.replace(/[^0-9]/g, "");
    state.navigation.sideAdmin[navigation.sideAdmin.Logoff].title =
      json.navigation.toolbar.Logoff;
  },
  UPDATE_TOOLBAR_NAVIGATION_BY_USER(state) {
    if (!state.user) {
      state.navigation.toolbar[navigation.toolbar.Login].visible = true;
      state.navigation.toolbar[navigation.toolbar.Logoff].visible = false;
    } else {
      state.navigation.toolbar[navigation.toolbar.Login].visible = false;
      state.navigation.toolbar[navigation.toolbar.Logoff].visible = true;
    }
  },
  UPDATE_FOOTER_BY_LANG(state, language) {
    const json = language.messages;

    state.footer.statusBar[footer.statusBar.TermsOfUse].title =
      json.footer.statusBar.TermsOfUse;
    state.footer.statusBar[footer.statusBar.PrivacyPolicy].title =
      json.footer.statusBar.PrivacyPolicy;
    state.footer.statusBar[footer.statusBar.CookieSettings].title =
      json.footer.statusBar.CookieSettings;

    state.footer.statusBar[footer.statusBar.Copywrite].title =
      json.footer.statusBar.Copywrite;

    state.footer.info.contact[footer.info.contact.LenoxTools].title =
      json.footer.info.contact.LenoxTools;
    state.footer.info.contact[footer.info.contact.Contact].title =
      json.footer.info.contact.Contact;
  },

  // Recommendation
  // Inputs

  SET_INPUT_MACHINE(state, payload) {
    if (payload == null) {
      payload = {};
      state.machine = {};
    }

    let inputMachine = state.input.machine;

    if (payload.MachineID) {
      inputMachine.userMachineID = payload.UserMachineID;
      inputMachine.machineID = payload.MachineID;
      inputMachine.machineName = payload.MachineName;
      inputMachine.manufacturerID = payload.ManufacturerID;
      inputMachine.manufacturerName = payload.ManufacturerName;
      inputMachine.model = payload.Model;
      inputMachine.bladeWidthIn = payload.BladeWidthIn;
      inputMachine.bladeWidthFr = payload.BladeWidthFr;
      inputMachine.bladeWidthMetric = payload.BladeWidthMetric;
      inputMachine.bladeThicknessImperial = payload.BladeThicknessImperial;
      inputMachine.bladeThicknessMetric = payload.BladeThicknessMetric;
      inputMachine.bladeLengthFt = payload.BladeLengthFt;
      inputMachine.bladeLengthIn = payload.BladeLengthIn;
      inputMachine.bladeLengthFr = payload.BladeLengthFr;
      inputMachine.bladeLengthImperial = payload.BladeLengthImperial;
      inputMachine.bladeLengthMetric = payload.BladeLengthMetric;
      inputMachine.hortizontal = payload.Hortizontal;
      inputMachine.scissorPivot = payload.ScissorPivot;
      inputMachine.cantedHead = payload.CantedHead;
      inputMachine.bandSpeedMinImperial = payload.BandSpeedMinImperial;
      inputMachine.bandSpeedMaxImperial = payload.BandSpeedMaxImperial;
      inputMachine.bandSpeedMinMetric = payload.BandSpeedMinMetric;
      inputMachine.bandSpeedMaxMetric = payload.BandSpeedMaxMetric;
    } else {
      inputMachine.userMachineID = payload.userMachineID;
      inputMachine.machineID = payload.machineID;
      inputMachine.machineName = payload.machineName;
      inputMachine.manufacturerID = payload.manufacturerID;
      inputMachine.manufacturerName = payload.manufacturerName;
      inputMachine.model = payload.model;
      inputMachine.bladeWidthIn = payload.bladeWidthIn;
      inputMachine.bladeWidthFr = payload.bladeWidthFr;
      inputMachine.bladeWidthMetric = payload.bladeWidthMetric;
      inputMachine.bladeThicknessImperial = payload.bladeThicknessImperial;
      inputMachine.bladeThicknessMetric = payload.bladeThicknessMetric;
      inputMachine.bladeLengthFt = payload.bladeLengthFt;
      inputMachine.bladeLengthIn = payload.bladeLengthIn;
      inputMachine.bladeLengthFr = payload.bladeLengthFr;
      inputMachine.bladeLengthImperial = payload.bladeLengthImperial;
      inputMachine.bladeLengthMetric = payload.bladeLengthMetric;

      if (payload.machineOrientation != undefined) {
        inputMachine.hortizontal = payload.machineOrientation == 0;
      } else {
        inputMachine.hortizontal = payload.hortizontal;
      }
      
      inputMachine.scissorPivot = payload.scissorPivot;
      inputMachine.cantedHead = payload.cantedHead;
      inputMachine.bandSpeedMinImperial = payload.bandSpeedMinImperial;
      inputMachine.bandSpeedMaxImperial = payload.bandSpeedMaxImperial;
      inputMachine.bandSpeedMinMetric = payload.bandSpeedMinMetric;
      inputMachine.bandSpeedMaxMetric = payload.bandSpeedMaxMetric;
    }
  },
  SET_SAVED_INPUT_MACHINE(state, payload) {
    if (payload == null) {
      payload = {};
    }

    const inputMachine = state.savedInput.machine;

    inputMachine.machineID = payload.machineID;
    inputMachine.machineName = payload.machineName;
    inputMachine.manufacturerID = payload.manufacturerID;
    inputMachine.manufacturerName = payload.manufacturerName;
    inputMachine.model = payload.model;
    inputMachine.bladeWidthIn = payload.BladeWidthIn;
    inputMachine.bladeWidthFr = payload.BladeWidthFr;
    inputMachine.bladeWidthMetric = payload.BladeWidthMetric;
    inputMachine.bladeThicknessImperial = payload.BladeThicknessImperial;
    inputMachine.bladeThicknessMetric = payload.BladeThicknessMetric;
    inputMachine.bladeLengthFt = payload.BladeLengthFt;
    inputMachine.bladeLengthIn = payload.BladeLengthIn;
    inputMachine.bladeLengthFr = payload.BladeLengthFr;
    inputMachine.bladeLengthImperial = payload.BladeLengthImperial;
    inputMachine.bladeLengthMetric = payload.BladeLengthMetric;
    inputMachine.hortizontal = payload.Hortizontal;
    inputMachine.scissorPivot = payload.ScissorPivot;
    inputMachine.cantedHead = payload.CantedHead;
    inputMachine.bandSpeedMinImperial = payload.BandSpeedMinImperial;
    inputMachine.bandSpeedMaxImperial = payload.BandSpeedMaxImperial;
    inputMachine.bandSpeedMinMetric = payload.BandSpeedMinMetric;
    inputMachine.bandSpeedMaxMetric = payload.BandSpeedMaxMetric;
  },
  SET_INPUT_MATERIAL(state, payload) {
    if (payload == null) {
      payload = {};
      state.material = {};
      state.scale = {};
    }

    const inputMaterial = state.input.material;

    inputMaterial.userMaterialID = payload.userMaterialID;
    inputMaterial.materialID = payload.materialID;
    inputMaterial.designation = payload.designation;
    inputMaterial.materialName = payload.materialName;
    inputMaterial.materialStandardID = payload.materialStandardID;
    inputMaterial.materialStandard = payload.materialStandard;
    inputMaterial.uns = payload.uns;
    inputMaterial.unsCategoryID = payload.unsCategoryID;
    inputMaterial.unsCategory = payload.unsCategory;
    inputMaterial.unsAlloyGroupID = payload.unsAlloyGroupID;
    inputMaterial.unsAlloyGroup = payload.unsAlloyGroup;
    inputMaterial.sawCalcGroupID = payload.sawCalcGroupID;
    inputMaterial.sawCalcGroupName = payload.sawCalcGroupName;
    inputMaterial.sawCalcGroupDescription = payload.sawCalcGroupDescription;
    inputMaterial.machinabilityGroupBiMetal = payload.machinabilityGroupBiMetal;
    inputMaterial.machinabilityGroupCarbide = payload.machinabilityGroupCarbide;
    inputMaterial.machinabilityRating = payload.machinabilityRating;
    inputMaterial.machinability = payload.machinability;
    inputMaterial.defaultHBHardness = payload.defaultHBHardness;
    inputMaterial.compositeUNS = payload.compositeUNS;
    inputMaterial.unsGeneralDescriptionID = payload.unsGeneralDescriptionID;
    inputMaterial.unsGeneralDescription = payload.unsGeneralDescription;
    inputMaterial.subCategoryID = payload.subCategoryID;
    inputMaterial.subCategory = payload.subCategory;
    inputMaterial.quickSearch = payload.quickSearch;
    inputMaterial.orderInList = payload.orderInList;
    inputMaterial.chemicalComposition = payload.chemicalComposition;
    inputMaterial.scale = payload.scale;
    inputMaterial.hardness = payload.hardness;
    inputMaterial.hardnessDescription = payload.hardnessDescription;
    inputMaterial.heavyScaleForged = payload.heavyScaleForged;
    inputMaterial.htQuenched = payload.htQuenched;

    if (inputMaterial.htQuenched) {
      inputMaterial.hardnessDescription += " H/T";
    }
  },
  SET_SAVED_INPUT_MATERIAL(state, payload) {
    if (payload == null) {
      payload = {};
    }

    const inputMaterial = state.savedInput.material;

    inputMaterial.materialID = payload.materialID;
    inputMaterial.designation = payload.designation;
    inputMaterial.materialName = payload.materialName;
    inputMaterial.materialStandardID = payload.materialStandardID;
    inputMaterial.materialStandard = payload.materialStandard;
    inputMaterial.uns = payload.uns;
    inputMaterial.unsCategoryID = payload.unsCategoryID;
    inputMaterial.unsCategory = payload.unsCategory;
    inputMaterial.unsAlloyGroupID = payload.unsAlloyGroupID;
    inputMaterial.unsAlloyGroup = payload.unsAlloyGroup;
    inputMaterial.sawCalcGroupID = payload.sawCalcGroupID;
    inputMaterial.sawCalcGroupName = payload.sawCalcGroupName;
    inputMaterial.sawCalcGroupDescription = payload.sawCalcGroupDescription;
    inputMaterial.machinabilityGroupBiMetal = payload.machinabilityGroupBiMetal;
    inputMaterial.machinabilityGroupCarbide = payload.machinabilityGroupCarbide;
    inputMaterial.machinabilityRating = payload.machinabilityRating;
    inputMaterial.machinability = payload.machinability;
    inputMaterial.defaultHBHardness = payload.defaultHBHardness;
    inputMaterial.compositeUNS = payload.compositeUNS;
    inputMaterial.unsGeneralDescriptionID = payload.unsGeneralDescriptionID;
    inputMaterial.unsGeneralDescription = payload.unsGeneralDescription;
    inputMaterial.subCategoryID = payload.subCategoryID;
    inputMaterial.subCategory = payload.subCategory;
    inputMaterial.quickSearch = payload.quickSearch;
    inputMaterial.orderInList = payload.orderInList;
    inputMaterial.chemicalComposition = payload.chemicalComposition;
    inputMaterial.scale = payload.scale;
    inputMaterial.hardness = payload.hardness;
    inputMaterial.hardnessDescription = payload.hardnessDescription;
    inputMaterial.heavyScaleForged = payload.heavyScaleForged;
    inputMaterial.htQuenched = payload.htQuenched;

    if (inputMaterial.htQuenched) {
      inputMaterial.hardnessDescription += " H/T";
    }
  },
  SET_INPUT_DIMENSIONS(state, payload) {
    state.input.dimensions = {};

    if (!payload) {
      return;
    }

    const inputDimensions = state.input.dimensions;

    inputDimensions.shape = payload.dimensions.shape;
    inputDimensions.dimensionDetails = payload.dimensions.dimensionDetails;
    inputDimensions.rotated = payload.dimensions.rotated;
    inputDimensions.measurement = payload.dimensions.measurement;
  },
  SET_INPUT_VISING(state, payload) {
    state.input.vising = {};

    if (!payload) {
      return;
    }

    const inputVising = state.input.vising;

    inputVising.quantity = payload.vising.quantity;
    inputVising.arrangement = payload.vising.arrangement;
  },
  SET_INPUT_CONDITIONS(state, payload) {
    state.input.conditions = {};

    if (!payload) {
      return;
    }

    const inputConditions = state.input.conditions;

    inputConditions.lubrication = payload.conditions.lubrication;
    inputConditions.pinching = payload.conditions.pinching;
    inputConditions.canting = payload.conditions.canting;
  },
  CHANGE_INPUT_UOM(state, metric) {
    state.input.metric = metric;
    state.input.inputUOM = metric ? 1 : 0;
  },
  CHANGE_INPUT_CURRENCY(state, currency) {
    state.input.currency = currency;
    state.input.inputCurrency = currency ? currency : 0;
  },
  RESET_INPUTS(state) {
    state.input.resetInputs++;
  },

  // Machines

  SHOW_MACHINE_SELECTOR_DIALOG(state, show) {
    state.machineSelectorDialog.show = show;
  },
  SET_MACHINE_MANUFACTURERS(state, list) {
    if (!list || list.length == 0) {
      state.manufacturers = [];
      state.machines = [];
      return;
    }

    let manufacturers = list.map((o) => ({
      manufacturerID: o.ManufacturerID,
      manufacturerName: o.ManufacturerName,
    }));

    let myMap = new Map();

    manufacturers = manufacturers.filter((el) => {
      const val = myMap.get(el.manufacturerName);
      if (val) {
        if (el.manufacturerID < val) {
          myMap.delete(el.manufacturerName);
          myMap.set(el.manufacturerName, el.manufacturerID);
          return true;
        } else {
          return false;
        }
      }
      myMap.set(el.manufacturerName, el.manufacturerID);
      return true;
    });

    manufacturers.sort(function(a, b) {
      if (!a.manufacturerName || !b.manufacturerName) {
        return 0;
      }

      var x = a.manufacturerName.toLowerCase();
      var y = b.manufacturerName.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    state.manufacturers = manufacturers;

    let machines = list.map((o) => ({
      machineID: o.MachineID,
      manufacturerID: o.ManufacturerID,
      model: o.Model,
    }));

    machines.sort(function(a, b) {
      if (!a.model || !b.model) {
        return 0;
      }

      var x = a.model.toLowerCase();
      var y = b.model.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    state.machines = machines;
  },
  SET_MANUFACTURERS(state, list) {
    if (!list || list.length == 0) {
      state.manufacturers = [];
      return;
    }

    state.manufacturers = list;
  },
  SET_MACHINES(state, list) {
    if (!list || list.length == 0) {
      state.machines = [];
      return;
    }

    state.machines = list;
  },
  SET_MACHINE(state, payload) {
    if (payload) {
      state.machine = payload;
    } else {
      state.machine = {};
    }
  },
  SET_USER_MACHINES(state, list) {
    if (!list || list.length == 0) {
      state.userMachines = [];
      return;
    }

    if (list[0].UserMachineID == undefined) {
      state.userMachines = list;
    } else {
      let machines = list.map((o) => ({
        userMachineID: o.UserMachineID,
        userID: o.UserID,
        machineID: o.MachineID,
        machineName: o.MachineName,
        imageID: o.ImageID,
        manufacturerID: o.ManufacturerID,
        manufacturerName: o.ManufacturerName,
        model: o.Model,
        bladeWidthIn: o.BladeWidthIn,
        bladeWidthFr: o.BladeWidthFr,
        bladeWidthMetric: o.BladeWidthMetric,
        bladeThicknessImperial: o.BladeThicknessImperial,
        bladeThicknessMetric: o.BladeThicknessMetric,
        bladeLengthFt: o.BladeLengthFt,
        bladeLengthIn: o.BladeLengthIn,
        bladeLengthFr: o.BladeLengthFr,
        bladeLengthImperial: o.BladeLengthImperial,
        bladeLengthMetric: o.BladeLengthMetric,
        hortizontal: o.Hortizontal,
        scissorPivot: o.ScissorPivot,
        cantedHead: o.CantedHead,
        bandSpeedMinImperial: o.BandSpeedMinImperial,
        bandSpeedMaxImperial: o.BandSpeedMaxImperial,
        bandSpeedMinMetric: o.BandSpeedMinMetric,
        bandSpeedMaxMetric: o.BandSpeedMaxMetric,

        fileName: o.FileName,

        details: "",
      }));

      machines.forEach((item) => {
        item.bladeWidthImperialDesc = machineDetails.getBladeWidth(item, false);
        item.bladeWidthMetricDesc = machineDetails.getBladeWidth(item, true);
        item.bladeThicknessImperialDesc = machineDetails.getBladeThickness(
          item,
          false
        );
        item.bladeThicknessMetricDesc = machineDetails.getBladeThickness(
          item,
          true
        );
        item.bladeLengthImperialDesc = machineDetails.getBladeLength(
          item,
          false
        );
        item.bladeLengthMetricDesc = machineDetails.getBladeLength(item, true);
        item.machineOrientationDesc = machineDetails.getMachineOrientation(
          item,
          this.state.language.messages
        );
        item.scissorPivotDesc = machineDetails.getScissorPivot(
          item,
          this.state.language.messages
        );
        item.cantedHeadDesc = machineDetails.getCantedHead(
          item,
          this.state.language.messages
        );
        item.bandSpeedMinImperialDesc = machineDetails.getBandSpeed(
          item,
          false,
          true
        );
        item.bandSpeedMaxImperialDesc = machineDetails.getBandSpeed(
          item,
          false,
          false
        );
        item.bandSpeedMinMetricDesc = machineDetails.getBandSpeed(
          item,
          true,
          true
        );
        item.bandSpeedMaxMetricDesc = machineDetails.getBandSpeed(
          item,
          true,
          false
        );
      });

      machines.sort(function(a, b) {
        if (!a.model || !b.model) {
          return 0;
        }

        var x = a.model.toLowerCase();
        var y = b.model.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      state.userMachines = machines;
    }
  },
  SET_USER_MACHINE(state, payload) {
    let userMachine = state.userMachines.find(
      (item) => item.userMachineID === payload.userMachineID
    );

    if (userMachine) {
      userMachine.manufacturerID = payload.manufacturerID;
      userMachine.manufacturerName = payload.manufacturerName;
      userMachine.model = payload.model;
      userMachine.bladeWidthIn = payload.bladeWidthIn;
      userMachine.bladeWidthFr = payload.bladeWidthFr;
      userMachine.bladeWidthMetric = payload.bladeWidthMetric;
      userMachine.bladeThicknessImperial = payload.bladeThicknessImperial;
      userMachine.bladeThicknessMetric = payload.bladeThicknessMetric;
      userMachine.bladeLengthFt = payload.bladeLengthFt;
      userMachine.bladeLengthIn = payload.bladeLengthIn;
      userMachine.bladeLengthImperial = payload.bladeLengthImperial;
      userMachine.bladeLengthMetric = payload.bladeLengthMetric;
      userMachine.hortizontal = payload.hortizontal;
      userMachine.scissorPivot = payload.scissorPivot;
      userMachine.cantedHead = payload.cantedHead;
      userMachine.bandSpeedMinImperial = payload.bandSpeedMinImperial;
      userMachine.bandSpeedMaxImperial = payload.bandSpeedMaxImperial;
      userMachine.bandSpeedMinMetric = payload.bandSpeedMinMetric;
      userMachine.bandSpeedMaxMetric = payload.bandSpeedMaxMetric;

      userMachine.bladeWidthImperialDesc = payload.bladeWidthImperialDesc;
      userMachine.bladeWidthMetricDesc = payload.bladeWidthMetricDesc;
      userMachine.bladeThicknessImperialDesc =
        payload.bladeThicknessImperialDesc;
      userMachine.bladeThicknessMetricDesc = payload.bladeThicknessMetricDesc;
      userMachine.bladeLengthImperialDesc = payload.bladeLengthImperialDesc;
      userMachine.bladeLengthMetricDesc = payload.bladeLengthMetricDesc;
      userMachine.machineOrientationDesc = payload.machineOrientationDesc;
      userMachine.scissorPivotDesc = payload.scissorPivotDesc;
      userMachine.cantedHeadDesc = payload.cantedHeadDesc;
      userMachine.machineConditionDesc = payload.machineConditionDesc;
      userMachine.bandSpeedMinImperialDesc = payload.bandSpeedMinImperialDesc;
      userMachine.bandSpeedMaxImperialDesc = payload.bandSpeedMaxImperialDesc;
      userMachine.bandSpeedMinMetricDesc = payload.bandSpeedMinMetricDesc;
      userMachine.bandSpeedMaxMetricDesc = payload.bandSpeedMaxMetricDesc;

      userMachine.manufacturerID = payload.manufacturerID;
      userMachine.manufacturerName = payload.manufacturerName;
      userMachine.model = payload.model;
      userMachine.bladeWidthIn = payload.bladeWidthIn;
      userMachine.bladeWidthFr = payload.bladeWidthFr;
      userMachine.bladeWidthMetric = payload.bladeWidthMetric;
      userMachine.bladeThicknessImperial = payload.bladeThicknessImperial;
      userMachine.bladeThicknessMetric = payload.bladeThicknessMetric;
      userMachine.bladeLengthFt = payload.bladeLengthFt;
      userMachine.bladeLengthIn = payload.bladeLengthIn;
      userMachine.bladeLengthImperial = payload.bladeLengthImperial;
      userMachine.bladeLengthMetric = payload.bladeLengthMetric;
      userMachine.hortizontal = payload.hortizontal;
      userMachine.scissorPivot = payload.scissorPivot;
      userMachine.cantedHead = payload.cantedHead;
      userMachine.bandSpeedMinImperial = payload.bandSpeedMinImperial;
      userMachine.bandSpeedMaxImperial = payload.bandSpeedMaxImperial;
      userMachine.bandSpeedMinMetric = payload.bandSpeedMinMetric;
      userMachine.bandSpeedMaxMetric = payload.bandSpeedMaxMetric;

      userMachine.defined = payload.defined;
    }
  },
  SET_BLADE_WIDTH_IMPERIAL_LIST(state, list) {
    if (!list || list.length == 0) {
      state.bladeWidthImperialList = [];
      return;
    }

    if ("Text" in list[0]) {
      let lst = list.map((o) => ({
        text: o.Text,
        value: o.Value,
      }));

      state.bladeWidthImperialList = lst;
    } else {
      state.bladeWidthImperialList = list;
    }
  },
  SET_BLADE_WIDTH_METRIC_LIST(state, list) {
    if (list) {
      state.bladeWidthMetricList = list;
    } else {
      state.bladeWidthMetricList = [];
    }
  },
  SET_BLADE_THICKNESS_IMPERIAL_LIST(state, list) {
    if (list) {
      state.bladeThicknessImperialList = list;
    } else {
      state.bladeThicknessImperialList = [];
    }
  },
  SET_BLADE_THICKNESS_METRIC_LIST(state, list) {
    if (list) {
      state.bladeThicknessMetricList = list;
    } else {
      state.bladeThicknessMetricList = [];
    }
  },
  SET_BLADE_LENGTH_FT_LIST(state, list) {
    if (list) {
      state.bladeLengthFtList = list;
    } else {
      state.bladeLengthFtList = [];
    }
  },
  SET_BLADE_LENGTH_IN_LIST(state, list) {
    if (list) {
      state.bladeLengthInList = list;
    } else {
      state.bladeLengthInList = [];
    }
  },
  SET_BLADE_LENGTH_FR_LIST(state, list) {
    if (!list || list.length == 0) {
      state.bladeLengthFrList = [];
      return;
    }

    if ("Text" in list[0]) {
      let lst = list.map((o) => ({
        text: o.Text,
        value: o.Value,
      }));

      state.bladeLengthFrList = lst;
    } else {
      state.bladeLengthFrList = list;
    }
  },
  SET_BLADE_LENGTH_METRIC_LIST(state, list) {
    if (list) {
      state.bladeLengthMetricList = list;
    } else {
      state.bladeLengthMetricList = [];
    }
  },
  RELOAD_MACHINE_IMPERIAL_COMPONENT(state) {
    state.unknownMachineImperialKey += 1;
  },
  RELOAD_MACHINE_METRIC_COMPONENT(state) {
    state.unknownMachineMetricKey += 1;
  },
  SET_MACHINES_READY(state, ready) {
    state.machinesReady = ready;
  },

  // Materials

  SHOW_MATERIAL_SELECTOR_DIALOG(state, show) {
    state.materialSelectorDialog.show = show;
  },
  SET_MATERIAL_STANDARDS(state, list) {
    if (!list || list.length == 0) {
      state.materialStandards = [];
      return;
    }

    if ("ID" in list[0]) {
      let materialStandards = list.map((o) => ({
        id: o.ID,
        value: o.Value,
        orderInList: o.OrderInList,
      }));

      state.materialStandards = materialStandards;
    } else {
      state.materialStandards = list;
    }
  },
  SET_UNS_CATEGORIES(state, list) {
    if (!list || list.length == 0) {
      state.unsCategories = [];
      return;
    }

    if ("ID" in list[0]) {
      let unsCategories = list.map((o) => ({
        id: o.ID,
        value: o.Value,
        orderInList: o.OrderInList,
      }));

      state.unsCategories = unsCategories;
    } else {
      state.unsCategories = list;
    }
  },
  SET_UNS_ALLOY_GROUPS(state, list) {
    if (!list || list.length == 0) {
      state.unsAlloyGroups = [];
      return;
    }

    if ("ID" in list[0]) {
      let unsAlloyGroups = list.map((o) => ({
        id: o.ID,
        value: o.Value,
        orderInList: o.OrderInList,
      }));

      state.unsAlloyGroups = unsAlloyGroups;
    } else {
      state.unsAlloyGroups = list;
    }
  },
  SET_SAW_CALC_GROUPS(state, list) {
    if (!list || list.length == 0) {
      state.sawCalcGroups = [];
      return;
    }

    if ("SawCalcGroupID" in list[0]) {
      let sawCalcGroups = list.map((o) => ({
        sawCalcGroupID: o.SawCalcGroupID,
        sawCalcGroupName: o.SawCalcGroupName,
        sawCalcGroupDescription: o.SawCalcGroupDescription,
        machinabilityGroupBiMetal: o.MachinabilityGroupBiMetal,
        machinabilityGroupCarbide: o.MachinabilityGroupCarbide,
        machinabilityRating: o.MachinabilityRating,
        defaultHBHardness: o.DefaultHBHardness,
        orderInList: o.OrderInList,
      }));

      state.sawCalcGroups = sawCalcGroups;
    } else {
      state.sawCalcGroups = list;
    }
  },
  SET_MACHINABILITY_GROUPS(state, list) {
    if (!list || list.length == 0) {
      state.machinabilityGroups = [];
      return;
    }

    if ("MachinabilityGroupID" in list[0]) {
      let machinabilityGroups = list.map((o) => ({
        machinabilityGroupID: o.MachinabilityGroupID,
        group: o.Group,
        rating: o.Rating,
        orderInList: o.OrderInList,
      }));

      state.machinabilityGroups = machinabilityGroups;
    } else {
      state.machinabilityGroups = list;
    }
  },
  SET_UNS_GENERAL_DESCRIPTIONS(state, list) {
    if (!list || list.length == 0) {
      state.unsGeneralDescriptions = [];
      return;
    }

    if ("ID" in list[0]) {
      let unsGeneralDescriptions = list.map((o) => ({
        id: o.ID,
        value: o.Value,
        orderInList: o.OrderInList,
      }));

      state.unsGeneralDescriptions = unsGeneralDescriptions;
    } else {
      state.unsGeneralDescriptions = list;
    }
  },
  SET_SUB_CATEGORIES(state, list) {
    if (!list || list.length == 0) {
      state.subCategories = [];
      return;
    }

    if ("ID" in list[0]) {
      let subCategories = list.map((o) => ({
        id: o.ID,
        value: o.Value,
        orderInList: o.OrderInList,
      }));

      state.subCategories = subCategories;
    } else {
      state.subCategories = list;
    }
  },
  SET_MATERIALS(state, list) {
    if (!list || list.length == 0) {
      state.materials = [];
      return;
    }

    if (list[0].MaterialID == undefined) {
      state.materials = list;
    } else {
      let materials = list.map((o) => ({
        id: o.MaterialID,
        designation: o.Designation,
        materialStandardID: o.MaterialStandardID,
        uns: o.UNS,
        unsCategoryID: o.UNSCategoryID,
        unsAlloyGroupID: o.UNSAlloyGroupID,
        sawCalcGroupID: o.SawCalcGroupID,
        compositeUNS: o.CompositeUNS,
        unsGeneralDescriptionID: o.UNSGeneralDescriptionID,
        subCategoryID: o.SubCategoryID,
        quickSearch: o.QuickSearch,
        orderInList: o.OrderInList,
      }));

      materials.forEach((item) => {
        item.description = item.designation;
      });

      state.materials = materials;
    }

    const selectMaterials = state.materials.filter(
      (item) => item.quickSearch === true
    );

    selectMaterials.forEach((item) => {
      const subCategory = state.subCategories.find(
        (itm) => itm.id == item.subCategoryID
      );

      if (subCategory) {
        item.description = subCategory.value + " - " + item.designation;
      }
    });

    state.selectMaterials = selectMaterials;
  },
  SET_SELECT_MATERIALS(state, list) {
    if (!list || list.length == 0) {
      state.selectMaterials = [];
      return;
    }

    list.forEach((item) => {
      const subCategory = state.subCategories.find(
        (itm) => itm.id == item.subCategoryID
      );

      if (subCategory) {
        item.description = subCategory.value + " - " + item.designation;
      }
    });

    state.selectMaterials = list;
  },
  SET_MATERIALS_READY(state, ready) {
    state.materialsReady = ready;
  },
  SET_MATERIAL(state, payload) {
    if (payload) {
      state.material = payload;

      if (state.material.scale == undefined) {
        state.material.scale = state.scales[0];
      }
      if (state.material.hardness == undefined) {
        state.material.hardness = state.defaultHardness;
      }
      if (state.material.heavyScaleForged == undefined) {
        state.material.heavyScaleForged = false;
      }
      if (state.material.htQuenched == undefined) {
        state.material.htQuenched = false;
      }

      state.material.hardnessDescription =
        state.material.hardness + " " + state.material.scale.abbreviation;
    } else {
      state.material = {};
    }
  },
  SET_SCALES(state, list) {
    if (!list || list.length == 0) {
      state.scales = [];
      return;
    }

    if (list[0].ScaleID == undefined) {
      state.scales = list;
    } else {
      let scales = list.map((o) => ({
        id: o.ScaleID,
        description: o.Description,
        abbreviation: o.Abbreviation,
        orderInList: o.OrderInList,
      }));

      state.scales = scales;
    }
  },
  SET_SCALE(state, payload) {
    if (payload) {
      if (state.material) {
        state.material.scale = payload;
      } else {
        state.material.scale = state.scales[0];
      }

      state.material.hardnessDescription =
        state.material.hardness + " " + state.material.scale.abbreviation;
    }
  },
  SET_HARDNESS(state, payload) {
    if (payload) {
      if (state.material) {
        state.material.hardnessCalculated = payload;
        state.material.hardness = parseInt(payload);
      } else {
        state.material.hardness = state.defaultHardness;
      }

      if (state.material.scale) {
        state.material.hardnessDescription =
          state.material.hardness + " " + state.material.scale.abbreviation;
      } else {
        state.material.hardnessDescription = "";
      }
    }
  },
  SET_HEAVY_SCALE_FORGED(state, payload) {
    if (payload) {
      if (state.material) {
        state.material.heavyScaleForged = payload;
      } else {
        state.material.heavyScaleForged = false;
      }
    }
  },
  SET_HT_QUENCHED(state, payload) {
    if (payload) {
      if (state.material) {
        state.material.htQuenched = payload;
      } else {
        state.material.htQuenched = false;
      }
    }
  },
  SET_USER_MATERIALS(state, list) {
    if (!list || list.length == 0) {
      state.userMaterials = [];
      return;
    }

    if (list[0].UserMaterialID == undefined) {
      state.userMaterials = list;
    } else {
      let materials = list.map((o) => ({
        userMaterialID: o.UserMaterialID,
        userID: o.UserID,
        materialID: o.MaterialID,
        materialName: o.MaterialName,
        designation: o.Designation,
        country: o.Country,
        standard: o.Standard,
        materialStandard: o.MaterialStandard,
        uns: o.UNS,
        unsCategory: o.UNSCategory,
        unsAlloyGroup: o.UNSAlloyGroup,
        sawCalcGroupName: o.SawCalcGroupName,
        sawCalcGroupDescription: o.SawCalcGroupDescription,
        machinabilityGroupBiMetal: o.MachinabilityGroupBiMetal,
        machinabilityGroupCarbide: o.MachinabilityGroupCarbide,
        machinabilityRating: o.MachinabilityRating,
        machinability: o.Machinability,
        defaultHBHardness: o.DefaultHBHardness,
        compositeUNS: o.CompositeUNS,
        unsGeneralDescription: o.UNSGeneralDescription,
        subCategory: o.SubCategory,
        orderInList: o.OrderInList,
        chemicalComposition: JSON.parse(o.ChemicalComposition),
        scale: {
          scaleID: o.ScaleID,
          abbreviation: o.ScaleAbbreviation,
          description: o.ScaleDescription,
        },
        hardness: o.Hardness,
        hardnessDescription: o.HardnessDescription,
        heavyScaleForged: o.HeavyScaleForged,
        htQuenched: o.HTQuenched,
      }));

      materials.sort(function(a, b) {
        if (!a.model || !b.model) {
          return 0;
        }

        var x = a.model.toLowerCase();
        var y = b.model.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      materials.forEach((item) => {
        item.hardnessDescription =
          item.hardness + " " + item.scale.abbreviation;

        if (item.machinabilityGroupBiMetal == undefined) {
          item.machinabilityGroupBiMetal = "";
        }

        if (item.machinabilityGroupCarbide == undefined) {
          item.machinabilityGroupCarbide = "";
        }

        item.machinability =
          "Group: " +
          item.machinabilityGroupBiMetal +
          "/" +
          item.machinabilityGroupCarbide +
          " " +
          "Rating: " +
          item.machinabilityRating;
      });

      state.userMaterials = materials;
    }
  },
  SET_USER_MATERIAL(state, payload) {
    if (!payload) {
      return;
    }

    let userMaterial = state.userMaterials.find(
      (item) => item.userMaterialID === payload.userMaterialID
    );

    if (userMaterial) {
      userMaterial.designation = payload.designation;
      userMaterial.country = payload.country;
      userMaterial.standard = payload.standard;
      userMaterial.materialStandard = payload.materialStandard;
      userMaterial.uns = payload.uns;
      userMaterial.unsCategory = payload.unsCategory;
      userMaterial.unsAlloyGroup = payload.unsAlloyGroup;
      userMaterial.sawCalcGroupName = payload.sawCalcGroupName;
      userMaterial.sawCalcGroupDescription = payload.sawCalcGroupDescription;
      userMaterial.machinabilityGroupBiMetal =
        payload.machinabilityGroupBiMetal;
      userMaterial.machinabilityGroupCarbide =
        payload.machinabilityGroupCarbide;
      userMaterial.machinabilityRating = payload.machinabilityRating;
      userMaterial.machinability = payload.machinability;
      userMaterial.defaultHBHardness = payload.defaultHBHardness;
      userMaterial.compositeUNS = payload.compositeUNS;
      userMaterial.unsGeneralDescription = payload.unsGeneralDescription;
      userMaterial.subCategory = payload.subCategory;
      userMaterial.orderInList = payload.orderInList;
      userMaterial.chemicalComposition = payload.chemicalComposition;
      userMaterial.scale = payload.scale;
      userMaterial.hardness = payload.hardness;
      userMaterial.hardnessDescription = payload.hardnessDescription;
      userMaterial.heavyScaleForged = payload.heavyScaleForged;
      userMaterial.htQuenched = payload.htQuenched;

      userMaterial.defined = payload.defined;
    }
  },
  RELOAD_MATERIAL_SELECTOR_DETAILS_COMPONENT(state) {
    state.materialSelectorDetailsKey += 1;
  },

  // Dimensions

  SHOW_DIMENSIONS_SELECTOR_DIALOG(state, show) {
    state.dimensionsSelectorDialog.show = show;
  },
  SET_IBEAM_GROUPS_STANDARDS(state, list) {
    if (!list || list.length == 0) {
      state.iBeamGroups = [];
      state.iBeamStandards = [];
      return;
    }

    let iBeamGroups = list.map((o) => ({
      iBeamGroupID: o.IBeamGroupID,
      iBeamGroup: o.IBeamGroup,
      orderInList: o.IBeamGroupOrderInList,
    }));

    let myMap = new Map();

    iBeamGroups = iBeamGroups.filter((el) => {
      const val = myMap.get(el.iBeamGroup);
      if (val) {
        if (el.iBeamGroupID < val) {
          myMap.delete(el.iBeamGroup);
          myMap.set(el.iBeamGroup, el.iBeamGroupID);
          return true;
        } else {
          return false;
        }
      }
      myMap.set(el.iBeamGroup, el.iBeamGroupID);
      return true;
    });

    iBeamGroups.sort(function(a, b) {
      var x = a.orderInList;
      var y = b.orderInList;
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    state.iBeamGroups = iBeamGroups;

    let iBeamStandards = list.map((o) => ({
      iBeamStandardID: o.IBeamStandardID,
      iBeamGroupID: o.IBeamGroupID,
      description: o.Description,
      depth: o.Depth,
      flangeWidth: o.FlangeWidth,
      webThickness: o.WebThickness,
      flangeThickness: o.FlangeThickness,
    }));

    iBeamStandards.sort(function(a, b) {
      if (!a.description || !b.description) {
        return 0;
      }

      var x = a.description.toLowerCase();
      var y = b.description.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    state.iBeamStandards = iBeamStandards;
  },
  SET_IBEAM_GROUPS(state, payload) {
    if (payload) {
      state.iBeamGroups = payload;
    } else {
      state.iBeamGroups = {};
    }
  },
  SET_IBEAM_STANDARDS(state, payload) {
    if (payload) {
      state.iBeamStandards = payload;
    } else {
      state.iBeamStandards = {};
    }
  },
  SET_DIMENSIONS_READY(state, ready) {
    state.dimensionsReady = ready;
  },

  // Vising

  SHOW_VISING_SELECTOR_DIALOG(state, show) {
    state.visingSelectorDialog.show = show;
  },
  SET_VISING_READY(state, ready) {
    state.visingReady = ready;
  },

  // Conditions

  SHOW_CONDITIONS_SELECTOR_DIALOG(state, show) {
    state.conditionsSelectorDialog.show = show;
  },
  SET_CONDITIONS_READY(state, ready) {
    state.conditionsReady = ready;
  },

  // Product Lines

  SET_PRODUCT_LINES_READY(state, ready) {
    state.productLinesReady = ready;
  },
  SET_PRODUCT_LINES_SHORT(state, list) {
    if (!list || list.length == 0) {
      state.productLines = [];
      return;
    }

    if (list[0].ProductLineID == undefined) {
      state.productLinesShort = list;
    } else {
      let productLines = list.map((o) => ({
        productLineID: o.ProductLineID,
        productFamily: o.ProductFamily,
        productType: o.ProductType,
        productTypeDescription: o.ProductTypeDescription,
        tpi: o.TPI,
        bwl1: o.BWL1,
        bwl2: o.BWL2,
        bwl3: o.BWL3,
        bwl4: o.BWL4,
        bwl5: o.BWL5,
        bwl6: o.BWL6,
        bwl7: o.BWL7,
        bwl8: o.BWL8,
        orderInList: o.OrderInList,
      }));

      state.productLinesShort = productLines;
    }
  },
  SET_BWL_REFS(state, list) {
    if (!list || list.length == 0) {
      state.bwlRefs = [];
      return;
    }

    if (list[0].BWLRefID == undefined) {
      state.bwlRefs = list;
    } else {
      let bwlRefs = list.map((o) => ({
        bwlRefID: o.BWLRefID,
        bwl: o.BWL,
        widthImperial: o.WidthImperial,
        widthImperialValue: o.WidthImperialValue,
        widthMetric: o.WidthMetric,
        gaugeImperial: o.GaugeImperial,
        gaugeMetric: o.GaugeMetric,
      }));

      state.bwlRefs = bwlRefs;
    }
  },

  // Recommendation

  RECOMMENDATION_UPDATED(state) {
    state.recommendationUpdated += 1;
  },

  // Cost Calculations

  UPDATE_COST_CALCULATION(state, payload) {
    const costCalculation = payload.costCalculation;
    const index = payload.index;

    state.costCalculations[index] = costCalculation;
  },
  RESET_COST_CALCULATIONS(state) {
    state.costCalculations = [{}, {}, {}, {}];
  },

  // SawCalc

  SET_SAW_CALC_LOADING(state, loading) {
    state.sawCalcLoading = loading;
  },

  SET_SETTINGS_LOADED(state, loaded) {
    state.settingsLoaded = loaded;
  },

  SELECT_RECOMMENDATION(state, payload) {
    state.selectedRecommendation = payload.recommendation;
  },
  CUSTOMIZE(state, payload) {
    state.selectedRecommendationForCustom = payload.recommendationIndex;
    state.selectedRecommendation = recommendations.Custom;

    state.recommendations[recommendations.Custom] = JSON.parse(
      JSON.stringify(payload.recommendation)
    );

    state.costCalculations[recommendations.Custom] = JSON.parse(
      JSON.stringify(payload.costCalculation)
    );
  },
  SET_RECOMMENDATIONS(state, list) {
    if (!list || list.length == 0) {
      state.recommendations = [{}, {}, {}, {}];
      return;
    }

    let recommendations = list.map((o) => ({
      title: o.Title,
      blade: o.Blade,
      bladeDescription: o.BladeDescription,
      specsImperial: o.SpecsImperial,
      specsMetric: o.SpecsMetric,
      bandSpeedImperial: o.BandSpeedImperial,
      bandSpeedMetric: o.BandSpeedMetric,
      feedRateImperial: o.FeedRateImperial,
      feedRateMetric: o.FeedRateMetric,
      chipLoadImperial: o.ChipLoadImperial,
      chipLoadMetric: o.ChipLoadMetric,
      cuttingRateImperial: o.CuttingRateImperial,
      cuttingRateMetric: o.CuttingRateMetric,
      cutTime: o.CutTime,
      cutTimeDescription: calculations.secToTime(o.CutTime),
    }));

    const json = state.language.messages;

    for (let i = 0; i < recommendations.length; i++) {
      recommendations[i].title = json.sawCalc.recommendation.title[i];
    }

    if (recommendations.length == 3) {
      recommendations.push({});
    }

    state.recommendations = recommendations;
  },
  CLOSE_CUSTOM_RECOMMENDATION(state) {
    state.recommendations[recommendations.Custom].blade = null;
    state.recommendations[recommendations.Custom].bladeDescription = null;
    state.recommendations[recommendations.Custom].specs = null;
    state.recommendations[recommendations.Custom].bandSpeed = null;
    state.recommendations[recommendations.Custom].feedRate = null;
    state.recommendations[recommendations.Custom].chipLoad = null;
    state.recommendations[recommendations.Custom].cuttingRate = null;
    state.recommendations[recommendations.Custom].cutTime = null;

    state.selectedRecommendationForCustom = null;
    state.selectedRecommendation = 0;
  },
  UPDATE_FROM_CUSTOM(state, payload) {
    const recommendationIndex = state.selectedRecommendationForCustom;
    const recommendation = payload.recommendation;
    const custom = payload.custom;
    const costCalculation = payload.costCalculation;

    state.recommendations[recommendationIndex] = JSON.parse(
      JSON.stringify(recommendation)
    );
    state.recommendations[recommendationIndex].custom = JSON.parse(
      JSON.stringify(custom)
    );
    state.recommendations[recommendationIndex].customized = true;

    state.costCalculations[recommendationIndex] = JSON.parse(
      JSON.stringify(costCalculation)
    );
  },
  RELOAD_RECOMMENDATION_COMPONENT(state) {
    state.recommendationKey += 1;
  },

  // Login

  SHOW_LOGIN_DIALOG(state, show) {
    state.loginDialog.show = show;
  },
  LOGIN_LOADING(state, loading) {
    state.loginDialog.loading = loading;
  },

  // Register

  SHOW_REGISTER_DIALOG(state, show) {
    state.registerDialog.show = show;
  },
  REGISTER_LOADING(state, loading) {
    state.registerDialog.loading = loading;
  },

  // Password Reset

  SHOW_PASSWORD_RESET_DIALOG(state, show) {
    state.passwordResetDialog.show = show;
  },
  PASSWORD_RESET_LOADING(state, loading) {
    state.passwordResetDialog.loading = loading;
  },

  // Snackbar

  SHOW_SNACKBAR(state, payload) {
    let message = payload.message;
    let delay = payload.delay;
    let show = payload.show;

    state.snackbar.show = show;
    if (payload.result) {
      state.snackbar.color = "success";
      if (delay) {
        state.snackbar.timeout = delay;
      } else {
        state.snackbar.timeout = 4000;
      }
      state.snackbar.message = message;
    } else {
      state.snackbar.color = "error";
      if (delay) {
        state.snackbar.timeout = delay;
      } else {
        state.snackbar.timeout = 5000;
      }
      state.snackbar.message = message;
    }
  },

  // User

  SET_USER(state, user) {
    if (user) {
      if (user.UserID) {
        state.user = {
          userID: user.UserID,
          companyName: user.CompanyName,
          street: user.Street,
          city: user.City,
          postalCode: user.PostalCode,
          country: user.Country,
          state: user.State,
          phone: user.Phone,
          firstName: user.FirstName,
          lastName: user.LastName,
          email: user.Email,
          marketingEmail: user.MarketingEmail,
          admin: user.Admin,
          imageID: user.ImageID,
          fileName: user.FileName,
          DistributorfirstName: user.DistributorfirstName,
          DistributorlastName: user.DistributorlastName,
          DistributorEmail: user.DistributorEmail,
          LENOXcontactEmail: user.LENOXcontactEmail,
          LENOXcontactfirstName: user.LENOXcontactfirstName,
          LENOXcontactlastName: user.LENOXcontactlastName,
        };
      } else {
        state.user = user;
      }

      if (user.UserLinks) {
        const list = [];

        user.UserLinks.forEach((userLink) => {
          const p = {
            userLinkID: userLink.UserLinkID,
            parentID: userLink.ParentID,
            userID: userLink.UserID,
            linkName: userLink.LinkName,
            orderInList: userLink.OrderInList,
          };

          list.push(p);
        });

        state.user.userLinks = list;
      } else {
        state.user.userLinks = [];
      }

      let country = null;

      if (state.user.country && state.countries) {
        if (state.user.country.length == 2) {
          country = state.countries.find(
            (item) => item.code == state.user.country
          );
        } else {
          country = state.countries.find(
            (item) => item.countryName == state.user.country
          );
        }
      }

      if (country) {
        state.user.country = country.code;
      } else {
        state.user.country = state.defaultCountry.code;
      }
    } else {
      state.user = null;
    }
  },

  // Axios

  SET_AXIOS_SOURCE(state, source) {
    state.axiosSource = source;
  },
  CANCEL_REQUEST(state) {
    state.axiosSource.cancel("Request cancelled.");
  },

  // Authentication

  SET_TOKEN(state, token) {
    state.token = token;
  },

  // Users

  SET_USERS(state, list) {
    if (!list || list.length == 0) {
      state.users = [];
      return;
    }

    let users = list.map((o) => ({
      userID: o.UserID,
      email: o.Email,
      uuid: o.UUID,
      emailVerified: o.EmailVerified,
      admin: o.Admin,
      marketingEmail: o.MarketingEmail,
      technicalGroup: o.TechnicalGroup,
      adminGroup: o.AdminGroup,
      active: o.Active,
      companyName: o.CompanyName,
      street: o.Street,
      city: o.City,
      state: o.State,
      postalCode: o.PostalCode,
      country: o.Country,
      phone: o.Phone,
      firstName: o.FirstName,
      lastName: o.LastName,
      userLinks: o.UserLinks,
    }));

    users.forEach((item) => {
      item.fullName = item.firstName + " " + item.lastName;

      item.address =
        item.street +
        ", " +
        item.city +
        ", " +
        item.state +
        " " +
        item.postalCode +
        ", " +
        item.country;

      const list = [];

      if (item.userLinks) {
        item.userLinks.forEach((userLink) => {
          const p = {
            userLinkID: userLink.UserLinkID,
            parentID: userLink.ParentID,
            userID: userLink.UserID,
            linkName: userLink.LinkName,
            orderInList: userLink.OrderInList,
          };

          list.push(p);
        });
      }

      item.userLinks = list;
    });

    state.users = users;
  },
  UPDATE_DB_USER(state, payload) {
    if (!payload) {
      return;
    }

    const userID = payload.UserID;
    const admin = payload.Admin;
    const marketingEmail = payload.MarketingEmail;
    const technicalGroup = payload.TechnicalGroup;
    const adminGroup = payload.AdminGroup;
    const active = payload.Active;

    const user = state.users.find((item) => item.userID == userID);

    if (user) {
      user.admin = admin;
      user.marketingEmail = marketingEmail;
      user.technicalGroup = technicalGroup;
      user.adminGroup = adminGroup;
      user.active = active;
    }
  },
  DELETE_DB_USER(state, payload) {
    if (!payload) {
      return;
    }

    var index = state.users.findIndex((o) => {
      return o.userID === payload;
    });

    if (index !== -1) state.users.splice(index, 1);
  },
  RELOAD_USERS_COMPONENT(state) {
    state.usersKey += 1;
  },
  UPDATE_ACCOUNT(state, payload) {
    if (!payload) {
      return;
    }

    const user = state.user;

    if (user) {
      user.userID = payload.UserID;
      user.companyName = payload.CompanyName;
      user.street = payload.Street;
      user.city = payload.City;
      user.state = payload.State;
      user.postalCode = payload.PostalCode;
      user.country = payload.Country;
      user.phone = payload.Phone;
      user.firstName = payload.FirstName;
      user.lastName = payload.LastName;
      user.marketingEmail = payload.MarketingEmail;
      user.userLinks = payload.UserLinks;
      user.DistributorfirstName= payload.DistributorfirstName;
      user.DistributorlastName= payload.DistributorlastName;
      user.DistributorEmail= payload.DistributorEmail;
      user.LENOXcontactEmail= payload.LENOXcontactEmail;
      user.LENOXcontactfirstName= payload.LENOXcontactfirstName;
      user.LENOXcontactlastName= payload.LENOXcontactlastName;
    }
  },
  SET_COUNTRIES(state, list) {
    if (!list || list.length == 0) {
      state.countries = [];
      return;
    }

    if (list[0].CountryID == undefined) {
      state.countries = list;
    } else {
      let countries = list.map((o) => ({
        countryID: o.CountryID,
        code: o.Code,
        countryName: o.CountryName,
      }));

      state.countries = countries;
    }
  },
  RELOAD_USER_LINKS_COMPONENT(state) {
    state.dataTableUserLinksKey += 1;
  },
  UPDATE_USER_LINKS(state, payload) {
    const parentID = payload.parentID;
    const list = payload.list;

    if (!parentID) {
      return;
    }

    const user = this.state.users.find(({ userID }) => userID === parentID);

    const lst = [];

    if (user) {
      if (list) {
        list.forEach((item) => {
          const userLink = {
            userLinkID: item.UserLinkID,
            parentID: item.ParentID,
            userID: item.UserID,
            linkName: item.LinkName,
            orderInList: item.OrderInList,
          };

          lst.push(userLink);
        });
      }

      user.userLinks = lst;
    }
  },

  // Stats

  SET_STATS(state, list) {
    if (!list || list.length == 0) {
      state.stats = [];
      return;
    }

    let stats = list.map((o) => ({
      title: o.Title,
      statValue: o.StatValue,
      updatedDate: o.UpdatedDate,
    }));

    stats.forEach((item) => {
      if (item.updatedDate) {
        if (item.updatedDate != null && item.updatedDate != undefined) {
          var value = new Date(
            parseInt(
              item.updatedDate.toString().replace(/(^.*\()|([+-].*$)/g, "")
            )
          );

          if (!isNaN(value)) {
            item.updatedDate =
              value.getMonth() +
              1 +
              "/" +
              value.getDate() +
              "/" +
              value.getFullYear();
          }
        }
      }
    });

    state.stats = stats;
  },

  // UI

  SHOW_SAW_CALC_DRAWER(state, show) {
    state.sawCalcDrawer = show;
  },
  GET_IMAGES(state, images) {
    const imagesList = [];

    images.forEach((image) => {
      imagesList.push({
        page: image.Page,
        name: image.Name,
        fileName: image.FileName,
      });
    });

    const homeCover = imagesList.filter(
      (item) => item.page === "Home" && item.name === "Cover"
    );

    if (homeCover && homeCover.length == 1) {
      state.images.home.cover.fileName = homeCover[0].fileName;
    }
  },
  SET_IMAGE_SOURCE(state, file) {
    if (file.id === "homeCover" && file.image != null) {
      state.images.home.cover.data = imageConversion.dataBase64 + file.data;
    }
  },
  UPDATE_IMAGE(state, payload) {
    const page = payload.page;
    const name = payload.name;
    const fileName = payload.fileName;
    const data = payload.data;

    if (page === "Home") {
      if (name === "Cover") {
        state.images.home.cover.fileName = fileName;
        state.images.home.cover.data = data;
      }
    }
  },
  IMAGE_LOADING(state, payload) {
    const page = payload.page;
    const name = payload.name;

    if (page === "Home") {
      if (name === "Cover") {
        state.images.home.cover.loading = payload.loading;
      }
    }
  },
  SHOW_DATA_TABLE_SETTINGS_DIALOG(state, show) {
    state.dataTableSettingsDialog.show = show;
  },
  SHOW_DATA_TABLE_UOM_SETTINGS_DIALOG(state, show) {
    state.dataTableUOMSettingsDialog.show = show;
  },
  RELOAD_DATA_TABLE_SETTINGS_COMPONENT(state) {
    state.dataTableSettingsKey += 1;
  },
  RELOAD_DATA_TABLE_UOM_SETTINGS_COMPONENT(state) {
    state.dataTableUOMSettingsKey += 1;
  },

  // Admin

  // Machines

  SET_MACHINES_ADMIN(state, list) {
    if (!list || list.length == 0) {
      state.machinesAdmin = [];
      return;
    }

    let machines = list.map((o) => ({
      machineID: o.MachineID,
      manufacturerID: o.ManufacturerID,
      manufacturerName: o.ManufacturerName,
      model: o.Model,
      bladeWidthIn: o.BladeWidthIn,
      bladeWidthFr: o.BladeWidthFr,
      bladeWidthMetric: o.BladeWidthMetric,
      bladeThicknessImperial: o.BladeThicknessImperial,
      bladeThicknessMetric: o.BladeThicknessMetric,
      bladeLengthFt: o.BladeLengthFt,
      bladeLengthIn: o.BladeLengthIn,
      bladeLengthFr: o.BladeLengthFr,
      bladeLengthImperial: o.BladeLengthImperial,
      bladeLengthMetric: o.BladeLengthMetric,
      hortizontal: o.Hortizontal,
      scissorPivot: o.ScissorPivot,
      cantedHead: o.CantedHead,
      bandSpeedMinImperial: o.BandSpeedMinImperial,
      bandSpeedMaxImperial: o.BandSpeedMaxImperial,
      bandSpeedMinMetric: o.BandSpeedMinMetric,
      bandSpeedMaxMetric: o.BandSpeedMaxMetric,
    }));

    machines.sort(function(a, b) {
      if (!a.model || !b.model) {
        return 0;
      }

      var x = a.model.toLowerCase();
      var y = b.model.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    state.machinesAdmin = machines;
  },
  SET_MACHINES_ADMIN_SETTINGS(state, payload) {
    state.machinesAdminSettings = payload;
  },

  // Materials

  SET_MATERIALS_ADMIN(state) {
    state.materialsAdmin = [];

    let materialsAdmin = state.materials.map((o) => ({
      id: o.id,
      designation: o.designation,
      materialStandardID: o.materialStandardID,
      uns: o.uns,
      unsCategoryID: o.unsCategoryID,
      unsAlloyGroupID: o.unsAlloyGroupID,
      sawCalcGroupID: o.sawCalcGroupID,
      compositeUNS: o.compositeUNS,
      unsGeneralDescriptionID: o.unsGeneralDescriptionID,
      subCategoryID: o.subCategoryID,
      quickSearch: o.quickSearch,
      orderInList: o.orderInList,
    }));

    state.materialStandards.forEach((item) => {
      const list = materialsAdmin.filter(
        (itm) => itm.materialStandardID == item.id
      );

      if (list) {
        for (let i = 0; i < list.length; i++) {
          list[i].materialStandard = item.value;
        }
      }
    });

    state.unsCategories.forEach((item) => {
      const list = materialsAdmin.filter((itm) => itm.unsCategoryID == item.id);

      if (list) {
        for (let i = 0; i < list.length; i++) {
          list[i].unsCategory = item.value;
        }
      }
    });

    state.unsAlloyGroups.forEach((item) => {
      const list = materialsAdmin.filter(
        (itm) => itm.unsAlloyGroupID == item.id
      );

      if (list) {
        for (let i = 0; i < list.length; i++) {
          list[i].unsAlloyGroup = item.value;
        }
      }
    });

    state.sawCalcGroups.forEach((item) => {
      const list = materialsAdmin.filter(
        (itm) => itm.sawCalcGroupID == item.sawCalcGroupID
      );

      if (list) {
        for (let i = 0; i < list.length; i++) {
          const itm = list[i];

          itm.defaultHBHardness = item.defaultHBHardness;
          itm.machinabilityGroupBiMetal = item.machinabilityGroupBiMetal;
          itm.machinabilityGroupCarbide = item.machinabilityGroupCarbide;
          itm.machinabilityRating = item.machinabilityRating;
          itm.sawCalcGroupName = item.sawCalcGroupName;
          itm.sawCalcGroupDescription = item.sawCalcGroupDescription;
        }
      }
    });

    state.unsGeneralDescriptions.forEach((item) => {
      const list = materialsAdmin.filter(
        (itm) => itm.unsGeneralDescriptionID == item.id
      );

      if (list) {
        for (let i = 0; i < list.length; i++) {
          list[i].unsGeneralDescription = item.value;
        }
      }
    });

    state.subCategories.forEach((item) => {
      const list = materialsAdmin.filter((itm) => itm.subCategoryID == item.id);

      if (list) {
        for (let i = 0; i < list.length; i++) {
          list[i].subCategory = item.value;
        }
      }
    });

    state.materialsAdmin = materialsAdmin;
  },
  SET_MATERIALS_ADMIN_SETTINGS(state, payload) {
    state.materialsAdminSettings = payload;
  },
  SET_MATERIALS_LOADING(state, loading) {
    state.materialsLoading = loading;
  },
  RELOAD_MATERIAL_DIALOG_COMPONENT(state) {
    state.materialDialogKey += 1;
  },
  SHOW_DATA_TABLE_CHEMICAL_COMPOSITIONS_DIALOG(state, show) {
    state.dataTableChemicalCompositionsDialog.show = show;
  },
  RELOAD_CHEMICAL_COMPOSITIONS_DIALOG_COMPONENT(state) {
    state.chemicalCompositionsDialogKey += 1;
  },

  // ProductLines

  SET_PRODUCT_LINES(state, list) {
    if (!list || list.length == 0) {
      state.productLines = [];
      return;
    }

    let productLines = list.map((o) => ({
      productLineID: o.ProductLineID,
      productFamilyID: o.ProductFamilyID,
      productFamily: o.ProductFamily,
      productTypeID: o.ProductTypeID,
      productType: o.ProductType,
      pitchLevel: o.PitchLevel,
      tpi: o.TPI,
      averagePitch: o.AveragePitch.toFixed(4),
      averageGulletArea: o.AverageGulletArea.toFixed(4),
      gulletFill: o.GulletFill.toFixed(2),
      bwl1: o.BWL1,
      bwl2: o.BWL2,
      bwl3: o.BWL3,
      bwl4: o.BWL4,
      bwl5: o.BWL5,
      bwl6: o.BWL6,
      bwl7: o.BWL7,
      bwl8: o.BWL8,
      active: o.Active,
      orderInList: o.OrderInList,
    }));

    productLines.sort(function(a, b) {
      if (!a.model || !b.model) {
        return 0;
      }

      var x = a.productFamily.toLowerCase();
      var y = b.productFamily.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    state.productLines = productLines;
  },
  SET_PRODUCT_LINES_SETTINGS(state, payload) {
    state.productLinesSettings = payload;
  },
  RELOAD_PRODUCT_LINES_DIALOG_COMPONENT(state) {
    state.productLineDialogKey += 1;
  },

  // ProductTypes

  SET_PRODUCT_TYPES(state, list) {
    if (!list || list.length == 0) {
      state.productTypes = [];
      return;
    }

    let productTypes = list.map((o) => ({
      productTypeID: o.ProductTypeID,
      productTypeName: o.ProductTypeName,
      productTypeDescription: o.ProductTypeDescription,
      orderInList: o.OrderInList,
    }));

    productTypes.sort(function(a, b) {
      if (!a.model || !b.model) {
        return 0;
      }

      var x = a.productTypeName.toLowerCase();
      var y = b.productTypeName.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    state.productTypes = productTypes;
  },

  // Recommendations

  SET_RECOMMENDATIONS_ADMIN(state, list) {
    if (!list || list.length == 0) {
      state.recommendationsAdmin = [];
      return;
    }

    let recommendationsAdmin = list.map((o) => ({
      recommendationID: o.RecommendationID,
      uom: o.UOM,
      manufacturerName: o.ManufacturerName,
      model: o.Model,
      designation: o.Designation,
      hardness: o.Hardness,
      shape: o.Shape,
      dimensions: o.Dimensions,
      quantity: o.Quantity,
      arrangement: o.Arrangement,
      lubrication: o.Lubrication,
      pinching: o.Pinching,
      blade: o.Blade,
      specsImperial: o.SpecsImperial,
      specsMetric: o.SpecsMetric,
      bandSpeedImperial: o.BandSpeedImperial,
      bandSpeedMetric: o.BandSpeedMetric,
      feedRateImperial: o.FeedRateImperial,
      feedRateMetric: o.FeedRateMetric,
      chipLoadImperial: o.ChipLoadImperial,
      chipLoadMetric: o.ChipLoadMetric,
      cuttingRateImperial: o.CuttingRateImperial,
      cuttingRateMetric: o.CuttingRateMetric,
      cutTime: o.CutTime,
      createdDate: o.CreatedDate,
    }));

    state.recommendationsAdmin = recommendationsAdmin;
  },
  SET_RECOMMENDATIONS_ADMIN_SETTINGS(state, payload) {
    state.recommendationsAdminSettings = payload;
  },

  // CuttingCharts

  SET_CUTTING_CHARTS(state, list) {
    if (!list || list.length == 0) {
      state.cuttingCharts = [];
      return;
    }

    let cuttingCharts = list.map((o) => ({
      cuttingChartID: o.CuttingChartID,
      cuttingChartName: o.CuttingChartName,
      userID: o.UserID,
      metric: o.Metric,
      machine: o.Machine,
      productImperial: o.ProductImperial,
      productMetric: o.ProductMetric,
      dimensionMin: o.DimensionMin,
      dimensionMax: o.DimensionMax,
      dimensionRange: o.DimensionRange,
      cuttingChartMaterialGroups: o.CuttingChartMaterialGroups,
    }));

    state.cuttingCharts = cuttingCharts;
  },
  SET_CUTTING_CHARTS_SETTINGS(state, payload) {
    state.cuttingChartsSettings = payload;
  },

  // Parameters

  SET_PARAMETERS(state, list) {
    if (!list || list.length == 0) {
      state.parameters = [];
      return;
    }

    let parameters = list.map((o) => ({
      parameterID: o.ParameterID,
      parameterName: o.ParameterName,
      parameterValue: o.ParameterValue,
      parameterDescription: o.ParameterDescription,
    }));

    parameters.sort(function(a, b) {
      if (!a.model || !b.model) {
        return 0;
      }

      var x = a.parameterName.toLowerCase();
      var y = b.parameterName.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    state.parameters = parameters;
  },

  SET_PRODUCT_SELECTIONS(state, list) {
    if (!list || list.length == 0) {
      state.productSelections = [];
      return;
    }

    let productSelections = list.map((o) => ({
      productSelectionID: o.ProductSelectionID,
      applicationScenario: o.ApplicationScenario,
      good: o.Good,
      better: o.Better,
      best: o.Best,
    }));

    productSelections.sort(function(a, b) {
      if (!a.model || !b.model) {
        return 0;
      }

      var x = a.applicationScenario;
      var y = b.applicationScenario;
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    state.productSelections = productSelections;
  },

  SET_PRODUCT_RECOMMENDATIONS(state, list) {
    if (!list || list.length == 0) {
      state.parameters = [];
      return;
    }

    let productRecommendations = list.map((o) => ({
      productRecommendationID: o.ProductRecommendationID,
      mrg: o.MRG,
      columnIndex: o.ColumnIndex,
      columnName: o.ColumnName,
      productType: o.ProductType,
    }));

    state.productRecommendations = productRecommendations;
  },

  // User

  // Logo

  SET_USER_LOGO_SOURCE(state, file) {
    let imageID = 0;
    let fileName = "";
    let userLogoUrl = "";

    if (file.imageID && file.fileName) {
      imageID = file.imageID;
      fileName = file.fileName;
    }

    if (file.url) {
      userLogoUrl = file.url;
    } else if (file.data) {
      userLogoUrl = imageConversion.dataBase64 + file.data;
    }

    if (!userLogoUrl) {
      imageID = 0;
      fileName = "";
    }

    state.user.imageID = imageID;
    state.user.fileName = fileName;
    state.userLogoUrl = userLogoUrl;
  },
  SET_USER_LOGO_URL(state, data) {
    state.userLogoUrl = imageConversion.dataBase64 + data;
  },

  // Machines

  SET_MACHINES_USER_SETTINGS(state, payload) {
    state.machinesUserSettings = payload;
  },
  SET_USER_MACHINE_IMAGE_SOURCE(state, file) {
    const machine = state.userMachines.find(
      (item) => item.userMachineID == file.id
    );

    if (machine) {
      if (file.imageID) {
        machine.imageID = file.imageID;
      }

      const imageID = machine.imageID;
      machine.imageID = null;

      if (file.url) {
        machine.url = file.url;
      } else if (file.data) {
        machine.url = imageConversion.dataBase64 + file.data;
      } else {
        machine.url = null;
      }

      machine.imageID = imageID;

      if (!machine.url) {
        machine.imageID = 0;
      }
    }
  },

  // Materials

  SET_MATERIALS_USER_SETTINGS(state, payload) {
    state.materialsUserSettings = payload;
  },

  // UserRecommendations

  SET_USER_RECOMMENDATIONS(state, list) {
    if (!list || list.length == 0) {
      state.userRecommendations = [];
      return;
    }

    let userRecommendations = list.map((o) => ({
      userRecommendationID: o.UserRecommendationID,
      userID: o.UserID,
      userMachineID: o.UserMachineID,
      userMaterialID: o.UserMaterialID,
      recommendationName: o.RecommendationName,
      input: o.Input,
      recommendation: o.Recommendation,
      custom: o.Custom,
      costCalculation: o.CostCalculation,
    }));

    userRecommendations.sort(function(a, b) {
      if (!a.model || !b.model) {
        return 0;
      }

      var x = a.recommendationName.toLowerCase();
      var y = b.recommendationName.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    userRecommendations.forEach((item) => {
      item.input = JSON.parse(item.input);
      item.recommendation = JSON.parse(item.recommendation);
      item.custom = JSON.parse(item.custom);
      item.costCalculation = JSON.parse(item.costCalculation);
    });

    state.userRecommendations = userRecommendations;
  },
  SET_RECOMMENDATIONS_USER_SETTINGS(state, payload) {
    state.recommendationsUserSettings = payload;
  },
};
