export default {
  snackbar: {
    show: false,
    color: "success",
    timeout: 4000,
    message: "",
    delay: null,
  },
  navigation: {
    toolbar: [
      {
        heading: true,
        title: "",
        name: "Home",
        action: "link",
        visible: true,
      },
      { spacer: true },
      {
        hiddenXsOnlyButton: true,
        title: "",
        path: "",
        action: "phone",
        visible: true,
      },
      {
        hiddenSmAndDownMenu: true,
        title: "",
        items: [],
        visible: true,
      },
      {
        hiddenSmAndDownButton: true,
        title: "Login",
        action: "showLoginDialog",
        visible: true,
      },
      {
        hiddenSmAndDownButton: true,
        title: "Logoff",
        action: "logoff",
        visible: false,
      },
    ],
    tabs: [
      {
        title: "HOME",
        name: "Home",
        route: "",
        tab: true,
      },
      {
        title: "SAW CALC",
        name: "SawCalc",
        route: "saw-calc",
        action: "link",
        tab: true,
      },
      {
        title: "BAND SAW GUIDE",
        route: "band-saw-guide",
        menu: true,
        menuIndex: 0,
        items: [
          {
            title: "Blade Terminology",
            name: "BladeTerminology",
            route: "blade-terminology",
            action: "link",
          },
          {
            title: "Blade Construction",
            name: "BladeConstruction",
            route: "blade-construction",
            action: "link",
          },
          {
            title: "Tooth Options",
            name: "ToothOptions",
            route: "tooth-options",
            action: "link",
          },
          {
            title: "Cutting Factors",
            name: "CuttingFactors",
            route: "cutting-factors",
            action: "link",
          },
          {
            title: "Beam Strength",
            name: "BeamStrength",
            route: "beam-strength",
            action: "link",
          },
          {
            title: "Lubrication",
            name: "Lubrication",
            route: "lubrication",
            action: "link",
          },
          {
            title: "Break-In Instructions",
            name: "BreakInInstructions",
            route: "break-in-instructions",
            action: "link",
          },
          {
            title: "Basic Maintenance Tips",
            name: "BasicMaintenanceTips",
            route: "basic-maintenance-tips",
            action: "link",
          },
          {
            title: "Common Problems",
            name: "CommonProblems",
            route: "common-problems",
            action: "link",
          },
          {
            title: "Glossary of Bandsaw Terms",
            name: "GlossaryOfBandsawTerms",
            route: "glossary-of-bandsaw-terms",
            action: "link",
          },
        ],
      },
      {
        title: "LENOX Advantage",
        name: "LENOXAdvantage",
        route: "lenox-advantage",
        url: "https://www.cutwithlenox.com/industrial/advantage",
        action: "link",
        tab: true,
      },
    ],
    tabsUser: [
      {
        title: "DASHBOARD",
        name: "Dashboard",
        route: "dashboard",
        tab: true,
      },
      {
        title: "LIBRARY",
        route: "library",
        menu: true,
        menuIndex: 1,
        items: [
          {
            title: "Machines",
            name: "Machines",
            route: "machines",
            action: "link",
          },
          {
            title: "Materials",
            name: "Materials",
            route: "materials",
            action: "link",
          },
        ],
      },
      {
        title: "SAW CALC",
        name: "SawCalc",
        route: "saw-calc",
        action: "link",
        tab: true,
      },
      {
        title: "BAND SAW GUIDE",
        route: "band-saw-guide",
        menu: true,
        menuIndex: 0,
        items: [
          {
            title: "Blade Terminology",
            name: "BladeTerminology",
            route: "blade-terminology",
            action: "link",
          },
          {
            title: "Blade Construction",
            name: "BladeConstruction",
            route: "blade-construction",
            action: "link",
          },
          {
            title: "Tooth Options",
            name: "ToothOptions",
            route: "tooth-options",
            action: "link",
          },
          {
            title: "Cutting Factors",
            name: "CuttingFactors",
            route: "cutting-factors",
            action: "link",
          },
          {
            title: "Beam Strength",
            name: "BeamStrength",
            route: "beam-strength",
            action: "link",
          },
          {
            title: "Lubrication",
            name: "Lubrication",
            route: "lubrication",
            action: "link",
          },
          {
            title: "Break-In Instructions",
            name: "BreakInInstructions",
            route: "break-in-instructions",
            action: "link",
          },
          {
            title: "Basic Maintenance Tips",
            name: "BasicMaintenanceTips",
            route: "basic-maintenance-tips",
            action: "link",
          },
          {
            title: "Common Problems",
            name: "CommonProblems",
            route: "common-problems",
            action: "link",
          },
          {
            title: "Glossary of Bandsaw Terms",
            name: "GlossaryOfBandsawTerms",
            route: "glossary-of-bandsaw-terms",
            action: "link",
          },
        ],
      },
      {
        title: "LENOX Advantage",
        name: "LENOXAdvantage",
        route: "lenox-advantage",
        url: "https://www.cutwithlenox.com/industrial/advantage",
        action: "link",
        tab: true,
      },
      {
        title: "ACCOUNT",
        name: "Account",
        route: "account",
        action: "link",
        tab: true,
      },
    ],
    tabsAdmin: [
      {
        title: "DASHBOARD ADMIN",
        name: "DashboardAdmin",
        route: "admin/dashboard",
        tab: true,
      },
      {
        title: "LIBRARY",
        route: "admin/library",
        menu: true,
        menuIndex: 1,
        items: [
          {
            title: "Machines",
            name: "MachinesAdmin",
            route: "admin/machines",
            action: "link",
          },
          {
            title: "Materials",
            name: "MaterialsAdmin",
            route: "admin/materials",
            action: "link",
          },
          {
            title: "Product Lines",
            name: "ProductLinesAdmin",
            route: "admin/product-lines",
            action: "link",
          },
          {
            title: "Recommendations",
            name: "RecommendationsAdmin",
            route: "admin/recommendations",
            action: "link",
          },
          {
            title: "Engine Parameters",
            name: "EngineParametersAdmin",
            route: "admin/engine-parameters",
            action: "link",
          },
          {
            title: "Dictionary UI",
            name: "DictionaryUI",
            route: "admin/dictionary-ui",
            action: "link",
          },
          {
            title: "Dictionary WS",
            name: "DictionaryWS",
            route: "admin/dictionary-ws",
            action: "link",
          },
          {
            title: "Translation",
            name: "Translation",
            route: "admin/translation",
            action: "link",
          },
        ],
      },
      {
        title: "SAW CALC",
        name: "SawCalc",
        route: "saw-calc",
        action: "link",
        tab: true,
      },
      {
        title: "BAND SAW GUIDE",
        route: "band-saw-guide",
        menu: true,
        menuIndex: 0,
        items: [
          {
            title: "Blade Terminology",
            name: "BladeTerminology",
            route: "blade-terminology",
            action: "link",
          },
          {
            title: "Blade Construction",
            name: "BladeConstruction",
            route: "blade-construction",
            action: "link",
          },
          {
            title: "Tooth Options",
            name: "ToothOptions",
            route: "tooth-options",
            action: "link",
          },
          {
            title: "Cutting Factors",
            name: "CuttingFactors",
            route: "cutting-factors",
            action: "link",
          },
          {
            title: "Beam Strength",
            name: "BeamStrength",
            route: "beam-strength",
            action: "link",
          },
          {
            title: "Lubrication",
            name: "Lubrication",
            route: "lubrication",
            action: "link",
          },
          {
            title: "Break-In Instructions",
            name: "BreakInInstructions",
            route: "break-in-instructions",
            action: "link",
          },
          {
            title: "Basic Maintenance Tips",
            name: "BasicMaintenanceTips",
            route: "basic-maintenance-tips",
            action: "link",
          },
          {
            title: "Common Problems",
            name: "CommonProblems",
            route: "common-problems",
            action: "link",
          },
          {
            title: "Glossary of Bandsaw Terms",
            name: "GlossaryOfBandsawTerms",
            route: "glossary-of-bandsaw-terms",
            action: "link",
          },
        ],
      },
      {
        title: "LENOX Advantage",
        name: "LENOXAdvantage",
        route: "lenox-advantage",
        url: "https://www.cutwithlenox.com/industrial/advantage",
        action: "link",
        tab: true,
      },
      {
        title: "ACCOUNT",
        name: "AccountAdmin",
        route: "admin/account",
        action: "link",
        tab: true,
      },
    ],
    side: [
      {
        heading: true,
        title: "",
        name: "Home",
        action: "link",
        visible: true,
      },
      { divider: true },
      {
        title: "HOME",
        name: "Home",
        route: "",
        action: "link",
        tab: true,
      },
      {
        title: "SAW CALC",
        name: "SawCalc",
        route: "saw-calc",
        action: "link",
        tab: true,
      },
      {
        title: "BAND SAW GUIDE",
        route: "band-saw-guide",
        menu: true,
        menuIndex: 0,
        items: [
          {
            title: "Blade Terminology",
            name: "BladeTerminology",
            route: "blade-terminology",
            action: "link",
          },
          {
            title: "Blade Construction",
            name: "BladeConstruction",
            route: "blade-construction",
            action: "link",
          },
          {
            title: "Tooth Options",
            name: "ToothOptions",
            route: "tooth-options",
            action: "link",
          },
          {
            title: "Cutting Factors",
            name: "CuttingFactors",
            route: "cutting-factors",
            action: "link",
          },
          {
            title: "Beam Strength",
            name: "BeamStrength",
            route: "beam-strength",
            action: "link",
          },
          {
            title: "Lubrication",
            name: "Lubrication",
            route: "lubrication",
            action: "link",
          },
          {
            title: "Break-In Instructions",
            name: "BreakInInstructions",
            route: "break-in-instructions",
            action: "link",
          },
          {
            title: "Basic Maintenance Tips",
            name: "BasicMaintenanceTips",
            route: "basic-maintenance-tips",
            action: "link",
          },
          {
            title: "Common Problems",
            name: "CommonProblems",
            route: "common-problems",
            action: "link",
          },
          {
            title: "Glossary of Bandsaw Terms",
            name: "GlossaryOfBandsawTerms",
            route: "glossary-of-bandsaw-terms",
            action: "link",
          },
        ],
      },
      {
        title: "LENOX Advantage",
        name: "LENOXAdvantage",
        route: "lenox-advantage",
        url: "https://www.cutwithlenox.com/industrial/advantage",
        action: "link",
        tab: true,
      },
      { divider: true },
      {
        title: "",
        path: "",
        action: "phone",
        tab: true,
      },
      {
        title: "Login",
        action: "showLoginDialog",
        tab: true,
      },
    ],
    sideUser: [
      {
        heading: true,
        title: "",
        name: "Home",
        action: "link",
        visible: true,
      },
      { divider: true },
      {
        title: "HOME",
        name: "Home",
        route: "",
        action: "link",
        tab: true,
      },
      {
        title: "DASHBOARD",
        name: "Dashboard",
        route: "",
        action: "link",
        tab: true,
      },
      {
        title: "LIBRARY",
        route: "library",
        menu: true,
        menuIndex: 1,
        items: [
          {
            title: "Machines",
            name: "Machines",
            route: "machines",
            action: "link",
          },
          {
            title: "Materials",
            name: "Materials",
            route: "materials",
            action: "link",
          },
        ],
      },
      {
        title: "SAW CALC",
        name: "SawCalc",
        route: "saw-calc",
        action: "link",
        tab: true,
      },
      {
        title: "BAND SAW GUIDE",
        route: "band-saw-guide",
        menu: true,
        menuIndex: 0,
        items: [
          {
            title: "Blade Terminology",
            name: "BladeTerminology",
            route: "blade-terminology",
            action: "link",
          },
          {
            title: "Blade Construction",
            name: "BladeConstruction",
            route: "blade-construction",
            action: "link",
          },
          {
            title: "Tooth Options",
            name: "ToothOptions",
            route: "tooth-options",
            action: "link",
          },
          {
            title: "Cutting Factors",
            name: "CuttingFactors",
            route: "cutting-factors",
            action: "link",
          },
          {
            title: "Beam Strength",
            name: "BeamStrength",
            route: "beam-strength",
            action: "link",
          },
          {
            title: "Lubrication",
            name: "Lubrication",
            route: "lubrication",
            action: "link",
          },
          {
            title: "Break-In Instructions",
            name: "BreakInInstructions",
            route: "break-in-instructions",
            action: "link",
          },
          {
            title: "Basic Maintenance Tips",
            name: "BasicMaintenanceTips",
            route: "basic-maintenance-tips",
            action: "link",
          },
          {
            title: "Common Problems",
            name: "CommonProblems",
            route: "common-problems",
            action: "link",
          },
          {
            title: "Glossary of Bandsaw Terms",
            name: "GlossaryOfBandsawTerms",
            route: "glossary-of-bandsaw-terms",
            action: "link",
          },
        ],
      },
      {
        title: "LENOX Advantage",
        name: "LENOXAdvantage",
        route: "lenox-advantage",
        url: "https://www.cutwithlenox.com/industrial/advantage",
        action: "link",
        tab: true,
      },
      { divider: true },
      {
        title: "ACCOUNT",
        name: "Account",
        route: "account",
        action: "link",
        tab: true,
      },
      { divider: true },
      {
        title: "phone",
        path: "",
        action: "phone",
        tab: true,
      },
      {
        title: "Logoff",
        action: "logoff",
        tab: true,
      },
    ],
    sideAdmin: [
      {
        heading: true,
        title: "",
        name: "Home",
        action: "link",
        visible: true,
      },
      { divider: true },
      {
        title: "HOME",
        name: "Home",
        route: "",
        action: "link",
        tab: true,
      },
      {
        title: "DASHBOARD",
        name: "DashboardAdmin",
        route: "",
        action: "link",
        tab: true,
      },
      {
        title: "LIBRARY",
        route: "admin/library",
        menu: true,
        menuIndex: 1,
        items: [
          {
            title: "Machines",
            name: "MachinesAdmin",
            route: "admin/machines",
            action: "link",
          },
          {
            title: "Materials",
            name: "MaterialsAdmin",
            route: "admin/materials",
            action: "link",
          },
          {
            title: "ProductLines",
            name: "ProductLinesAdmin",
            route: "admin/product-lines",
            action: "link",
          },
          {
            title: "Recommendations",
            name: "RecommendationsAdmin",
            route: "admin/recommendations",
            action: "link",
          },
          {
            title: "EngineParameters",
            name: "EngineParametersAdmin",
            route: "admin/engine-parameters",
            action: "link",
          },
          {
            title: "Dictionary UI",
            name: "DictionaryUI",
            route: "admin/dictionary-ui",
            action: "link",
          },
          {
            title: "Dictionary WS",
            name: "DictionaryWS",
            route: "admin/dictionary-ws",
            action: "link",
          },
          {
            title: "Translation",
            name: "Translation",
            route: "admin/translation",
            action: "link",
          },
        ],
      },
      {
        title: "SAW CALC",
        name: "SawCalc",
        route: "saw-calc",
        action: "link",
        tab: true,
      },
      {
        title: "BAND SAW GUIDE",
        route: "band-saw-guide",
        menu: true,
        menuIndex: 0,
        items: [
          {
            title: "Blade Terminology",
            name: "BladeTerminology",
            route: "blade-terminology",
            action: "link",
          },
          {
            title: "Blade Construction",
            name: "BladeConstruction",
            route: "blade-construction",
            action: "link",
          },
          {
            title: "Tooth Options",
            name: "ToothOptions",
            route: "tooth-options",
            action: "link",
          },
          {
            title: "Cutting Factors",
            name: "CuttingFactors",
            route: "cutting-factors",
            action: "link",
          },
          {
            title: "Beam Strength",
            name: "BeamStrength",
            route: "beam-strength",
            action: "link",
          },
          {
            title: "Lubrication",
            name: "Lubrication",
            route: "lubrication",
            action: "link",
          },
          {
            title: "Break-In Instructions",
            name: "BreakInInstructions",
            route: "break-in-instructions",
            action: "link",
          },
          {
            title: "Basic Maintenance Tips",
            name: "BasicMaintenanceTips",
            route: "basic-maintenance-tips",
            action: "link",
          },
          {
            title: "Common Problems",
            name: "CommonProblems",
            route: "common-problems",
            action: "link",
          },
          {
            title: "Glossary of Bandsaw Terms",
            name: "GlossaryOfBandsawTerms",
            route: "glossary-of-bandsaw-terms",
            action: "link",
          },
        ],
      },
      {
        title: "LENOX Advantage",
        name: "LENOXAdvantage",
        route: "lenox-advantage",
        url: "https://www.cutwithlenox.com/industrial/advantage",
        action: "link",
        tab: true,
      },
      { divider: true },
      {
        title: "ACCOUNT",
        name: "AccountAdmin",
        route: "admin/account",
        action: "link",
        tab: true,
      },
      { divider: true },
      {
        title: "",
        path: "",
        action: "phone",
        tab: true,
      },
      {
        title: "Logoff",
        action: "logoff",
        tab: true,
      },
    ],
  },
  languages: [{ flag: "us", locale: "en", title: "English", messages: null }],
  language: { flag: "us", locale: "en", title: "English" },
  footer: {
    statusBar: [
      {
        title: "Terms of Use",
        name: "TermsOfUse",
        route: "terms-of-use",
        url: "https://www.stanleyblackanddecker.com/terms-use",
        action: "link",
      },
      { divider: true },
      {
        title: "Privacy Policy",
        name: "PrivacyPolicy",
        route: "updated-privacy-policy",
        url: "https://www.stanleyblackanddecker.com/privacy-policy",
        action: "link",
      },
      { divider: true },
      {
        cookieSettings: true,
        title: "Cookie Settings",
      },
      {
        copywrite: true,
        title: "LENOX, Stanley Black &amp; Decker. All rights reserved.",
      },
    ],
    info: {
      contact: [
        {
          title: "lenoxtools.com",
          action: "url",
          path: "https://www.lenoxtools.com/",
        },
        {
          title: "contact",
          action: "url",
          path: "https://www.lenoxtools.com/pages/ContactUs.aspx",
        },
      ],
      social: [
        {
          title: "Facebook",
          action: "url",
          path: "https://www.facebook.com/lenoxtools",
          icon: "mdi-facebook",
        },
        {
          title: "Twitter",
          action: "url",
          path: "https://twitter.com/lenoxtools",
          icon: "mdi-twitter",
        },
        {
          title: "Instagram",
          action: "url",
          path: "https://www.instagram.com/lenoxtools",
          icon: "mdi-instagram",
        },
        {
          title: "YouTube",
          action: "url",
          path: "https://www.youtube.com/user/LENOXTools",
          icon: "mdi-youtube",
        },
      ],
    },
  },

  // Recommendation
  // Inputs

  input: {
    metric: false,
    inputUOM: 0,
    machine: {
      manufacturerName: null,
      model: null,
      bladeLength: null,
    },
    material: {
      designation: null,
      hardnessDescription: null,
    },
    dimensions: {
      shape: null,
      dimensions: null,
    },
    vising: {
      quantity: null,
      arrangement: null,
    },
    conditions: {
      lubrication: null,
      pinching: null,
      canting: null,
    },
    currency: 0,
    inputCurrency: 0,
    resetInputs: 0,
  },
  savedInput: {
    machine: {
      manufacturerName: null,
      model: null,
      bladeLength: null,
    },
    material: {
      designation: null,
      hardnessDescription: null,
    },
  },

  // Machines

  machineSelectorDialog: {
    show: false,
    loading: false,
  },
  manufacturers: [],
  machines: [],
  machine: {},
  userMachines: [],
  userMachine: {},
  bladeWidthImperialList: [],
  bladeWidthMetricList: [],
  bladeThicknessImperialList: [],
  bladeThicknessMetricList: [],
  bladeLengthFtList: [],
  bladeLengthInList: [],
  bladeLengthFrList: [],
  bladeLengthMetricList: [],
  unknownMachineImperialKey: 0,
  unknownMachineMetricKey: 0,
  machinesReady: false,
  machineTasks: [
    { description: "Machines", function: "getMachineManufacturers" },
    {
      description: "Blade Width Imperial",
      function: "getBladeWidthImperialList",
    },
    {
      description: "Blade Width Metric",
      function: "getBladeWidthMetricList",
    },
    {
      description: "Blade Thickness Imperial",
      function: "getBladeThicknessImperialList",
    },
    {
      description: "Blade Thickness Metric",
      function: "getBladeThicknessMetricList",
    },
    { description: "Blade Length Ft", function: "getBladeLengthFtList" },
    { description: "Blade Length In", function: "getBladeLengthInList" },
    { description: "Blade Length Fr", function: "getBladeLengthFrList" },
    {
      description: "Blade Length Metric",
      function: "getBladeLengthMetricList",
    },
  ],

  // Material

  materialSelectorDialog: {
    show: false,
    loading: false,
  },
  materialStandards: [],
  unsCategories: [],
  unsAlloyGroups: [],
  sawCalcGroups: [],
  machinabilityGroups: [],
  unsGeneralDescriptions: [],
  subCategories: [],
  materials: [],
  selectMaterials: [],
  material: {},
  userMaterials: [],
  userMaterial: {},
  scales: [],
  defaultHardness: 20,
  hbHardnessMin: 90,
  hbHardnessMinConvertable: 226,
  hbHardnessMax: 739,
  hrcHardnessMin: 20,
  hrcHardnessMax: 65,
  materialSelectorDetailsKey: 0,
  materialsReady: false,
  materialTasks: [
    { description: "Material Standards", function: "getMaterialStandards" },
    {
      description: "UNS Categories",
      function: "getUNSCategories",
    },
    {
      description: "UNS Alloy Groups",
      function: "getUNSAlloyGroups",
    },
    {
      description: "SawCalc Groups",
      function: "getSawCalcGroups",
    },
    {
      description: "UNS General Descriptions",
      function: "getUNSGeneralDescriptions",
    },
    {
      description: "Material Sub Categories",
      function: "getSubCategories",
    },
    { description: "Material", function: "getMaterials" },
    { description: "Scale", function: "getScales" },
  ],

  // Dimensions

  dimensionsSelectorDialog: {
    show: false,
    loading: false,
  },
  iBeamGroups: [],
  iBeamStandards: [],
  dimensionsReady: false,
  dimensionTasks: [
    { description: "Dimensions", function: "getIBeamStandards" },
  ],

  // Vising

  visingSelectorDialog: {
    show: false,
    loading: false,
  },
  visingReady: false,

  // Conditions

  conditionsSelectorDialog: {
    show: false,
    loading: false,
  },
  conditionsReady: false,
  defaultCantingDegrees: 6,

  // Product Lines

  productLines: [],
  productLinesReady: false,
  productLineTasks: [
    { description: "Products", function: "getProductLinesShort" },
    { description: "BWLRefs", function: "getBWLRefs" },
  ],

  // SawCalc

  sawCalcLoading: false,
  settingsLoaded: false,

  // Outputs

  recommendations: [
    {
      title: "",
      blade: null,
      specs: null,
      bandSpeed: null,
      feedRate: null,
      chipLoad: null,
      cuttingRate: null,
      cutTime: null,
    },
    {
      title: "",
      blade: null,
      specs: null,
      bandSpeed: null,
      feedRate: null,
      chipLoad: null,
      cuttingRate: null,
      cutTime: null,
    },
    {
      title: "",
      blade: null,
      specs: null,
      bandSpeed: null,
      feedRate: null,
      chipLoad: null,
      cuttingRate: null,
      cutTime: null,
    },
    {
      title: "Custom",
      blade: null,
      specs: null,
      bandSpeed: null,
      feedRate: null,
      chipLoad: null,
      cuttingRate: null,
      cutTime: null,
    },
  ],
  selectedRecommendation: 0,
  selectedRecommendationForCustom: null,
  bandSpeedImperialIncrement: 5,
  bandSpeedMetricIncrement: 1,
  bandSpeedMin: 1,
  bandSpeedMax: 10000,
  feedRateImperialIncrement: 0.01,
  feedRateMetricIncrement: 1,
  feedRateMin: 0.05,
  feedRateMax: 100,
  overrideMin: 0,
  overrideMax: 400,
  overrideStep: 5,
  recommendationUpdated: 0,
  recommendationKey: 0,

  // Cost Calculations

  costCalculations: [
    {
      title: "",
      bladeLife: 0,
      pricePerBlade: null,
      cutsPerBlade: null,
      measurement: null,
      laborBurdenRate: null,
      costsPerCut: null,
      costsPerMeasurement: null,
    },
    {
      title: "",
      bladeLife: 0,
      pricePerBlade: null,
      cutsPerBlade: null,
      measurement: null,
      laborBurdenRate: null,
      costsPerCut: null,
      costsPerMeasurement: null,
    },
    {
      title: "",
      bladeLife: 0,
      pricePerBlade: null,
      cutsPerBlade: null,
      measurement: null,
      laborBurdenRate: null,
      costsPerCut: null,
      costsPerMeasurement: null,
    },
    {
      title: "Custom",
      bladeLife: 0,
      pricePerBlade: null,
      cutsPerBlade: null,
      measurement: null,
      laborBurdenRate: null,
      costsPerCut: null,
      costsPerMeasurement: null,
    },
  ],

  // Login

  loginDialog: {
    show: false,
    loading: false,
  },

  // Register

  registerDialog: {
    show: false,
    loading: false,
  },

  // Password Reset

  passwordResetDialog: {
    show: false,
    loading: false,
  },

  // User

  user: null,

  // Authentication

  token: null,

  // Users

  users: [],
  usersKey: 0,
  countries: [],
  defaultCountry: { code: "US", countryName: "United States" },
  dataTableUserLinksKey: 0,

  // Stats

  stats: [],

  // Axios

  axiosSource: null,

  // UI

  sawCalcDrawer: false,
  images: {
    home: {
      cover: {
        fileName: null,
        data: null,
        // defaultURL: "../images/saw-calc-home-cover-default.jpg",
        defaultURL: "../images/clear.jpg",
        loading: false,
        loadingURL: "../images/loading.jpg",
        maxSize: 300,
      },
    },
  },

  dataTableSettingsDialog: {
    show: false,
    loading: false,
  },
  dataTableUOMSettingsDialog: {
    show: false,
    loading: false,
  },
  dataTableSettingsKey: 0,
  dataTableUOMSettingsKey: 0,

  // Admin

  // Machines

  machinesAdmin: [],
  machinesAdminSettings: {},

  // Materials

  materialsAdmin: [],
  materialsAdminSettings: {},
  materialsLoading: false,
  materialDialogKey: 0,
  dataTableChemicalCompositionsDialog: {
    show: false,
    loading: false,
  },
  chemicalCompositionsDialogKey: 0,

  // ProductLines

  productLinesSettings: {},
  productLineDialogKey: 0,
  productLinesShort: [],
  bwlRefs: [],

  // ProductTypes

  productTypes: [],

  // Recommendations

  recommendationsAdmin: [],
  recommendationsAdminSettings: {},

  // CuttingCharts

  cuttingCharts: [],
  cuttingChartsSettings: {},

  // Parameters

  parameters: [],
  productSelections: [],
  productRecommendations: [],

  // User

  // Logo

  userLogoUrl: "",
  userLogoImageMaxSize: 300,

  // Machines

  machinesUserSettings: {},
  machineImageMaxSize: 300,

  // Materials

  materialsUserSettings: {},

  // UserRecommendations

  userRecommendations: [],
  recommendationsUserSettings: {},
};
