const returnCode = {
  Success: 0,
  ErrRecordNotFound: 103,
  ErrRecordExists: 104,
  ErrAccessTokenChanged: 112,
  ErrAICInvalidAccessToken: 200,
  ErrBadRequest: 400,
  ErrAICAuthCodeNotValid: 413,
  ErrAICTokenExpired: 414,
  ErrToManyRequests: 429,
  ErrServerError: 500,
  ErrEngine: 601,
  ErrNetworkError: 801,
};

export { returnCode };
