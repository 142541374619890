<template>
  <v-dialog
    v-model="registerDialog.show"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    max-width="600px"
  >
    <v-card>
      <v-card-title class="card-title">
        <h3>
          {{ $t("register.title") }}
        </h3>
        <v-spacer />
        <span @click="login()">
          <span class="action-link">{{ $t("register.login") }}</span
          >&nbsp;
          <span class="action-link-text">{{ $t("register.instead") }}</span>
        </span>
      </v-card-title>

      <v-form ref="form">
        <v-card-text>
          <v-container class="pb-0">
            <v-row>
              <v-col
                class="pa-0 px-3"
                cols="12"
                sm="7"
                :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
              >
                <v-text-field
                  autofocus
                  :label="$t('register.companyNameLabel')"
                  :disabled="registerDialog.loading"
                  v-model="companyName"
                  :rules="inputRules"
                >
                  <v-icon slot="prepend" color="primary lighten-1"
                    >mdi-domain</v-icon
                  >
                </v-text-field>
              </v-col>
              <v-col
                class="pa-0 px-3"
                cols="12"
                sm="5"
                :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
              >
                <v-text-field
                  :label="$t('register.phoneLabel')"
                  :disabled="registerDialog.loading"
                  v-model="phone"
                  prepend-icon="mdi-empty"
                >
                </v-text-field>
              </v-col>
              <v-col
                class="pa-0 px-3"
                cols="12"
                sm="6"
                :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
              >
                <v-text-field
                  :label="$t('register.streetLabel')"
                  :disabled="registerDialog.loading"
                  v-model="street"
                  prepend-icon="mdi-empty"
                >
                </v-text-field>
              </v-col>
              <v-col
                class="pa-0 px-3"
                cols="12"
                sm="6"
                :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
              >
                <v-text-field
                  :label="$t('register.cityLabel')"
                  :disabled="registerDialog.loading"
                  v-model="city"
                  prepend-icon="mdi-empty"
                  :rules="inputRules"
                >
                </v-text-field>
              </v-col>
              <v-col
                class="pa-0 px-3"
                cols="12"
                sm="4"
                :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
              >
                <v-text-field
                  :label="$t('register.stateLabel')"
                  :disabled="registerDialog.loading"
                  v-model="state"
                  prepend-icon="mdi-empty"
                >
                </v-text-field>
              </v-col>
              <v-col
                class="pa-0 px-3"
                cols="12"
                sm="4"
                :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
              >
                <v-text-field
                  :label="$t('register.postalCodeLabel')"
                  :disabled="registerDialog.loading"
                  v-model="postalCode"
                  prepend-icon="mdi-empty"
                >
                </v-text-field>
              </v-col>
              <v-col
                class="pa-0 px-3"
                cols="12"
                sm="4"
                :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
              >
                <v-autocomplete
                  :label="$t('register.countryLabel')"
                  :disabled="registerDialog.loading"
                  :items="countries"
                  item-text="countryName"
                  item-value="countryID"
                  :rules="countryRules"
                  @change="countryChanged"
                ></v-autocomplete>
              </v-col>
              <v-col
                class="pa-0 px-3"
                cols="12"
                sm="5"
                :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
              >
                <v-text-field
                  :label="$t('register.firstNameLabel')"
                  :disabled="registerDialog.loading"
                  v-model="firstName"
                  :rules="inputRules"
                >
                  <v-icon slot="prepend" color="primary lighten-1"
                    >mdi-account</v-icon
                  >
                </v-text-field>
              </v-col>
              <v-col
                class="pa-0 px-3"
                cols="12"
                sm="7"
                :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
              >
                <v-text-field
                  :label="$t('register.lastNameLabel')"
                  :disabled="registerDialog.loading"
                  v-model="lastName"
                  prepend-icon="mdi-empty"
                  :rules="inputRules"
                ></v-text-field>
              </v-col>
              <v-col
                class="pa-0 px-3"
                sm="7"
                :class="{ 'hide-empty': $vuetify.breakpoint.smAndDown }"
              ></v-col>
              <v-col
                class="pa-0 px-3"
                cols="12"
                sm="6"
                :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
              >
                <v-text-field
                  :label="$t('register.emailLabel')"
                  :disabled="registerDialog.loading"
                  v-model="email"
                  prepend-icon="mdi-email"
                  :rules="emailRules"
                >
                  <v-icon slot="prepend" color="primary lighten-1"
                    >mdi-email</v-icon
                  >
                </v-text-field>
              </v-col>
              <v-col
                class="pa-0 px-3"
                cols="12"
                sm="6"
                :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
              >
                <v-text-field
                  :label="$t('register.confirmEmailLabel')"
                  :disabled="registerDialog.loading"
                  v-model="confirmEmail"
                  prepend-icon="mdi-empty"
                  :rules="confirmEmailRules"
                ></v-text-field>
              </v-col>
              <v-col
                class="pa-0 px-3 pr-8"
                cols="12"
                sm="6"
                :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
              >
                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="passwordRules"
                  :type="showPassword ? 'text' : 'password'"
                  :label="$t('register.passwordLabel')"
                  :disabled="registerDialog.loading"
                  @click:append="showPassword = !showPassword"
                >
                  <v-icon slot="prepend" color="primary lighten-1"
                    >mdi-lock</v-icon
                  >
                </v-text-field>
              </v-col>
              <v-col
                class="pa-0 px-3 pr-8"
                cols="12"
                sm="6"
                :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
              >
                <v-text-field
                  v-model="confirmPassword"
                  prepend-icon="mdi-empty"
                  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="confirmPasswordRules"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  :label="$t('register.confirmPasswordLabel')"
                  :disabled="registerDialog.loading"
                  @click:append="showConfirmPassword = !showConfirmPassword"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="marketingEmail"
                  :label="$t('register.marketingEmailLabel')"
                  :disabled="registerDialog.loading"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions
          :class="{
            'padding-action-small': $vuetify.breakpoint.smAndDown,
            'padding-action': $vuetify.breakpoint.mdAndUp,
          }"
        >
          <v-container class="px-1 py-0">
            <v-row>
              <v-col cols="12" sm="6" class="pt-0">
                <v-btn
                  depressed
                  tile
                  v-html="$t('register.cancelButton')"
                  class="primary white--text full-width"
                  @click="cancel"
                ></v-btn>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0">
                <v-btn
                  depressed
                  tile
                  v-html="$t('register.submitButton')"
                  class="primary white--text full-width"
                  :disabled="registerDialog.loading"
                  :loading="registerDialog.loading"
                  @click="register"
                ></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";
import { mask } from "vue-the-mask";
import { MASK } from "@/constants/Masks";

export default {
  name: "Register",

  directives: {
    mask,
  },

  data() {
    return {
      companyName: "",
      street: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      phone: "",
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      password: "",
      confirmPassword: "",
      marketingEmail: false,
      showPassword: false,
      showConfirmPassword: false,
      inputRules: [
        (v) => (!!v && v.length > 0) || this.$t("register.inputRules"),
      ],
      countryRules: [(v) => !!v || this.$t("register.inputRules")],
      emailRules: [
        (v) => !!v || this.$t("register.emailRequired"),
        (v) => /.+@.+\..+/.test(v) || this.$t("register.emailValid"),
      ],
      confirmEmailRules: [
        (v) => !!v || this.$t("register.emailRequired"),
        (v) => /.+@.+\..+/.test(v) || this.$t("register.emailValid"),
        (v) => (!!v && v === this.email) || this.$t("register.emailMatch"),
      ],
      passwordRules: [
        (v) =>
          // eslint-disable-next-line no-useless-escape
          /^(?!.*(.)\1\1.*)(?=^.{8,}$)((?=.*\d)|(?=.*[£!@#\$%\^&\*]))((?=.*[A-Z])|(?=.*[a-z])).*$/.test(
            v
          ) || this.$t("register.passwordAICRules"),
      ],
      confirmPasswordRules: [
        (v) => (!!v && v.length >= 8) || this.$t("register.passwordAICRules"),
        (v) =>
          (!!v && v === this.password) || this.$t("register.passwordMatch"),
      ],
      phoneMask: MASK.phone,
    };
  },

  computed: {
    ...mapGetters(["registerDialog", "countries", "axiosSource"]),
  },

  methods: {
    countryChanged(id) {
      const country = this.countries.find(({ countryID }) => countryID === id);
      this.country = country.code;
    },
    cancel() {
      this.$store.dispatch("cancelRequest");
      this.$store.dispatch("registerLoading", false);
      this.$store.dispatch("showRegisterDialog", false);
      this.resetForm();
    },
    passwordRepeatedChar(v) {
      const match = /[a-zA-Z0-9]{3,}/.test(v);
      return !match;
    },
    register() {
      if (this.$refs.form.validate()) {
        const form = {
          companyName: this.companyName,
          street: this.street,
          city: this.city,
          postalCode: this.postalCode,
          country: this.country,
          state: this.state,
          phone: this.phone,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          marketingEmail: this.marketingEmail,
        };

        this.$store.dispatch("registerLoading", true);

        this.$store.dispatch("register", form).then(
          (response) => {
            this.$store.dispatch("registerLoading", false);
            this.$store.dispatch("showRegisterDialog", false);
            this.resetForm();

            const notification = {
              show: true,
              result: true,
              message: response.message,
            };

            this.$store.dispatch("showSnackbar", notification);

            if (this.user) {
              if (this.user.admin) {
                router.push({ name: "DashboardAdmin" }).catch(() => {});
              } else {
                router.push({ name: "Dashboard" }).catch(() => {});
              }
            }
          },
          (error) => {
            this.$store.dispatch("registerLoading", false);

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }

            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };

            this.$store.dispatch("showSnackbar", notification);

            // eslint-disable-next-line
            console.error("error: ", error);
          }
        );
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
    login() {
      if (this.registerDialog.loading) {
        return;
      }

      this.$store.dispatch("showRegisterDialog", false);
      this.$store.dispatch("showLoginDialog", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__title {
  padding: 20px 20px 0px 20px;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 0px 20px 0px 10px;
}

.padding-input {
  padding: 0 10px;
}

.padding-action {
  padding: 0px 20px 20px 20px;
}

.padding-action-small {
  padding: 5px 20px 10px 20px;
}

.hide-empty {
  display: none;
}
</style>
