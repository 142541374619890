const machineDetails = {
  getFraction(fraction) {
    if (fraction > 0 && fraction <= 0.125) {
      return "1/8";
    } else if (fraction <= 0.25) {
      return "1/4";
    } else if (fraction <= 0.375) {
      return "3/8";
    } else if (fraction <= 0.5) {
      return "1/2";
    } else if (fraction <= 0.625) {
      return "5/8";
    } else if (fraction <= 0.75) {
      return "3/4";
    } else if (fraction <= 0.875) {
      return "7/8";
    } else {
      return "";
    }
  },
  getBladeWidth(item, metric) {
    if (metric) {
      return item.bladeWidthMetric + "mm";
    } else {
      let desc = "";

      if (item.bladeWidthIn > 0) {
        desc = item.bladeWidthIn;
      }

      if (item.bladeWidthFr > 0) {
        if (item.bladeWidthIn > 0) {
          desc += "-";
        }

        desc += this.getFraction(item.bladeWidthFr);
      }

      desc += '"';

      return desc;
    }
  },
  getBladeThickness(item, metric) {
    if (metric) {
      return item.bladeThicknessMetric + "mm";
    } else {
      return item.bladeThicknessImperial + '"';
    }
  },
  getBladeLength(item, metric) {
    if (metric) {
      return item.bladeLengthMetric + "mm";
    } else {
      let desc = item.bladeLengthFt + "ft";

      if (item.bladeLengthIn > 0) {
        desc += " " + item.bladeLengthIn;
      }

      if (item.bladeLengthFr > 0) {
        if (item.bladeLengthIn > 0) {
          desc += "-";
        }

        desc += this.getFraction(item.bladeLengthFr);
      }

      if (item.bladeLengthIn > 0 || item.bladeLengthFr > 0) {
        desc += "in";
      }

      return desc;
    }
  },
  getMachineOrientation(item, messages) {
    if (item.hortizontal) {
      return messages.sawCalc.inputMachine.machineOrientationHorizontalLabel;
    } else {
      return messages.sawCalc.inputMachine.machineOrientationVerticalLabel;
    }
  },
  getScissorPivot(item, messages) {
    if (item.scissorPivot) {
      return messages.common.yes;
    } else {
      return messages.common.no;
    }
  },
  getCantedHead(item, messages) {
    if (item.cantedHead) {
      return messages.common.yes;
    } else {
      return messages.common.no;
    }
  },
  getBandSpeed(item, metric, min) {
    if (metric) {
      if (min) {
        return item.bandSpeedMinMetric + " mpm";
      } else {
        return item.bandSpeedMaxMetric + " mpm";
      }
    } else {
      if (min) {
        return item.bandSpeedMinImperial + " fpm";
      } else {
        return item.bandSpeedMaxImperial + " fpm";
      }
    }
  },
};

export { machineDetails };
