const calculations = {
  conversion: 25.4,
  inValue(value, precision, trim) {
    if (!trim) {
      return parseFloat(value).toFixed(precision) + "in";
    } else {
      return parseFloat(parseFloat(value).toFixed(precision)) + "in";
    }
  },
  mmValue(value, precision, trim) {
    if (!trim) {
      return parseFloat(value).toFixed(precision) + "mm";
    } else {
      return parseFloat(parseFloat(value).toFixed(precision)) + "mm";
    }
  },
  mmToIn(value) {
    return parseFloat((value / this.conversion).toFixed(3));
  },
  inToMM(value) {
    return parseFloat((value * this.conversion).toFixed(2));
  },
  fpmToMpm(value) {
      return (value * 12 * this.conversion) / 1000;
  },
  mpmToFpm(value) {
      return (value * 1000) / this.conversion / 12;
  },
  sqInToSqCM(value) {
    return parseInt(value * Math.pow(this.conversion / 10, 2));
  },
  sqCMToSqIn(value) {
    return parseInt(value / Math.pow(this.conversion / 10, 2));
  },
  lengthImperial(lengthFt, lengthIn, lengthFr) {
    const length = lengthFt * 12 + lengthIn + lengthFr;
    return parseFloat(parseFloat(length).toFixed(2));
  },
  getFraction(fraction) {
    if (fraction > 0 && fraction <= 0.125) {
      return "1/8";
    } else if (fraction <= 0.25) {
      return "1/4";
    } else if (fraction <= 0.375) {
      return "3/8";
    } else if (fraction <= 0.5) {
      return "1/2";
    } else if (fraction <= 0.625) {
      return "5/8";
    } else if (fraction <= 0.75) {
      return "3/4";
    } else if (fraction <= 0.875) {
      return "7/8";
    } else {
      return "";
    }
  },
  getFractionValue(fraction) {
    if (fraction <= 0) {
      return 0;
    } else if (fraction > 0 && fraction <= 0.125) {
      return 0.125;
    } else if (fraction <= 0.25) {
      return 0.25;
    } else if (fraction <= 0.375) {
      return 0.375;
    } else if (fraction <= 0.5) {
      return 0.5;
    } else if (fraction <= 0.625) {
      return 0.625;
    } else if (fraction <= 0.75) {
      return 0.75;
    } else if (fraction <= 0.875) {
      return 0.875;
    } else {
      return 0;
    }
  },
  getBladeWidth(machine, metric, bladeWidthImperialList, bladeWidthMetricList) {
    const itemImperial = machine.bladeWidthImperial;
    const itemMetric = machine.bladeWidthMetric;

    if (!metric) {
      if (itemImperial) {
        const index = bladeWidthImperialList.indexOf(itemImperial);
        const bladeWidthMetric = bladeWidthMetricList[index];

        if (bladeWidthMetric != undefined) {
          return {
            in: parseInt(itemImperial.value),
            fr: this.getFractionValue(
              parseFloat(itemImperial.value) - parseInt(itemImperial.value)
            ),
            mm: bladeWidthMetric,
          };
        } else {
          return { in: 0, fr: 0, mm: 0 };
        }
      } else {
        return { in: 0, fr: 0, mm: 0 };
      }
    } else {
      if (itemMetric) {
        const index = bladeWidthMetricList.indexOf(itemMetric);
        const bladeWidthImperial = bladeWidthImperialList[index];

        if (bladeWidthImperial != undefined) {
          return {
            in: parseInt(bladeWidthImperial.value),
            fr: this.getFractionValue(
              parseFloat(bladeWidthImperial.value) -
                parseInt(bladeWidthImperial.value)
            ),
            mm: itemMetric,
          };
        } else {
          return { in: 0, fr: 0, mm: 0 };
        }
      } else {
        return { in: 0, fr: 0, mm: 0 };
      }
    }
  },
  getBladeThickness(
    machine,
    metric,
    bladeThicknessImperialList,
    bladeThicknessMetricList
  ) {
    const itemImperial = machine.bladeThicknessImperial;
    const itemMetric = machine.bladeThicknessMetric;

    if (!metric) {
      if (itemImperial) {
        const index = bladeThicknessImperialList.indexOf(itemImperial);
        const bladeThicknessMetric = bladeThicknessMetricList[index];

        if (bladeThicknessMetric != undefined) {
          return {
            in: itemImperial,
            mm: bladeThicknessMetric,
          };
        } else {
          return { in: 0, fr: 0, mm: 0 };
        }
      } else {
        return { in: 0, mm: 0 };
      }
    } else {
      if (itemMetric) {
        const index = bladeThicknessMetricList.indexOf(itemMetric);
        const bladeThicknessImperial = bladeThicknessImperialList[index];

        if (bladeThicknessImperial != undefined) {
          return {
            in: bladeThicknessImperial,
            mm: itemMetric,
          };
        } else {
          return { in: 0, fr: 0, mm: 0 };
        }
      } else {
        return { in: 0, mm: 0 };
      }
    }
  },
  getBladeLength(machine, metric) {
    const ft = machine.bladeLengthFt;
    const inch = machine.bladeLengthIn != null ? machine.bladeLengthIn : 0;
    
    //let fr = machine.bladeLengthFr != null ? machine.bladeLengthFr : 0;
    //const mm = machine.bladeLengthMetric;

    /***DFCT0020656****/
    let fr = 0;
    if (machine.bladeLengthFr != null) {
        let frObj = JSON.parse(JSON.stringify(machine.bladeLengthFr));
        if (Object.prototype.hasOwnProperty.call(frObj,'text')) {
            fr = frObj.value;
        } 
    } 
    //const mm = machine.bladeLengthMetric;
    const mm = JSON.parse(JSON.stringify(machine.bladeLengthMetric));
    /********/

    if (!metric) {
      return {
        ft: parseInt(ft),
        in: parseInt(inch),
        fr: this.getFractionValue(parseFloat(fr)),
        imperial: parseInt(ft) * 12 + parseInt(inch) + parseFloat(fr),
        mm: Math.round(
          (parseInt(ft) * 12 + parseInt(inch) + parseFloat(fr)) *
            this.conversion
        ),
      };
    } else {
      const inValue = parseFloat(mm / this.conversion);
      return {
        ft: parseInt(inValue / 12),
        in: parseInt(inValue % 12),
        fr: this.getFractionValue(inValue - parseInt(inValue)),
        imperial: inValue,
        mm: parseInt(mm),
      };
    }
  },
  getBandSpeed(machine, min, metric) {
    let bandSpeedImperial = machine.bandSpeedMinImperial;
    let bandSpeedMetric = machine.bandSpeedMinMetric;

    if (!min) {
      bandSpeedImperial = machine.bandSpeedMaxImperial;
      bandSpeedMetric = machine.bandSpeedMaxMetric;
    }

    if (!metric) {
      if (bandSpeedImperial) {
        return {
          fpm: parseInt(bandSpeedImperial),
          mpm: parseInt(this.fpmToMpm(bandSpeedImperial)),
        };
      } else {
        return { fpm: 0, mpm: 0 };
      }
    } else {
      if (bandSpeedMetric) {
        return {
          fpm: parseInt(this.mpmToFpm(bandSpeedMetric)),
          mpm: bandSpeedMetric,
        };
      } else {
        return { fpm: 0, mpm: 0 };
      }
    }
  },
  HBtoHRC(value) {
    const min = 20;
    const max = 65;
    let result = 0;

    value = parseFloat(value);

    if (value > 0 && value <= 226) {
      result = min;
    } else if (value > 226 && value <= 286) {
      result = (value - 104.7) / 5.97;
    } else if (value > 286 && value <= 371) {
      result = (value - 27.6) / 8.57;
    } else if (value > 371 && value <= 482) {
      result = (value + 79.6) / 11.158;
    } else if (value > 482 && value <= 739) {
      result = (value + 401) / 17.515;
    } else {
      result = max;
    }

    return result;
  },
  HRCtoHB(value) {
    const min = 226;
    const max = 739;
    let result = 0;

    value = parseFloat(value);

    if (value > 0 && value <= 20) {
      result = min;
    } else if (value > 20 && value <= 30) {
      result = 5.97 * value + 104.7;
    } else if (value > 30 && value <= 40) {
      result = 8.57 * value + 27.6;
    } else if (value > 40 && value <= 50) {
      result = 11.158 * value - 79.6;
    } else if (value > 50 && value <= 65) {
      result = 17.515 * value - 401;
    } else {
      result = max;
    }

    return result;
  },
  downloadFileName(title, ext) {
    const today = new Date();

    const date =
      today.getMonth() + 1 + "-" + today.getDate() + "-" + today.getFullYear();
    const time =
      today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();

    return title + " " + date + " " + time + ext;
  },
  secToTime(value) {
    let desc = "";

    if (value == undefined || value <= 0) {
      return desc;
    }

    value = parseInt(value);

    const hours = parseInt(value / 3600);
    const min = parseInt(value / 60) - hours * 60;
    const sec = value - hours * 3600 - min * 60;

    if (hours > 0) {
      desc = `${hours} hours `;
    }

    if (min > 0) {
      desc += `${min} min `;
    }

    if (sec > 0) {
      desc += `${sec} sec `;
    }

    return desc.trim();
  },
};

export { calculations };
