<template>
  <v-container fluid class="py-0">
    <v-row justify="center">
      <v-col class="px-0 py-0">
        <v-card flat>
          <v-card-text class="pa-2">
            <v-container fluid class="pa-0">
              <v-row>
                <v-col justify="center" align="left" class="px-2 py-0">
                  <v-btn-toggle
                    depressed
                    tile
                    dark
                    mandatory
                    :value="inputUOM"
                    @change="uomChanged"
                  >
                    <v-btn
                      color="primary"
                      v-html="$t('sawCalc.inputUOM.imperial')"
                      :x-small="$vuetify.breakpoint.mdAndDown"
                    ></v-btn>
                    <v-btn
                      color="primary"
                      v-html="$t('sawCalc.inputUOM.metric')"
                      :x-small="$vuetify.breakpoint.mdAndDown"
                    ></v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "InputsUOM",

  data() {
    return {};
  },

  computed: {
    ...mapGetters(["inputUOM"]),
  },

  methods: {
    ...mapActions(["changeInputUOM"]),
    uomChanged(index) {
      this.changeInputUOM(index === 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  max-height: 30px;
  min-height: 23px;
}
</style>
