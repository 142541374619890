<template>
  <v-dialog
    v-model="loginDialog.show"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    max-width="400px"
  >
    <v-card>
      <v-card-title class="card-title">
        <h3>
          {{ $t("login.title") }}
        </h3>
        <v-spacer />
        <span @click="register()">
          <span class="action-link">{{ $t("login.register") }}</span
          >&nbsp;
          <span class="action-link-text">{{ $t("login.instead") }}</span>
        </span>
      </v-card-title>

      <v-form ref="form">
        <v-card-text>
          <v-container class="pb-0">
            <v-row>
              <v-col
                cols="12"
                :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
              >
                <v-text-field
                  autofocus
                  :label="$t('login.emailLabel')"
                  :disabled="loginDialog.loading"
                  v-model="email"
                  prepend-icon="mdi-email"
                  @keydown.enter="login()"
                  :rules="emailRules"
                >
                  <v-icon slot="prepend" color="primary lighten-1"
                    >mdi-email</v-icon
                  >
                </v-text-field>
              </v-col>
              <v-col
                cols="9"
                :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
              >
                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  :label="$t('login.passwordLabel')"
                  :disabled="loginDialog.loading"
                  @click:append="showPassword = !showPassword"
                  @keydown.enter="login()"
                  :rules="inputRules"
                >
                  <v-icon slot="prepend" color="primary lighten-1"
                    >mdi-lock</v-icon
                  >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions
          :class="{
            'padding-action-small': $vuetify.breakpoint.smAndDown,
            'padding-action': $vuetify.breakpoint.mdAndUp,
          }"
        >
          <v-container class="px-1 py-0">
            <v-row>
              <v-col align="right">
                <span class="action-link" @click="passwordReset()">{{
                  $t("login.passwordReset")
                }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="pt-0">
                <v-btn
                  depressed
                  tile
                  v-html="$t('login.cancelButton')"
                  class="primary white--text full-width"
                  @click="cancel"
                ></v-btn>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0">
                <v-btn
                  depressed
                  tile
                  v-html="$t('login.submitButton')"
                  class="primary white--text full-width"
                  :disabled="loginDialog.loading"
                  :loading="loginDialog.loading"
                  @click="login"
                ></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

export default {
  name: "Login",

  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      inputRules: [(v) => (!!v && v.length > 0) || this.$t("login.inputRules")],
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Email must be valid",
      ],
    };
  },

  computed: {
    ...mapGetters(["loginDialog", "user", "axiosSource"]),
  },

  methods: {
    cancel() {
      this.$store.dispatch("cancelRequest");
      this.$store.dispatch("loginLoading", false);
      this.$store.dispatch("showLoginDialog", false);
      this.resetForm();
    },
    passwordReset() {
      if (this.loginDialog.loading) {
        return;
      }

      this.$store.dispatch("loginLoading", false);
      this.$store.dispatch("showLoginDialog", false);
      this.resetForm();
      this.$store.dispatch("showPasswordResetDialog", true);
    },
    login() {
      if (this.$refs.form.validate()) {
        const form = {
          email: this.email,
          password: this.password,
        };

        this.$store.dispatch("loginLoading", true);

        this.$store.dispatch("login", form).then(
          (response) => {
            this.$store.dispatch("loginLoading", false);
            this.$store.dispatch("showLoginDialog", false);
            this.resetForm();

            const notification = {
              show: true,
              result: true,
              message: response.message,
            };

            this.$store.dispatch("showSnackbar", notification);

            if (this.user) {
              if (this.user.admin) {
                router.push({ name: "DashboardAdmin" }).catch(() => {});
              } else {
                router.push({ name: "Dashboard" }).catch(() => {});
              }
            }
          },
          (error) => {
            this.$store.dispatch("loginLoading", false);

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }

            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };

            this.$store.dispatch("showSnackbar", notification);

            // eslint-disable-next-line
            console.error("error: ", error);
          }
        );
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
    register() {
      if (this.loginDialog.loading) {
        return;
      }

      this.$store.dispatch("loginLoading", false);
      this.$store.dispatch("showLoginDialog", false);
      this.$store.dispatch("showRegisterDialog", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__title {
  padding: 20px 20px 0px 20px;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 0px 20px 0px 10px;
}

.padding-input {
  padding: 0 10px;
}

.padding-action {
  padding: 0px 20px 20px 20px;
}

.padding-action-small {
  padding: 5px 20px 10px 20px;
}
</style>
