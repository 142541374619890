var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"back",style:({ backgroundImage: `url('${_vm.getURL}')` }),attrs:{"fluid":"","fill-height":""}},[_c('v-row',{attrs:{"id":"content"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('h1',{class:{
            'margin-small': _vm.$vuetify.breakpoint.mdAndUp,
            'white--text': !_vm.imageLoading,
            'primary--text': _vm.imageLoading,
          },domProps:{"innerHTML":_vm._s(_vm.$t('home.title'))}})])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('p',{class:{
            'margin-small': _vm.$vuetify.breakpoint.mdAndUp,
            'white--text': !_vm.imageLoading,
            'black--text': _vm.imageLoading,
          },domProps:{"innerHTML":_vm._s(_vm.$t('home.description'))}})])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-btn',{staticClass:"white primary--text",class:{ 'margin-small': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"depressed":"","tile":"","to":{ name: 'SawCalc' }},domProps:{"innerHTML":_vm._s(_vm.$t('home.sawCalcButton'))}})],1),(!_vm.user)?_c('v-row',{staticClass:"mt-5"},[_c('v-btn',{staticClass:"primary white--text",class:{ 'margin-small': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"depressed":"","tile":""},domProps:{"innerHTML":_vm._s(_vm.$t('home.loginButton'))},on:{"click":function($event){return _vm.login()}}}),_c('span',{staticClass:"pa-0 ml-5 mt-1",class:{
            'margin-small mt-4': _vm.$vuetify.breakpoint.mdAndUp,
            'white--text': !_vm.imageLoading,
            'black--text': _vm.imageLoading,
          },domProps:{"innerHTML":_vm._s(_vm.$t('home.or'))}}),_c('v-btn',{staticClass:"primary white--text ml-4",class:{ 'margin-small': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"depressed":"","tile":""},domProps:{"innerHTML":_vm._s(_vm.$t('home.registerButton'))},on:{"click":function($event){return _vm.register()}}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }