import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: {
    // de: require("../locales/de.json"),
    en: require("../locales/en.json"),
    // es: require("../locales/es.json"),
    // fr: require("../locales/fr.json")
  },
});

export default i18n;
