<template>
  <v-container fluid class="white black--text py-0">
    <v-row>
      <v-col class="pa-0 ma-0 primary" style="height: 2px;"></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" sm="6" class="px-0 pb-0">
        <v-row align="center" justify="center" class="py-0">
          <v-container class="py-0">
            <v-row>
              <v-col align="center" justify="center" class="pt-1 pb-0">
                <ul>
                  <li
                    v-for="(item, i) in footer.info.contact"
                    :key="`${i}-footer-info-contact`"
                    class="mx-1 footer-info-contact"
                    @click="navigationClick(item)"
                  >
                    {{ item.title }}
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-container>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" sm="6" class="px-0 pb-0">
        <v-row align="center" justify="center" class="py-0">
          <v-container class="py-0">
            <v-row>
              <v-col
                align="center"
                justify="center"
                class="py-0 info-caption-links"
              >
                <template v-for="(item, i) in footer.info.social">
                  <template v-if="item.icon">
                    <v-btn
                      :key="`${i}-footer-info-connect`"
                      text
                      icon
                      color="primary"
                      @click="navigationClick(item)"
                    >
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-btn>
                  </template>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FooterInfo",

  computed: {
    ...mapGetters(["footer"])
  },

  methods: {
    navigationClick(link) {
      if (link.action == "url") {
        this.openUrl(link.path);
      }
    },
    openUrl(url) {
      window.open(url);
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-info-contact {
  display: inline;
  color: map-get($theme, primary-color);
  font-size: map-get($theme, font-size-secondary);
  font-family: map-get($theme, font-family-primary), sans-serif;
  font-weight: map-get($theme, font-weight-primary-navigation);
  font-style: italic;
  text-transform: uppercase;
  cursor: pointer;
}
</style>
