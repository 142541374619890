import Vue from "vue";
import Router from "vue-router";
import Home from "@/views/public/Home.vue";
import store from "@/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/saw-calc",
      name: "SawCalc",
      component: () =>
        import(
          /* webpackChunkName: "saw-calc" */ "../views/public/SawCalc.vue"
        ),
    },
    {
      path: "/blade-terminology",
      name: "BladeTerminology",
      component: () =>
        import(
          /* webpackChunkName: "blade-terminology" */ "../views/public/BandSawGuide/BladeTerminology.vue"
        ),
    },
    {
      path: "/blade-construction",
      name: "BladeConstruction",
      component: () =>
        import(
          /* webpackChunkName: "blade-construction" */ "../views/public/BandSawGuide/BladeConstruction.vue"
        ),
    },
    {
      path: "/tooth-options",
      name: "ToothOptions",
      component: () =>
        import(
          /* webpackChunkName: "tooth-options" */ "../views/public/BandSawGuide/ToothOptions.vue"
        ),
    },
    {
      path: "/cutting-factors",
      name: "CuttingFactors",
      component: () =>
        import(
          /* webpackChunkName: "cutting-factors" */ "../views/public/BandSawGuide/CuttingFactors.vue"
        ),
    },
    {
      path: "/beam-strength",
      name: "BeamStrength",
      component: () =>
        import(
          /* webpackChunkName: "beam-strength" */ "../views/public/BandSawGuide/BeamStrength.vue"
        ),
    },
    {
      path: "/lubrication",
      name: "Lubrication",
      component: () =>
        import(
          /* webpackChunkName: "lubrication" */ "../views/public/BandSawGuide/Lubrication.vue"
        ),
    },
    {
      path: "/break-in-instructions",
      name: "BreakInInstructions",
      component: () =>
        import(
          /* webpackChunkName: "break-in-instructions" */ "../views/public/BandSawGuide/BreakInInstructions.vue"
        ),
    },
    {
      path: "/basic-maintenance-tips",
      name: "BasicMaintenanceTips",
      component: () =>
        import(
          /* webpackChunkName: "basic-maintenance-tips" */ "../views/public/BandSawGuide/BasicMaintenanceTips.vue"
        ),
    },
    {
      path: "/common-problems",
      name: "CommonProblems",
      component: () =>
        import(
          /* webpackChunkName: "common-problems" */ "../views/public/BandSawGuide/CommonProblems.vue"
        ),
    },
    {
      path: "/glossary-of-bandsaw-terms",
      name: "GlossaryOfBandsawTerms",
      component: () =>
        import(
          /* webpackChunkName: "glossary-of-bandsaw-terms" */ "../views/public/BandSawGuide/GlossaryOfBandsawTerms.vue"
        ),
    },
    {
      path: "/lenox-advantage",
      name: "LENOXAdvantage",
      beforeEnter: (to) => {
        window.open(to.params.url);
      },
    },
    {
      path: "/terms-of-use",
      name: "TermsOfUse",
      beforeEnter: (to) => {
        window.open(to.params.url);
      },
    },
    {
      path: "/updated-privacy-policy",
      name: "PrivacyPolicy",
      beforeEnter: (to) => {
        window.open(to.params.url);
      },
    },
    {
      path: "/update-password",
      name: "UpdatePassword",
      component: () =>
        import(
          /* webpackChunkName: "update-password" */ "../views/public/UpdatePassword.vue"
        ),
    },
    {
      path: "/email-verification",
      name: "EmailVerification",
      component: () =>
        import(
          /* webpackChunkName: "email-verification" */ "../views/public/EmailVerification.vue"
        ),
    },
    {
      path: "*",
      name: "NotFound",
      component: () =>
        import(
          /* webpackChunkName: "not-found" */ "../views/public/NotFound.vue"
        ),
    },

    // User

    {
      path: "/dashboard",
      name: "Dashboard",
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ "../views/user/Dashboard.vue"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/machines",
      name: "Machines",
      component: () =>
        import(/* webpackChunkName: "machines" */ "../views/user/Machines.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/materials",
      name: "Materials",
      component: () =>
        import(
          /* webpackChunkName: "materials" */ "../views/user/Materials.vue"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/account",
      name: "Account",
      component: () =>
        import(/* webpackChunkName: "account" */ "../views/user/Account.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    // Admin

    {
      path: "/admin/dashboard",
      name: "DashboardAdmin",
      component: () =>
        import(
          /* webpackChunkName: "dashboard-admin" */ "../views/admin/Dashboard.vue"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin/machines",
      name: "MachinesAdmin",
      component: () =>
        import(
          /* webpackChunkName: "machines-admin" */ "../views/admin/Machines.vue"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin/materials",
      name: "MaterialsAdmin",
      component: () =>
        import(
          /* webpackChunkName: "materials-admin" */ "../views/admin/Materials.vue"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin/product-lines",
      name: "ProductLinesAdmin",
      component: () =>
        import(
          /* webpackChunkName: "product-lines-admin" */ "../views/admin/ProductLines.vue"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin/recommendations",
      name: "RecommendationsAdmin",
      component: () =>
        import(
          /* webpackChunkName: "recommendations-admin" */ "../views/admin/Recommendations.vue"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin/engine-parameters",
      name: "EngineParametersAdmin",
      component: () =>
        import(
          /* webpackChunkName: "engine-parameters-admin" */ "../views/admin/EngineParameters.vue"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin/account",
      name: "AccountAdmin",
      component: () =>
        import(
          /* webpackChunkName: "account-admin" */ "../views/admin/Account.vue"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin/dictionary-ui",
      name: "DictionaryUI",
      component: () =>
        import(
          /* webpackChunkName: "dictionary-ui" */ "../views/admin/DictionaryUI.vue"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin/dictionary-ws",
      name: "DictionaryWS",
      component: () =>
        import(
          /* webpackChunkName: "dictionary-ws" */ "../views/admin/DictionaryWS.vue"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin/translation",
      name: "Translation",
      component: () =>
        import(
          /* webpackChunkName: "translation" */ "../views/admin/Translation.vue"
        ),
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.user) {
      next({
        path: "/",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
