<template>
  <v-container
    fluid
    fill-height>
    <v-row align="center" justify="center">
      <v-col align="center" justify="center">
        <v-card max-width="500">
          <v-card-title class="card-title">
            <h3>
              {{ $t("browserCheck.title") }}
            </h3>
          </v-card-title>

          <v-card-text>
            <v-container class="pb-0">
              <v-row>
                <v-col>
                  <span class="secondary--text">{{ $t("browserCheck.message") }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-8">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span>{{ $t("browserCheck.detected") }}: {{ browserInfo }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { browserCheck } from "@/helpers/BrowserCheck";

export default {
  name: "NotFound",

  computed: {
    browserInfo() {
      const browser = browserCheck.getBrowser()
      return `${browser.name} ver.${browser.version}`
    }
  },

};
</script>