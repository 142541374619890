<template>
  <v-app>
    <NavBar />

    <Login />
    <Register />
    <PasswordReset />

    <BrowserCheck v-if="!supportedBrowser" />
    <v-main v-if="supportedBrowser" class="white">
      <transition name="slide-fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>

    <FooterInfo v-if="footerInfoVisible" />
    <FooterLinks v-if="footerLinksVisible" />
  </v-app>
</template>

<script>
import NavBar from "@/components/public/NavBar";
import FooterInfo from "@/components/public/FooterInfo";
import FooterLinks from "@/components/public/FooterLinks";
import Login from "@/components/public/Login";
import Register from "@/components/public/Register";
import PasswordReset from "@/components/public/PasswordReset";
import BrowserCheck from "@/views/public/BrowserCheck"

import { mapGetters, mapActions } from "vuex";
import i18n from "@/plugins/i18n";
import { browserCheck } from "@/helpers/BrowserCheck";


export default {
  name: "App",

  components: {
    NavBar,
    FooterInfo,
    FooterLinks,
    Login,
    Register,
    PasswordReset,
    BrowserCheck,
  },

  data() {
    return {
      publicLinks: [
        "Home",
        "SawCalc",
        "BladeTerminology",
        "BladeConstruction",
        "ToothOptions",
        "CuttingFactors",
        "BeamStrength",
        "Lubrication",
        "BreakInInstructions",
        "BasicMaintenanceTips",
        "CommonProblems",
        "GlossaryOfBandsawTerms",
        "UpdatePassword",
      ],
      userLinks: [
        "Home",
        "Dashboard",
        "Library",
        "Machines",
        "Materials",
        "SawCalc",
        "BladeTerminology",
        "BladeConstruction",
        "ToothOptions",
        "CuttingFactors",
        "BeamStrength",
        "Lubrication",
        "BreakInInstructions",
        "BasicMaintenanceTips",
        "CommonProblems",
        "GlossaryOfBandsawTerms",
        "Account",
        "UpdatePassword",
      ],
      adminLinks: [
        "Home",
        "DashboardAdmin",
        "LibraryAdmin",
        "MachinesAdmin",
        "MaterialsAdmin",
        "ProductLinesdmin",
        "RecommendationsAdmin",
        "EngineParametersAdmin",
        "SawCalc",
        "BladeTerminology",
        "BladeConstruction",
        "ToothOptions",
        "CuttingFactors",
        "BeamStrength",
        "Lubrication",
        "BreakInInstructions",
        "BasicMaintenanceTips",
        "CommonProblems",
        "GlossaryOfBandsawTerms",
        "AccountAdmin",
        "DictionaryUI",
        "DictionaryWS",
        "Translation",
        "UpdatePassword",
      ],
    };
  },

  computed: {
    ...mapGetters(["user", "languages", "language"]),
    footerInfoVisible() {
      return (
        this.publicLinks.indexOf(this.$route.name) > -1 &&
        this.$route.name != "SawCalc"
      );
    },
    footerLinksVisible() {
      return (
        this.publicLinks.indexOf(this.$route.name) > -1 ||
        this.userLinks.indexOf(this.$route.name) > -1 ||
        this.adminLinks.indexOf(this.$route.name) > -1
      );
    },
    supportedBrowser() {
      return browserCheck.supportedBrowser()
    }
  },

  methods: {
    ...mapActions(["storeInit", "changeLanguage"]),
    changeLanguage(language) {
      this.changeLanguage({ language: language, reload: false });
      i18n.locale = language.locale;
    },
  },

  created() {
    this.storeInit();
  },
};
</script>

<style lang="scss">
body {
  background-color: map-get($theme, background-color);
  color: map-get($theme, text-color);
  text-shadow: none;
}

.full-width {
  width: 100%;
}

// Static Pages

.line-caption {
  font-weight: 600;
}

.clearfix {
  overflow: auto;
}

.center {
  text-align: center;
}

.right {
  float: right;
}

.column-two {
  float: left;
  width: 50%;
}

.column-three {
  float: left;
  width: 33.3%;
  padding: 5px;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

.responsive {
  max-width: 100%;
  height: auto;
}

.list-style-type-none {
  list-style-type: none;
  margin-left: -30px;
}

.list-style-type-none li {
  margin-bottom: 10px;
}

// Static Pages

#content {
  margin: 0 0 0 10px;
}

#content h1 {
  font-family: map-get($theme, font-family-primary), sans-serif;
  font-weight: map-get($theme, font-weight-primary-title);
  font-size: map-get($theme, font-size-primary-title);
  text-shadow: 0.5px 0px map-get($theme, text-color);
  line-height: 34px;
  color: map-get($theme, text-color);
  letter-spacing: 0px;
  margin-bottom: 20px;
}

#content h2 {
  font-family: map-get($theme, font-family-primary), sans-serif;
  font-weight: map-get($theme, font-weight-primary-sub-title);
  font-size: map-get($theme, font-size-primary-sub-title);
  text-shadow: 0.5px 0px map-get($theme, secondary-color);
  line-height: 28px;
  color: map-get($theme, secondary-color);
  letter-spacing: 0px;
  margin-bottom: 10px;
}

#content h3 {
  font-family: map-get($theme, font-family-primary), sans-serif;
  font-weight: map-get($theme, font-weight-primary-sub-title);
  font-size: map-get($theme, font-size-primary-sub-title);
  text-shadow: 0.5px 0px map-get($theme, text-color);
  line-height: 28px;
  color: map-get($theme, text-color);
  letter-spacing: 0px;
  margin-bottom: 10px;
}

#content h4 {
  font-family: map-get($theme, font-family-secondary), sans-serif;
  font-weight: map-get($theme, font-weight-primary-sub-title);
  font-size: map-get($theme, font-size-secondary-sub-title);
  text-shadow: 0.5px 0px map-get($theme, text-color);
  line-height: 24px;
  color: map-get($theme, text-color);
  letter-spacing: 0px;
  margin-bottom: 0px;
}

#content p {
  font-family: map-get($theme, font-family-secondary), sans-serif;
  font-weight: map-get($theme, font-weight-primary-copy);
  font-size: map-get($theme, font-size-primary-copy);
  line-height: 22px;
  color: map-get($theme, text-color);
  margin-bottom: 15px;
}

#content li {
  padding: 5px;
}

#content ol {
  margin: 0 0 1.5em;
  padding: 0;
  counter-reset: item;
}

#content ol > li {
  margin: 0;
  padding: 0 0 0 2em;
  text-indent: -2em;
  list-style-type: none;
  counter-increment: item;
}

#content ol > li:before {
  display: inline-block;
  width: 1em;
  padding-right: 0.5em;
  font-weight: bold;
  text-align: right;
  content: counter(item) ".";
}

.video-container {
  position: relative;
  padding-top: 30%;
  overflow: hidden;
  min-height: 180px;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 300px;
  padding-right: 10px;
  padding-bottom: 5px;
}

.video-container:first-child iframe {
  padding-right: 10px;
}

.box {
  display: flex;
  align-items: center;
}

// Recommendation

.card-title {
  font-family: map-get($theme, font-family-primary), sans-serif;
  font-weight: map-get($theme, font-weight-primary-navigation);
  font-size: map-get($theme, font-size-primary-copy);
  color: map-get($theme, primary-color);
  text-transform: uppercase;
}

.card-text-caption {
  font-family: map-get($theme, font-family-secondary), sans-serif;
  font-weight: map-get($theme, font-weight-primary-copy);
  font-size: map-get($theme, font-size-card-text-caption);
  line-height: map-get($theme, line-height-card-text-caption);
  color: map-get($theme, footer-link-color);
  text-transform: uppercase;
}

.card-text-caption-uom {
  font-family: map-get($theme, font-family-secondary), sans-serif;
  font-weight: map-get($theme, font-weight-primary-copy);
  font-size: map-get($theme, font-size-card-text-caption);
  line-height: map-get($theme, line-height-card-text-caption);
  color: map-get($theme, text-color);
}

.card-text-description {
  font-family: map-get($theme, font-family-secondary), sans-serif;
  font-weight: map-get($theme, font-weight-primary-copy);
  font-size: map-get($theme, line-height-card-text-caption);
  line-height: map-get($theme, line-height-card-text-caption);
  color: map-get($theme, text-color);
}

.card-text-description-bold {
  font-family: map-get($theme, font-family-secondary), sans-serif;
  font-weight: map-get($theme, font-weight-primary-navigation);
  font-size: map-get($theme, font-size-secondary);
  line-height: map-get($theme, line-height-card-text-caption);
  color: map-get($theme, text-color);
}

.card-text-error {
  font-family: map-get($theme, font-family-secondary), sans-serif;
  font-weight: map-get($theme, font-weight-primary-copy);
  font-size: map-get($theme, font-size-card-text-caption);
  line-height: map-get($theme, line-height-card-text-caption);
  color: map-get($theme, secondary-color);
  text-transform: uppercase;
}

.centered-input input {
  text-align: center;
}

.v-slider--horizontal {
  margin-left: 0px;
  margin-right: 0px;
}

// Selector controls

.selector-title {
  font-family: map-get($theme, font-family-primary), sans-serif;
  font-weight: map-get($theme, font-weight-primary-sub-title);
  font-size: map-get($theme, font-size-primary-sub-title);
  text-shadow: 0.5px 0px map-get($theme, text-color);
  line-height: 28px;
  color: map-get($theme, text-color);
  letter-spacing: 0px;
}

.selector-tab {
  color: map-get($theme, primary-color);
  position: relative;
  cursor: pointer;
  letter-spacing: -0.2px;
  font-size: map-get($theme, font-size-secondary);
  font-family: map-get($theme, font-family-primary), sans-serif;
  font-weight: map-get($theme, font-weight-primary-navigation);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
}

// Dialogs

.dialog-button {
  font-family: map-get($theme, font-family), sans-serif;
}

.action-link {
  font-family: map-get($theme, font-family-primary), sans-serif;
  font-weight: map-get($theme, font-weight-primary-navigation);
  font-size: map-get($theme, line-height-card-text-caption);
  color: map-get($theme, secondary-color);
  text-transform: none;
  cursor: pointer;
}

.action-link-text {
  font-family: map-get($theme, font-family-primary), sans-serif;
  font-weight: map-get($theme, font-weight-primary-sub-title);
  font-size: map-get($theme, line-height-card-text-caption);
  color: map-get($theme, text-color);
  text-transform: none;
  cursor: pointer;
}

// Navigation Side Menu

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: map-get($theme, primary-color) !important;
}

// Admin - Dashboard

.rounded-card {
  border-radius: 5px;
}

// Inputs

.title-ready {
  color: map-get($theme, text-color);
}
.title-not-ready {
  color: map-get($theme, secondary-color);
}
</style>
