import { currency } from "@/constants/Currency";
import { calculations } from "@/helpers/Calculations";

export default {
  snackbar(state) {
    return state.snackbar;
  },
  navigation(state) {
    return state.navigation;
  },
  languages(state) {
    return state.languages;
  },
  language(state) {
    return state.language;
  },

  footer(state) {
    return state.footer;
  },

  // Recommendation
  // Inputs

  input(state) {
    return state.input;
  },
  metric(state) {
    return state.input.metric;
  },
  inputUOM(state) {
    return state.input.inputUOM;
  },
  bandSpeedUOM(getters) {
    return getters.input.metric ? "mpm" : "fpm";
  },
  feedRateUOM(getters) {
    return getters.input.metric ? "mm/min" : "in/min";
  },
  chipLoadUOM(getters) {
    return getters.input.metric ? "mm" : "in";
  },
  cuttingRateUOM(getters) {
    return getters.input.metric ? "cm²/min" : "in²/min";
  },
  inputMachine(state) {
    return state.input.machine;
  },
  savedInputMachine(state) {
    return state.savedInput.machine;
  },
  inputMaterial(state) {
    return state.input.material;
  },
  savedInputMaterial(state) {
    return state.savedInput.material;
  },
  inputDimensions(state) {
    return state.input.dimensions;
  },
  inputVising(state) {
    return state.input.vising;
  },
  inputConditions(state) {
    return state.input.conditions;
  },
  inputCurrency(state) {
    return state.input.inputCurrency;
  },
  resetInputs(state) {
    return state.input.resetInputs;
  },

  // Machines

  machineSelectorDialog(state) {
    return state.machineSelectorDialog;
  },
  manufacturers(state) {
    return state.manufacturers;
  },
  machines(state) {
    return state.machines;
  },
  machine(state) {
    return state.machine;
  },
  userMachines(state) {
    return state.userMachines;
  },
  bladeWidthImperialList(state) {
    return state.bladeWidthImperialList;
  },
  bladeWidthMetricList(state) {
    return state.bladeWidthMetricList;
  },
  bladeThicknessImperialList(state) {
    return state.bladeThicknessImperialList;
  },
  bladeThicknessMetricList(state) {
    return state.bladeThicknessMetricList;
  },
  bladeLengthFtList(state) {
    return state.bladeLengthFtList;
  },
  bladeLengthInList(state) {
    return state.bladeLengthInList;
  },
  bladeLengthFrList(state) {
    return state.bladeLengthFrList;
  },
  bladeLengthMetricList(state) {
    return state.bladeLengthMetricList;
  },
  unknownMachineImperialKey(state) {
    return state.unknownMachineImperialKey;
  },
  unknownMachineMetricKey(state) {
    return state.unknownMachineMetricKey;
  },
  machinesReady(state) {
    return state.machinesReady;
  },
  machineTasks(state) {
    return state.machineTasks;
  },

  // Materials

  materialSelectorDialog(state) {
    return state.materialSelectorDialog;
  },
  materialStandards(state) {
    return state.materialStandards;
  },
  unsCategories(state) {
    return state.unsCategories;
  },
  unsAlloyGroups(state) {
    return state.unsAlloyGroups;
  },
  sawCalcGroups(state) {
    return state.sawCalcGroups;
  },
  machinabilityGroups(state) {
    return state.machinabilityGroups;
  },
  unsGeneralDescriptions(state) {
    return state.unsGeneralDescriptions;
  },
  subCategories(state) {
    return state.subCategories;
  },
  materials(state) {
    return state.materials;
  },
  selectMaterials(state) {
    return state.selectMaterials;
  },
  material(state) {
    return state.material;
  },
  userMaterials(state) {
    return state.userMaterials;
  },
  scales(state) {
    return state.scales;
  },
  defaultHardness(state) {
    return state.defaultHardness;
  },
  hbHardnessMin(state) {
    return state.hbHardnessMin;
  },
  hbHardnessMinConvertable(state) {
    return state.hbHardnessMinConvertable;
  },
  hbHardnessMax(state) {
    return state.hbHardnessMax;
  },
  hrcHardnessMin(state) {
    return state.hrcHardnessMin;
  },
  hrcHardnessMax(state) {
    return state.hrcHardnessMax;
  },
  materialSelectorDetailsKey(state) {
    return state.materialSelectorDetailsKey;
  },
  materialsReady(state) {
    return state.materialsReady;
  },
  materialTasks(state) {
    return state.materialTasks;
  },

  // Dimensions

  dimensionsSelectorDialog(state) {
    return state.dimensionsSelectorDialog;
  },
  iBeamGroups(state) {
    return state.iBeamGroups;
  },
  iBeamStandards(state) {
    return state.iBeamStandards;
  },
  dimensionsReady(state) {
    return state.dimensionsReady;
  },
  dimensionTasks(state) {
    return state.dimensionTasks;
  },

  // Vising

  visingSelectorDialog(state) {
    return state.visingSelectorDialog;
  },
  visingReady(state) {
    return state.visingReady;
  },

  // Conditions

  conditionsSelectorDialog(state) {
    return state.conditionsSelectorDialog;
  },
  conditionsReady(state) {
    return state.conditionsReady;
  },
  defaultCantingDegrees(state) {
    return state.defaultCantingDegrees;
  },

  // Product Lines

  productLines(state) {
    return state.productLines;
  },
  productLinesReady(state) {
    return state.productLinesReady;
  },
  productLineTasks(state) {
    return state.productLineTasks;
  },

  sawCalcReady(state, getters) {
    return (
      getters.machinesReady &&
      getters.materialsReady &&
      getters.dimensionsReady &&
      getters.visingReady &&
      getters.conditionsReady &&
      getters.productLinesReady
    );
  },

  // SawCalc

  sawCalcLoading(state) {
    return state.sawCalcLoading;
  },
  settingsLoaded(state) {
    return state.settingsLoaded;
  },

  // Recommendation

  recommendations(state) {
    return state.recommendations;
  },
  recommendationUpdated(state) {
    return state.recommendationUpdated;
  },

  // Cost Calculations

  costCalculations(state) {
    return state.costCalculations;
  },
  currencySymbol(getters) {
    if (getters.input.currency == currency.dollar) {
      return "$";
    } else if (getters.input.currency == currency.euro) {
      return "€";
    } else if (getters.input.currency == currency.pound) {
      return "£";
    }
  },
  measurementUOM(getters) {
    return getters.input.metric ? "cm²" : "in²";
  },

  // SawCalc

  selectedRecommendation(state) {
    return state.selectedRecommendation;
  },
  selectedRecommendationForCustom(state) {
    return state.selectedRecommendationForCustom;
  },
  bandSpeedImperialIncrement(state) {
    return state.bandSpeedImperialIncrement;
  },
  bandSpeedMetricIncrement(state) {
    return state.bandSpeedMetricIncrement;
  },
  bandSpeedImperialMin(state) {
    return state.bandSpeedMin;
  },
  bandSpeedMetricMin(state) {
    return calculations.fpmToMpm(state.bandSpeedMin);
  },
  bandSpeedImperialMax(state) {
    return state.bandSpeedMax;
  },
  bandSpeedMetricMax(state) {
    return calculations.fpmToMpm(state.bandSpeedMax);
  },
  feedRateImperialIncrement(state) {
    return state.feedRateImperialIncrement;
  },
  feedRateMetricIncrement(state) {
    return state.feedRateMetricIncrement;
  },
  feedRateImperialMin(state) {
    return state.feedRateMin;
  },
  feedRateMetricMin(state) {
    return calculations.inToMM(state.feedRateMin);
  },
  feedRateImperialMax(state) {
    return state.feedRateMax;
  },
  feedRateMetricMax(state) {
    return calculations.inToMM(state.feedRateMax);
  },
  overrideMin(state) {
    return state.overrideMin;
  },
  overrideMax(state) {
    return state.overrideMax;
  },
  overrideStep(state) {
    return state.overrideStep;
  },
  recommendationKey(state) {
    return state.recommendationKey;
  },

  // Register

  registerDialog(state) {
    return state.registerDialog;
  },

  // Login

  loginDialog(state) {
    return state.loginDialog;
  },

  // Password Reset

  passwordResetDialog(state) {
    return state.passwordResetDialog;
  },

  // User

  user(state) {
    return state.user;
  },

  // Axios

  axiosSource(state) {
    return state.axiosSource;
  },

  // Authentication

  token(state) {
    return state.token;
  },

  // Users

  users(state) {
    return state.users;
  },
  usersKey(state) {
    return state.usersKey;
  },
  countries(state) {
    return state.countries;
  },
  defaultCountry(state) {
    return state.defaultCountry;
  },
  dataTableUserLinksKey(state) {
    return state.dataTableUserLinksKey;
  },

  // Stats

  stats(state) {
    return state.stats;
  },

  // UI

  sawCalcDrawer(state) {
    return state.sawCalcDrawer;
  },
  images(state) {
    return state.images;
  },
  homeCoverImageLoading(state) {
    return state.images.home.cover.loading;
  },
  dataTableSettingsDialog(state) {
    return state.dataTableSettingsDialog;
  },
  dataTableUOMSettingsDialog(state) {
    return state.dataTableUOMSettingsDialog;
  },
  dataTableSettingsKey(state) {
    return state.dataTableSettingsKey;
  },
  dataTableUOMSettingsKey(state) {
    return state.dataTableUOMSettingsKey;
  },

  // Admin

  // Machines

  machinesAdmin(state) {
    return state.machinesAdmin;
  },
  machinesAdminSettings(state) {
    return state.machinesAdminSettings;
  },
  machinesUser(state) {
    return state.machinesUser;
  },

  // Materials

  materialsAdmin(state) {
    return state.materialsAdmin;
  },
  materialsAdminSettings(state) {
    return state.materialsAdminSettings;
  },
  materialsLoading(state) {
    return state.materialsLoading;
  },
  materialDialogKey(state) {
    return state.materialDialogKey;
  },
  dataTableChemicalCompositionsDialog(state) {
    return state.dataTableChemicalCompositionsDialog;
  },
  chemicalCompositionsDialogKey(state) {
    return state.chemicalCompositionsDialogKey;
  },

  // ProductLines

  productLinesSettings(state) {
    return state.productLinesSettings;
  },
  productLineDialogKey(state) {
    return state.productLineDialogKey;
  },
  productLinesShort(state) {
    return state.productLinesShort;
  },
  bwlRefs(state) {
    return state.bwlRefs;
  },

  // ProductTypes

  productTypes(state) {
    return state.productTypes;
  },

  // Recommendations

  recommendationsAdmin(state) {
    return state.recommendationsAdmin;
  },
  recommendationsAdminSettings(state) {
    return state.recommendationsAdminSettings;
  },

  // CuttingCharts

  cuttingCharts(state) {
    return state.cuttingCharts;
  },
  cuttingChartsSettings(state) {
    return state.cuttingChartsSettings;
  },

  // Parameters

  parameters(state) {
    return state.parameters;
  },
  productSelections(state) {
    return state.productSelections;
  },
  productRecommendations(state) {
    return state.productRecommendations;
  },

  // User

  // Logo

  userLogoUrl(state) {
    return state.userLogoUrl;
  },
  userLogoImageMaxSize(state) {
    return state.userLogoImageMaxSize;
  },

  // Machines

  machinesUserSettings(state) {
    return state.machinesUserSettings;
  },
  machineImageMaxSize(state) {
    return state.machineImageMaxSize;
  },

  // Materials

  materialsUserSettings(state) {
    return state.materialsUserSettings;
  },
  materialsUser(state) {
    return state.materialsUser;
  },

  // UserRecommendations

  userRecommendations(state) {
    return state.userRecommendations;
  },
  recommendationsUserSettings(state) {
    return state.recommendationsUserSettings;
  },
};
