<template>
  <v-container fluid class="white black--text py-0">
    <v-row>
      <v-col cols="12" sm="6" class="py-0">
        <v-row align="center" justify="center" class="footer-text pb-2">
          <template v-for="(item, i) in footer.statusBar">
            <template v-if="!item.divider && !item.copywrite && !item.cookieSettings">
              <router-link
                :to="{ name: item.name, params: { url: item.url } }"
                :key="`${i}-footer-link`"
                class="footer-link"
                >{{ item.title }}</router-link
              >
            </template>

            <template v-if="item.divider">
              <span :key="`${i}-footer-link`" class="footer-link">|</span>
            </template>

            <template v-if="item.cookieSettings">
              <!-- <span :key="`${i}-footer-link`" class="footer-link">{{ item.title }}</span> -->
              <button :key="`${i}-footer-link`" id="ot-sdk-btn" class="ot-sdk-show-settings footer-link">{{ item.title }}</button>
            </template>
          </template>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" class="py-0">
        <v-row align="center" justify="center" class="footer-text pb-2">
          &copy;{{ new Date().getFullYear() }},
          <span v-html="footer.statusBar[copywriteLink].title" />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";
import { footer } from "@/constants/Footer";

export default {
  name: "FooterLinks",

  computed: {
    ...mapGetters(["footer"]),
    copywriteLink() {
      return footer.statusBar.Copywrite;
    }
  },

  methods: {
    navigationClick(link) {
      if (link.action == "link") {
        this.routeLink(link.name);
      } else if (link.action == "url") {
        this.openUrl(link.path);
      }
    },
    routeLink(name) {
      router.push({ name: name }).catch(() => {});
    },
    openUrl(url) {
      window.open(url);
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-link {
  color: map-get($theme, footer-link-color) !important;
  background-color: map-get($theme, background-color) !important;
  font-family: map-get($theme, font-family-secondary), sans-serif !important;
  font-weight: map-get($theme, font-weight-primary-navigation) !important;
  font-size: map-get($theme, font-size-footer-link) !important;
  text-decoration: none !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  padding: 0 !important;
  border: none !important;
  cursor: pointer;
}

.footer-text {
  color: map-get($theme, footer-link-color);
  font-family: map-get($theme, font-family-secondary), sans-serif;
  font-weight: map-get($theme, font-weight-secondary);
  font-size: map-get($theme, font-size-footer-text);
  margin-left: 4%;
  margin-top: 10px;
  line-height: 14px;
}

button:focus { 
  outline: none; 
}
</style>
