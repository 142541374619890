import idb from "@/services/indexedDB";
import axios from "axios";
import { config } from "@/config/ConfigSettings";
import { api } from "@/helpers/ApiCalls";
import { returnCode } from "@/constants/ReturnCode";
import { calculations } from "@/helpers/Calculations";
import { machineDetails } from "@/helpers/MachineDetails";
import { imageConversion } from "@/helpers/ImageConversion";
import { recommendations } from "@/constants/Recommendations";
export default {
  // eslint-disable-next-line no-unused-vars
  storeInit({ commit, dispatch, getters }) {
    dispatch("getSettings");
    dispatch("setDefaultLanguage").then(dispatch("updateControlsByLanguage"));

    dispatch("getLanguages", config.test).then(
      (response) => {
        if (response.code == returnCode.Success) {
          dispatch("getLanguageSettings");
        }
      },
      (error) => {
        // eslint-disable-next-line
        console.error(error);
      }
    );

    dispatch("getImages");
  },
  async getSettings({ commit, dispatch }) {
    try {
      const countries = await idb.get("settings", "countries");
      if (!countries || countries.length == 0) {
        dispatch("getCountries");
      } else {
        commit("SET_COUNTRIES", countries);
      }

      const user = await idb.get("settings", "user");
      dispatch("updateUser", { user: user, save: false });

      // Inputs

      
      const metric = await idb.get("settings", "metric");
      commit("CHANGE_INPUT_UOM", metric != null ? metric : 0);

      const currency = await idb.get("settings", "currency");
      commit("CHANGE_INPUT_CURRENCY", currency != null ? currency : 0);

      // Machines

      const manufacturers = await idb.get("settings", "manufacturers");
      commit("SET_MANUFACTURERS", manufacturers);

      const machines = await idb.get("settings", "machines");
      commit("SET_MACHINES", machines);

      const bladeWidthImperialList = await idb.get(
        "settings",
        "bladeWidthImperialList"
      );
      commit("SET_BLADE_WIDTH_IMPERIAL_LIST", bladeWidthImperialList);

      const bladeWidthMetricList = await idb.get(
        "settings",
        "bladeWidthMetricList"
      );
      commit("SET_BLADE_WIDTH_METRIC_LIST", bladeWidthMetricList);

      const bladeThicknessImperialList = await idb.get(
        "settings",
        "bladeThicknessImperialList"
      );
      commit("SET_BLADE_THICKNESS_IMPERIAL_LIST", bladeThicknessImperialList);

      const bladeThicknessMetricList = await idb.get(
        "settings",
        "bladeThicknessMetricList"
      );
      commit("SET_BLADE_THICKNESS_METRIC_LIST", bladeThicknessMetricList);

      const bladeLengthFtList = await idb.get("settings", "bladeLengthFtList");
      commit("SET_BLADE_LENGTH_FT_LIST", bladeLengthFtList);

      const bladeLengthInList = await idb.get("settings", "bladeLengthInList");
      commit("SET_BLADE_LENGTH_IN_LIST", bladeLengthInList);

      const bladeLengthFrList = await idb.get("settings", "bladeLengthFrList");
      commit("SET_BLADE_LENGTH_FR_LIST", bladeLengthFrList);

      const bladeLengthMetricList = await idb.get(
        "settings",
        "bladeLengthMetricList"
      );
      commit("SET_BLADE_LENGTH_METRIC_LIST", bladeLengthMetricList);

      const userMachines = await idb.get("settings", "userMachines");
      commit("SET_USER_MACHINES", userMachines);

      if (
        manufacturers != null &&
        manufacturers.length > 0 &&
        machines != null &&
        machines.length > 0 &&
        bladeWidthImperialList != null &&
        bladeWidthImperialList.length > 0 &&
        bladeWidthMetricList != null &&
        bladeWidthMetricList.length > 0 &&
        bladeThicknessImperialList != null &&
        bladeThicknessImperialList.length > 0 &&
        bladeThicknessMetricList != null &&
        bladeThicknessMetricList.length > 0 &&
        bladeLengthFtList != null &&
        bladeLengthFtList.length > 0 &&
        bladeLengthInList != null &&
        bladeLengthInList.length > 0 &&
        bladeLengthFrList != null &&
        bladeLengthFrList.length > 0 &&
        bladeLengthMetricList != null &&
        bladeLengthMetricList.length > 0
      ) {
        commit("SET_MACHINES_READY", true);
      } else {
        commit("SET_MACHINES_READY", false);
      }

      // Token

      const token = await idb.get("settings", "token");
      commit("SET_TOKEN", token != null ? token : "");

      // Materials

      const materialStandards = await idb.get("settings", "materialStandards");
      commit("SET_MATERIAL_STANDARDS", materialStandards);

      const unsCategories = await idb.get("settings", "unsCategories");
      commit("SET_UNS_CATEGORIES", unsCategories);

      const unsAlloyGroups = await idb.get("settings", "unsAlloyGroups");
      commit("SET_UNS_ALLOY_GROUPS", unsAlloyGroups);

      const sawCalcGroups = await idb.get("settings", "sawCalcGroups");
      commit("SET_SAW_CALC_GROUPS", sawCalcGroups);

      const unsGeneralDescriptions = await idb.get(
        "settings",
        "unsGeneralDescriptions"
      );
      commit("SET_UNS_GENERAL_DESCRIPTIONS", unsGeneralDescriptions);

      const subCategories = await idb.get("settings", "subCategories");
      commit("SET_SUB_CATEGORIES", subCategories);

      const materials = await idb.get("settings", "materials");
      commit("SET_MATERIALS", materials);

      const scales = await idb.get("settings", "scales");
      commit("SET_SCALES", scales);

      const userMaterials = await idb.get("settings", "userMaterials");
      commit("SET_USER_MATERIALS", userMaterials);

      if (
        materialStandards != null &&
        materialStandards.length > 0 &&
        unsCategories != null &&
        unsCategories.length > 0 &&
        unsAlloyGroups != null &&
        unsAlloyGroups.length > 0 &&
        sawCalcGroups != null &&
        sawCalcGroups.length > 0 &&
        unsGeneralDescriptions != null &&
        unsGeneralDescriptions.length > 0 &&
        subCategories != null &&
        subCategories.length > 0 &&
        materials != null &&
        materials.length > 0 &&
        scales != null &&
        scales.length > 0
      ) {
        commit("SET_MATERIALS_READY", true);
      } else {
        commit("SET_MATERIALS_READY", false);
      }

      // Dimensions

      const iBeamGroups = await idb.get("settings", "iBeamGroups");
      commit("SET_IBEAM_GROUPS", iBeamGroups);

      const iBeamStandards = await idb.get("settings", "iBeamStandards");
      commit("SET_IBEAM_STANDARDS", iBeamStandards);

      if (
        iBeamGroups != null &&
        iBeamGroups.length > 0 &&
        iBeamStandards != null &&
        iBeamStandards.length > 0
      ) {
        commit("SET_DIMENSIONS_READY", true);
      } else {
        commit("SET_DIMENSIONS_READY", false);
      }

      commit("SET_VISING_READY", true);

      commit("SET_CONDITIONS_READY", true);

      // Product Lines, BWL Refs

      const productLinesShort = await idb.get("settings", "productLinesShort");
      commit("SET_PRODUCT_LINES_SHORT", productLinesShort);

      const bwlRefs = await idb.get("settings", "bwlRefs");
      commit("SET_BWL_REFS", bwlRefs);

      if (
        productLinesShort != null &&
        productLinesShort.length > 0 &&
        bwlRefs != null &&
        bwlRefs.length > 0
      ) {
        commit("SET_PRODUCT_LINES_READY", true);
      } else {
        commit("SET_PRODUCT_LINES_READY", false);
      }

      // Settings
      commit("SET_SETTINGS_LOADED", true);

      // MachinesAdminSettings
      const machinesAdminSettings = await idb.get(
        "settings",
        "machinesAdminSettings"
      );
      commit("SET_MACHINES_ADMIN_SETTINGS", machinesAdminSettings);

      // MachinesUserSettings
      const machinesUserSettings = await idb.get(
        "settings",
        "machinesUserSettings"
      );
      commit("SET_MACHINES_USER_SETTINGS", machinesUserSettings);

      // MaterialsAdminSettings
      const materialsAdminSettings = await idb.get(
        "settings",
        "materialsAdminSettings"
      );
      commit("SET_MATERIALS_ADMIN_SETTINGS", materialsAdminSettings);

      // MaterialsUserSettings
      const materialsUserSettings = await idb.get(
        "settings",
        "materialsUserSettings"
      );
      commit("SET_MATERIALS_USER_SETTINGS", materialsUserSettings);

      // RecommendationsUserSettings
      const recommendationsUserSettings = await idb.get(
        "settings",
        "recommendationsUserSettings"
      );
      commit("SET_RECOMMENDATIONS_USER_SETTINGS", recommendationsUserSettings);

      // ProductLinesSettings
      const productLinesSettings = await idb.get(
        "settings",
        "productLinesSettings"
      );
      commit("SET_PRODUCT_LINES_SETTINGS", productLinesSettings);

      // RecommendationsAdminSettings
      const recommendationsAdminSettings = await idb.get(
        "settings",
        "recommendationsAdminSettings"
      );
      commit(
        "SET_RECOMMENDATIONS_ADMIN_SETTINGS",
        recommendationsAdminSettings
      );

      // CuttingChartsSettings
      const cuttingChartsSettings = await idb.get(
        "settings",
        "cuttingChartsSettings"
      );
      commit("SET_CUTTING_CHARTS_SETTINGS", cuttingChartsSettings);
    } catch (error) {
      // eslint-disable-next-line
      console.error("Error getting settings: " + error);
    }
  },
  async getLanguageSettings({ commit, dispatch, getters }) {
    try {
      const language = await idb.get("settings", "language");

      if (language) {
        commit("SET_LANG", language);

        dispatch("changeLanguage", {
          language: language,
          reload: true,
        }).then(
          () => {
            dispatch("updateControlsByLanguage");
          },
          (error) => {
            // eslint-disable-next-line
            console.error(error);
          }
        );
      } else {
        dispatch("changeLanguage", {
          language: getters.language,
          reload: true,
        }).then(
          () => {
            dispatch("updateControlsByLanguage");
          },
          (error) => {
            // eslint-disable-next-line
            console.error(error);
          }
        );
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error("Error getting settings: " + error);
    }
  },
  async saveSettings({ commit }, payload) {
    const key = payload.key;
    const value = payload.value;

    try {
      await idb.save("settings", {
        key: key,
        value: value,
      });

      if (key === "language") {
        commit("SET_LANG", value);
        commit("UPDATE_NAVIGATION_BY_LANG", value);
        commit("UPDATE_FOOTER_BY_LANG", value);
      } else if (key === "metric") {
        commit("CHANGE_INPUT_UOM", value);
      } else if (key === "currency") {
        commit("CHANGE_INPUT_CURRENCY", value);
      } else if (key === "token") {
        commit("SET_TOKEN", value);
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error("Error saving language: " + error);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async syncRequired({ commit, getters }, payload) {
    let required = true;

    if (
      !getters.machinesReady ||
      !getters.materialsReady ||
      !getters.dimensionsReady ||
      !getters.visingReady ||
      !getters.conditionsReady ||
      !getters.productLinesReady
    ) {
      return required;
    }

    const dbSyncSeconds = payload;
    let dbSyncDate = await idb.get("settings", "dbSyncDate");

    if (dbSyncDate) {
      dbSyncDate = new Date(parseInt(dbSyncDate, 10));
      const timeFromLastSync = new Date().getTime() - dbSyncDate.getTime();

      if (parseInt(timeFromLastSync / 1000) <= dbSyncSeconds) {
        required = false;
      }
    }

    // eslint-disable-next-line no-unused-vars
    return new Promise(function(resolve, reject) {
      resolve(required);
    });
  },

  // Languages

  getLanguages({ commit, dispatch, getters }, showAll) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetLanguages/" + showAll;

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_LANGUAGES", {
                languages: res.LanguagesList,
              });

              resolve({ code: res.Code, message: res.Description });
            } else {
              dispatch("getTranslation", "errorGettingLanguages").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          commit("SET_LANGUAGES", null);

          dispatch("getTranslation", "errorGettingLanguages").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting languages: " + error.response.data
                );

                const notification = {
                  show: true,
                  result: false,
                  message: response.message,
                };

                dispatch("showSnackbar", notification);

                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting languages: " + error);

                const notification = {
                  show: true,
                  result: false,
                  message: error,
                };

                dispatch("showSnackbar", notification);

                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  setDefaultLanguage({ commit, getters }) {
    commit("SET_DEFAULT_LANGUAGE", getters.language);
  },
  changeLanguage({ commit, dispatch, getters }, payload) {
    const language = payload.language;
    const reload = payload.reload;

    return new Promise((resolve, reject) => {
      if (getters.languages.find((item) => item.locale === language.locale)) {
        if (!language.messages || reload) {
          const url = config.url() + "GetLanguage/" + language.locale;

          axios
            .create()
            .get(url, {
              headers: api.headers(getters),
            })
            .then((response) => {
              const error = api.responseError(response);

              if (error) {
                if (api.authenticationError(error.Code)) {
                  dispatch("logoff");
                }

                reject({ code: error.Code, message: error.Description });
              } else {
                const res = response.data;

                if (res.Code == returnCode.Success) {
                  language.messages = JSON.parse(res.Json);

                  commit("CHANGE_INPUT_UOM", language.uom == 1 ? true : false);

                  dispatch("saveSettings", {
                    key: "language",
                    value: language,
                  });

                  resolve(res.Description);
                } else {
                  reject({ code: res.Code, message: res.Description });
                }
              }
            })
            .catch((error) => {
              dispatch("getTranslation", "errorGettingLanguage").then(
                (response) => {
                  if (error.response) {
                    // eslint-disable-next-line
                    console.error(
                      "Error getting language: " + error.response.data
                    );
                    reject({ code: error.response.status, message: response });
                  } else {
                    // eslint-disable-next-line
                    console.error("Error getting language: " + error);
                    reject({
                      code: returnCode.ErrNetworkError,
                      message: error,
                    });
                  }
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            });
        } else {
          dispatch("saveSettings", { key: "language", value: language });
          resolve();
        }
      }
      else {
        resolve();
      }
    });
  },
  // eslint-disable-next-line no-unused-vars
  getTranslation({ commit, getters }, text) {
    return new Promise((resolve, reject) => {
      const json = getters.language.messages;
      const translation = json["response"][text];

      if (translation) {
        resolve(translation);
      } else {
        // eslint-disable-next-line
        //console.log("getTranslation", text);
        reject("No translation found");
      }
    });
  },

  // UI Updates

  updateControlsByLanguage({ commit, getters }) {
    commit("UPDATE_NAVIGATION_BY_LANG", getters.language);
    commit("UPDATE_FOOTER_BY_LANG", getters.language);
  },

  // Recommendation
  // Inputs

  resetInputs({ commit, dispatch, getters }) {
    commit("RESET_INPUTS");

    dispatch("setInputMachine", null);
    dispatch("setInputMaterial", null);
    dispatch("setInputDimensions", null);
    dispatch("setInputVising", null);
    dispatch("setInputConditions", null);
    dispatch("setToken", null);

    if (getters.selectedRecommendationForCustom != null) {
      dispatch("closeCustomRecommendation");
    }
  },
  updateRecommendations({ dispatch, getters }) {
    let valid = false;

    if (
      getters.inputMachine.manufacturerName != null &&
      getters.inputMaterial.designation != null &&
      getters.inputDimensions.shape != null &&
      getters.inputVising.arrangement != null &&
      getters.inputConditions.lubrication != null
    ) {
      valid = true;
    } else {
      dispatch("resetOutputs");
    }

    if (valid) {
      dispatch("getRecommendations");
    }
  },

  // Cost Calculations

  updateCostCalculation({ commit, getters }, payload) {
    const cutTime = payload.cutTime;
    const cuttingRate = payload.cuttingRate;
    const costCalculation = payload.costCalculation;
    const index = payload.recommendationIndex;
    return new Promise((resolve, reject) => {
      if (costCalculation == undefined) {
        reject({ status: false });
        return;
      }

      if (cutTime == undefined || cutTime == 0) {
        costCalculation.costsPerCut = null;
        costCalculation.costsPerMeasurement = null;

        commit("UPDATE_COST_CALCULATION", {
          costCalculation: costCalculation,
          index: index,
        });

        resolve({ status: true, costCalculation: costCalculation });
        return;
      }

      const pricePerBlade = costCalculation.pricePerBlade
        ? parseFloat(costCalculation.pricePerBlade)
        : 0;
      const laborBurdenRate = costCalculation.laborBurdenRate
        ? parseFloat(costCalculation.laborBurdenRate)
        : 0;

      if (costCalculation.bladeLife == 0) {
        costCalculation.costsPerCut = null;
        costCalculation.costsPerMeasurement = null;

        const cutsPerBlade = costCalculation.cutsPerBlade
          ? parseInt(costCalculation.cutsPerBlade)
          : 0;

        let valid = false;
        if (
          pricePerBlade != undefined &&
          pricePerBlade > 0 &&
          cutsPerBlade != undefined &&
          cutsPerBlade > 0 &&
          laborBurdenRate != undefined &&
          laborBurdenRate > 0
        ) {
          valid = true;
        }
        // console.log("valid", valid);

        if (valid) {
          const toolCostPerCut = pricePerBlade / cutsPerBlade;

          const cutsPerHour = (60 * 60) / cutTime;

          const hoursPerLife = cutsPerBlade / cutsPerHour;

          const costPerCut = (laborBurdenRate * hoursPerLife) / cutsPerBlade;

          const totalCostPerCut = toolCostPerCut + costPerCut;
          costCalculation.costsPerCut = totalCostPerCut.toFixed(2);

          costCalculation.costsPerMeasurement = (
            costCalculation.costsPerCut /
            ((cutTime / 60) * cuttingRate)
          ).toFixed(2);
        }

        commit("UPDATE_COST_CALCULATION", {
          costCalculation: costCalculation,
          index: index,
        });

        resolve({ status: true, costCalculation: costCalculation });
      } else {
        costCalculation.costsPerMeasurement = null;
        costCalculation.costsPerCut = null;

        const inputDimensions = getters.inputDimensions;

        let valid = false;
        if (
          inputDimensions != undefined &&
          inputDimensions != null &&
          inputDimensions.measurement != undefined &&
          inputDimensions.measurement != null &&
          pricePerBlade != undefined &&
          pricePerBlade > 0 &&
          costCalculation.measurement != undefined &&
          costCalculation.measurement != null &&
          laborBurdenRate != undefined &&
          laborBurdenRate > 0
        ) {
          valid = true;
        }
        // console.log("inputDimensions", inputDimensions);

        if (valid) {
          let inputDimensionsMeasurement =
            getters.inputUOM == 0
              ? inputDimensions.measurement.measurementImperial
              : inputDimensions.measurement.measurementMetric;
          inputDimensionsMeasurement = inputDimensionsMeasurement
            ? parseFloat(inputDimensionsMeasurement)
            : 0;
          // console.log("inputDimensionsMeasurement", inputDimensionsMeasurement);

          let costCalculationMeasurement =
            getters.inputUOM == 0
              ? costCalculation.measurement.measurementImperial
              : costCalculation.measurement.measurementMetric;
          costCalculationMeasurement = costCalculationMeasurement
            ? parseFloat(costCalculationMeasurement)
            : 0;
          // console.log("costCalculationMeasurement", costCalculationMeasurement);

          if (
            costCalculationMeasurement != 0 &&
            inputDimensionsMeasurement != 0
          ) {
            const totalCuts =
              costCalculationMeasurement / inputDimensionsMeasurement;

            const totalCutTime = totalCuts * cutTime;
            const totalCutTimeHours = totalCutTime / (60 * 60);

            const costLabor = totalCutTimeHours * laborBurdenRate;

            const machineCostPerMeasurement =
              costLabor / costCalculationMeasurement;

            const toolCostPerMeasurement =
              pricePerBlade / costCalculationMeasurement;

            const totalCostPerMeasurement =
              toolCostPerMeasurement + machineCostPerMeasurement;

            const costsPerCut =
              totalCostPerMeasurement * inputDimensionsMeasurement;

            costCalculation.costsPerMeasurement = totalCostPerMeasurement.toFixed(
              2
            );

            costCalculation.costsPerCut = costsPerCut.toFixed(2);
          }
        }

        commit("UPDATE_COST_CALCULATION", {
          costCalculation: costCalculation,
          index: index,
        });

        resolve({ status: true, costCalculation: costCalculation });
      }
    });
  },

  // UOM

  async changeInputUOM({ dispatch }, metric) {
    await dispatch("saveSettings", { key: "metric", value: metric });
    dispatch("reloadMachineComponent", metric);
    dispatch("updateCostCalculations");
  },
  changeInputCurrency({ dispatch }, currency) {
    dispatch("saveSettings", { key: "currency", value: currency });
  },

  // Machines

  showMachineSelectorDialog({ commit }, show) {
    commit("SHOW_MACHINE_SELECTOR_DIALOG", show);
  },
  setInputMachine({ commit, dispatch }, payload) {
    commit("SET_INPUT_MACHINE", payload);
    dispatch("updateRecommendations");
  },
  setSavedInputMachine({ commit }, payload) {
    commit("SET_INPUT_MACHINE", payload);
    commit("SET_SAVED_INPUT_MACHINE", payload);
  },
  reloadMachineComponent({ commit }, metric) {
    if (!metric) {
      commit("RELOAD_MACHINE_IMPERIAL_COMPONENT");
    } else {
      commit("RELOAD_MACHINE_METRIC_COMPONENT");
    }
  },
  setMachinesReady({ commit }, payload) {
    commit("SET_MACHINES_READY", payload);
  },
  // eslint-disable-next-line no-unused-vars
  getMachines({ commit, dispatch, getters }) {

    const tasks = [...getters.machineTasks];
    if (getters.user) {
      tasks.unshift({
        description: "User Machines",
        function: "getUserMachines",
        payload: { showNotification: true },
      });
    }

    dispatch("getMachinesData", { tasks: tasks }).then(
      (response) => {
        const notification = {
          show: true,
          result: true,
          message: response.message,
        };
        dispatch("showSnackbar", notification);

        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
          resolve({ status: true, response: response });
        });
      },
      (error) => {
        if (api.authenticationError(error.Code)) {
          dispatch("logoff");
        }

        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
          dispatch("setSawCalcLoading", false);

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          dispatch("showSnackbar", notification);

          resolve({ status: false, error: error });
        });
      }
    );
  },

  // Materials

  showMaterialSelectorDialog({ commit }, show) {
    commit("SHOW_MATERIAL_SELECTOR_DIALOG", show);
  },
  setInputMaterial({ commit, dispatch }, payload) {
    commit("SET_INPUT_MATERIAL", payload);
    dispatch("updateRecommendations");
  },
  setSavedInputMaterial({ commit }, payload) {
    commit("SET_INPUT_MATERIAL", payload);
    commit("SET_SAVED_INPUT_MATERIAL", payload);
  },
  reloadMaterialSelectorDetailsComponent({ commit }) {
    commit("RELOAD_MATERIAL_SELECTOR_DETAILS_COMPONENT");
  },
  setMaterialsReady({ commit }, payload) {
    commit("SET_MATERIALS_READY", payload);
  },

  // Dimensions

  showDimensionsSelectorDialog({ commit }, show) {
    commit("SHOW_DIMENSIONS_SELECTOR_DIALOG", show);
  },
  setInputDimensions({ commit, dispatch }, payload) {
    commit("SET_INPUT_DIMENSIONS", payload);

    if (payload == null) {
      dispatch("setInputVising", null);
    }

    dispatch("updateRecommendations");
  },
  setDimensionsReady({ commit }, payload) {
    commit("SET_DIMENSIONS_READY", payload);
  },

  // Vising

  showVisingSelectorDialog({ commit }, show) {
    commit("SHOW_VISING_SELECTOR_DIALOG", show);
  },
  setInputVising({ commit, dispatch }, payload) {
    commit("SET_INPUT_VISING", payload);
    dispatch("updateRecommendations");
  },
  setVisingReady({ commit }, payload) {
    commit("SET_VISING_READY", payload);
  },

  // Conditions

  showConditionsSelectorDialog({ commit }, show) {
    commit("SHOW_CONDITIONS_SELECTOR_DIALOG", show);
  },
  setInputConditions({ commit, dispatch }, payload) {
    commit("SET_INPUT_CONDITIONS", payload);
    dispatch("updateRecommendations");
  },
  setConditionsReady({ commit }, payload) {
    commit("SET_CONDITIONS_READY", payload);
  },

  // Product Lines

  setProductLinesReady({ commit }, payload) {
    commit("SET_PRODUCT_LINES_READY", payload);
  },

  // SawCalc

  // Machines

  async getMachinesData({ commit, dispatch }, payload) {
    commit("SET_MACHINES_READY", false);
    dispatch("setSawCalcLoading", true);

    const tasks = [...payload.tasks];

    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];

      let promise;
      try {
        promise = await dispatch(task.function, task.payload);

        if (
          promise != undefined &&
          promise.code != returnCode.Success &&
          promise.code != returnCode.ErrRecordNotFound
        ) {
          let p = new Promise(function(resolve, reject) {
            let message;
            dispatch("getTranslation", "errorNotLoaded").then(
              (response) => {
                message = task.description + " " + response;

                const notification = {
                  show: true,
                  result: false,
                  message: message,
                };

                dispatch("showSnackbar", notification);
                dispatch("setSawCalcLoading", false);
                reject({ code: promise.Code, message: promise.Description });
              },
              (error) => {
                // eslint-disable-next-line
                console.error(error);
              }
            );
          });

          return p;
        }
      } catch (error) {
        let p = new Promise(function(resolve, reject) {
          let message;
          dispatch("getTranslation", "errorNotLoaded").then(
            (response) => {
              message = task.description + " " + response;

              const notification = {
                show: true,
                result: false,
                message: message,
              };

              dispatch("showSnackbar", notification);

              reject(error);
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });

        return p;
      }
    }

    commit("SET_MACHINES_READY", true);

    let p = new Promise(function(resolve, reject) {
      dispatch("getTranslation", "machinesUpdated").then(
        (response) => {
          resolve({ message: response });
        },
        (error) => {
          reject(error);
        }
      );
    });

    return p;
  },
  getMachineManufacturers({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetMachineManufacturers/_/_/_/_/1/1";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_MACHINE_MANUFACTURERS", res.MachineManufacturerList);

              dispatch("saveSettings", {
                key: "manufacturers",
                value: getters.manufacturers,
              });
              dispatch("saveSettings", {
                key: "machines",
                value: getters.machines,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_MACHINE_MANUFACTURERS", null);

              dispatch("saveSettings", {
                key: "manufacturers",
                value: null,
              });
              dispatch("saveSettings", {
                key: "machines",
                value: null,
              });

              let message;
              dispatch("getTranslation", "machinesNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_MACHINE_MANUFACTURERS", null);

          dispatch("saveSettings", {
            key: "manufacturers",
            value: null,
          });
          dispatch("saveSettings", {
            key: "machines",
            value: null,
          });

          dispatch("getTranslation", "errorGettingMachines").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error getting machines: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting machines: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  getMachine({ commit, dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      const machineID = payload.machineID;

      const url = config.url() + "GetMachine/" + machineID;

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.Machine;

              dispatch("getMachineFromResponse", item).then((response) => {
                response.machineID = item.MachineID;
                dispatch("setMachine", response);

                resolve({ message: res.Description, machine: response });
              });
            } else {
              dispatch("getTranslation", "errorGettingMachine").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("setMachine", null);

          dispatch("getTranslation", "errorGettingMachine").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error getting machine: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting machine: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  setMachine({ commit }, machine) {
    commit("SET_MACHINE", machine);
  },
  getUserMachines({ commit, dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const showNotification = payload.showNotification;
    let userID = payload.userID;

    if (!userID) {
      userID = getters.user.userID;
    }

    return new Promise((resolve, reject) => {
      const url = config.url() + "GetUserMachines/_/" + userID + "/_/_/_/1/1";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_USER_MACHINES", res.UserMachinesList);
              dispatch("saveSettings", {
                key: "userMachines",
                value: getters.userMachines,
              });

              if (showNotification) {
                resolve({ code: res.Code, message: res.Description });
              } else {
                resolve({ code: res.Code, message: "" });
              }
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_USER_MACHINES", null);

              dispatch("saveSettings", {
                key: "userMachines",
                value: null,
              });

              let message;
              dispatch("getTranslation", "machinesNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_USER_MACHINES", null);

          dispatch("saveSettings", {
            key: "userMachines",
            value: null,
          });

          dispatch("getTranslation", "errorGettingMachines").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error getting machines: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting machines: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getUserMachine({ commit, dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    return new Promise((resolve, reject) => {
      const userMachineID = payload.userMachineID;

      const url = config.url() + "GetUserMachine/" + userMachineID;

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.UserMachine;

              dispatch("getMachineFromResponse", item).then((response) => {
                response.userMachineID = item.UserMachineID;
                response.userID = item.UserID;
                response.machineName = item.MachineName;
                response.imageID = item.ImageID;
                response.defined = true;

                commit("SET_USER_MACHINE", response);

                resolve({ message: res.Description, machine: response });
              });
            } else {
              dispatch("getTranslation", "errorGettingMachine").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          commit("SET_USER_MACHINE", null);

          dispatch("getTranslation", "errorGettingMachine").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error getting machine: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting machine: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  addUserMachine({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const machineID = payload.machineID;

    const url = config.url() + "AddUserMachine";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            UserID: getters.user.userID,
            MachineID: machineID,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.UserMachine;

              dispatch("getMachineFromResponse", item).then((response) => {
                response.machineID = item.MachineID;
                dispatch("setMachine", response);

                dispatch("getUserMachines", { showNotification: false });

                resolve({ message: res.Description, machine: response });
              });
            } else if (res.Code == returnCode.ErrRecordExists) {
              dispatch("getTranslation", "machineExists").then(
                (response) => {
                  reject({ code: res.Code, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              dispatch("getTranslation", "errorGettingMachine").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("setMachine", null);

          dispatch("getTranslation", "errorCreatingMachine").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error creating user machine: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error creating user machine: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  removeUserMachine({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const userID = getters.user.userID;
    const userMachineID = payload.userMachineID;

    if (!userID || !userMachineID) {
      return;
    }

    const url =
      config.url() + "RemoveUserMachine/" + userID + "/" + userMachineID;

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              dispatch("getUserMachines", { showNotification: false });
              resolve({ message: res.Description });
            } else {
              dispatch("getTranslation", "errorGettingMachine").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorRemovingMachine").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error removing user machine: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error removing user machine: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  getMachineFromResponse({ commit, getters }, item) {
    if (!item) {
      return;
    }

    return new Promise((resolve) => {
      const machine = {
        manufacturerID: item.ManufacturerID,
        manufacturerName: item.ManufacturerName,
        model: item.Model,
        bladeWidthIn: item.BladeWidthIn,
        bladeWidthFr: item.BladeWidthFr,
        bladeWidthMetric: item.BladeWidthMetric,
        bladeThicknessImperial: item.BladeThicknessImperial,
        bladeThicknessMetric: item.BladeThicknessMetric,
        bladeLengthFt: item.BladeLengthFt,
        bladeLengthIn: item.BladeLengthIn,
        bladeLengthFr: item.BladeLengthFr,
        bladeLengthImperial: item.BladeLengthImperial,
        bladeLengthMetric: item.BladeLengthMetric,
        hortizontal: item.Hortizontal,
        scissorPivot: item.ScissorPivot,
        cantedHead: item.CantedHead,
        bandSpeedMinImperial: item.BandSpeedMinImperial,
        bandSpeedMaxImperial: item.BandSpeedMaxImperial,
        bandSpeedMinMetric: item.BandSpeedMinMetric,
        bandSpeedMaxMetric: item.BandSpeedMaxMetric,
      };

      machine.bladeWidthImperialDesc = machineDetails.getBladeWidth(
        machine,
        false
      );
      machine.bladeWidthMetricDesc = machineDetails.getBladeWidth(
        machine,
        true
      );
      machine.bladeThicknessImperialDesc = machineDetails.getBladeThickness(
        machine,
        false
      );
      machine.bladeThicknessMetricDesc = machineDetails.getBladeThickness(
        machine,
        true
      );
      machine.bladeLengthImperialDesc = machineDetails.getBladeLength(
        machine,
        false
      );
      machine.bladeLengthMetricDesc = machineDetails.getBladeLength(
        machine,
        true
      );
      machine.machineOrientationDesc = machineDetails.getMachineOrientation(
        machine,
        getters.language.messages
      );
      machine.scissorPivotDesc = machineDetails.getScissorPivot(
        machine,
        getters.language.messages
      );
      machine.cantedHeadDesc = machineDetails.getCantedHead(
        machine,
        getters.language.messages
      );
      machine.bandSpeedMinImperialDesc = machineDetails.getBandSpeed(
        machine,
        false,
        true
      );
      machine.bandSpeedMaxImperialDesc = machineDetails.getBandSpeed(
        machine,
        false,
        false
      );
      machine.bandSpeedMinMetricDesc = machineDetails.getBandSpeed(
        machine,
        true,
        true
      );
      machine.bandSpeedMaxMetricDesc = machineDetails.getBandSpeed(
        machine,
        true,
        false
      );

      resolve(machine);
    });
  },
  getBladeWidthImperialList({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetWidthImperialList";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_BLADE_WIDTH_IMPERIAL_LIST", res.List);

              dispatch("saveSettings", {
                key: "bladeWidthImperialList",
                value: getters.bladeWidthImperialList,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_BLADE_WIDTH_IMPERIAL_LIST", null);

              dispatch("saveSettings", {
                key: "bladeWidthImperialList",
                value: null,
              });

              let message;
              dispatch("getTranslation", "bladeWidthImperialListNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_BLADE_WIDTH_IMPERIAL_LIST", null);

          dispatch("saveSettings", {
            key: "bladeWidthImperialList",
            value: null,
          });

          dispatch("getTranslation", "errorGettingWidthImperialList").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting width imperial list: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting width imperial list: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getBladeWidthMetricList({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetWidthMetricList";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_BLADE_WIDTH_METRIC_LIST", res.List);

              dispatch("saveSettings", {
                key: "bladeWidthMetricList",
                value: getters.bladeWidthMetricList,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_BLADE_WIDTH_METRIC_LIST", null);

              dispatch("saveSettings", {
                key: "bladeWidthMetricList",
                value: null,
              });

              let message;
              dispatch("getTranslation", "bladeWidthMetricListNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_BLADE_WIDTH_METRIC_LIST", null);

          dispatch("saveSettings", {
            key: "bladeWidthMetricList",
            value: null,
          });

          dispatch("getTranslation", "errorGettingWidthMetricList").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting width metric list: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting width metric list: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getBladeThicknessImperialList({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetThicknessImperialList";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_BLADE_THICKNESS_IMPERIAL_LIST", res.List);

              dispatch("saveSettings", {
                key: "bladeThicknessImperialList",
                value: getters.bladeThicknessImperialList,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_BLADE_THICKNESS_IMPERIAL_LIST", null);

              dispatch("saveSettings", {
                key: "bladeThicknessImperialList",
                value: null,
              });

              let message;
              dispatch(
                "getTranslation",
                "bladeThicknessImperialListNotFound"
              ).then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_BLADE_THICKNESS_IMPERIAL_LIST", null);

          dispatch("saveSettings", {
            key: "bladeThicknessImperialList",
            value: null,
          });

          dispatch("getTranslation", "errorGettingThicknessImperialList").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting thickness imperial list: " +
                    error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error(
                  "Error getting thickness imperial list: " + error
                );
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getBladeThicknessMetricList({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetThicknessMetricList";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_BLADE_THICKNESS_METRIC_LIST", res.List);

              dispatch("saveSettings", {
                key: "bladeThicknessMetricList",
                value: getters.bladeThicknessMetricList,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_BLADE_THICKNESS_METRIC_LIST", null);

              dispatch("saveSettings", {
                key: "bladeThicknessMetricList",
                value: null,
              });

              let message;
              dispatch(
                "getTranslation",
                "bladeThicknessMetricListNotFound"
              ).then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_BLADE_THICKNESS_METRIC_LIST", null);

          dispatch("saveSettings", {
            key: "bladeThicknessMetricList",
            value: null,
          });

          dispatch("getTranslation", "errorGettingThicknessMetricList").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting thickness metric list: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting thickness metric list: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getBladeLengthFtList({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetLengthFtList";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_BLADE_LENGTH_FT_LIST", res.List);

              dispatch("saveSettings", {
                key: "bladeLengthFtList",
                value: getters.bladeLengthFtList,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_BLADE_LENGTH_FT_LIST", null);

              dispatch("saveSettings", {
                key: "bladeLengthFtList",
                value: null,
              });

              let message;
              dispatch("getTranslation", "bladeLengthFtListNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_BLADE_LENGTH_FT_LIST", null);

          dispatch("saveSettings", {
            key: "bladeLengthFtList",
            value: null,
          });

          dispatch("getTranslation", "errorGettingLengthFtList").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting length, ft list: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting length, ft list: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getBladeLengthInList({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetLengthInList";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_BLADE_LENGTH_IN_LIST", res.List);

              dispatch("saveSettings", {
                key: "bladeLengthInList",
                value: getters.bladeLengthInList,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_BLADE_LENGTH_IN_LIST", null);

              dispatch("saveSettings", {
                key: "bladeLengthInList",
                value: null,
              });

              let message;
              dispatch("getTranslation", "bladeLengthInListNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_BLADE_LENGTH_IN_LIST", null);

          dispatch("saveSettings", {
            key: "bladeLengthInList",
            value: null,
          });

          dispatch("getTranslation", "errorGettingLengthInList").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting length, in list: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting length, in list: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getBladeLengthFrList({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetLengthFrList";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_BLADE_LENGTH_FR_LIST", res.List);

              dispatch("saveSettings", {
                key: "bladeLengthFrList",
                value: getters.bladeLengthFrList,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_BLADE_LENGTH_FR_LIST", null);

              dispatch("saveSettings", {
                key: "bladeLengthFrList",
                value: null,
              });

              let message;
              dispatch("getTranslation", "bladeLengthFrListNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_BLADE_LENGTH_FR_LIST", null);

          dispatch("saveSettings", {
            key: "bladeLengthFrList",
            value: null,
          });

          dispatch("getTranslation", "errorGettingLengthFrList").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting length, fr list: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting length, fr list: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getBladeLengthMetricList({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetLengthMetricList";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_BLADE_LENGTH_METRIC_LIST", res.List);

              dispatch("saveSettings", {
                key: "bladeLengthMetricList",
                value: getters.bladeLengthMetricList,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_BLADE_LENGTH_METRIC_LIST", null);

              dispatch("saveSettings", {
                key: "bladeLengthMetricList",
                value: null,
              });

              let message;
              dispatch("getTranslation", "bladeLengthMetricListNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_BLADE_LENGTH_METRIC_LIST", null);

          dispatch("saveSettings", {
            key: "bladeLengthMetricList",
            value: null,
          });

          dispatch("getTranslation", "errorGettingLengthMetricList").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting length, metric list: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting length, metric list: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  addUnknownMachine({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const machine = payload.machine;

    if (!machine) {
      return;
    }

    const metric = payload.uom == 0 ? false : true;
    const bladeWidth = calculations.getBladeWidth(
      machine,
      metric,
      getters.bladeWidthImperialList,
      getters.bladeWidthMetricList
    );
    const bladeThickness = calculations.getBladeThickness(
      machine,
      metric,
      getters.bladeThicknessImperialList,
      getters.bladeThicknessMetricList
    );
    const bladeLength = calculations.getBladeLength(machine, metric);
    const bandSpeedMin = calculations.getBandSpeed(machine, true, metric);
    const bandSpeedMax = calculations.getBandSpeed(machine, false, metric);

    const item = {
      UserID: getters.user.userID,
      ManufacturerName: machine.manufacturerName,
      Model: machine.model,
      BladeWidthIn: bladeWidth.in,
      BladeWidthFr: bladeWidth.fr,
      BladeWidthMetric: bladeWidth.mm,
      BladeThicknessImperial: bladeThickness.in,
      BladeThicknessMetric: bladeThickness.mm,
      BladeLengthFt: bladeLength.ft,
      BladeLengthIn: bladeLength.in,
      BladeLengthFr: bladeLength.fr,
      BladeLengthImperial: bladeLength.imperial,
      BladeLengthMetric: bladeLength.mm,
      Hortizontal: machine.machineOrientation,
      ScissorPivot: machine.scissorPivot,
      CantedHead: machine.cantedHead,
      BandSpeedMinImperial: bandSpeedMin.fpm,
      BandSpeedMaxImperial: bandSpeedMax.fpm,
      BandSpeedMinMetric: bandSpeedMin.mpm,
      BandSpeedMaxMetric: bandSpeedMax.mpm,
    };

    const url = config.url() + "AddUserMachine";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(url, item, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              dispatch("getUserMachines", { showNotification: false });
              resolve({ message: res.Description, machine: response });
            } else if (res.Code == returnCode.ErrRecordExists) {
              dispatch("getTranslation", "machineExists").then(
                (response) => {
                  reject({ code: res.Code, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              dispatch("getTranslation", "errorGettingMachine").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorGettingMachines").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting user machines: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting user machines: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },

  // Material

  async getMatrialsData({ commit, dispatch }, payload) {
    commit("SET_MATERIALS_READY", false);
    dispatch("setSawCalcLoading", true);

    const tasks = [...payload.tasks];

    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];

      let promise;
      try {
        promise = await dispatch(task.function, task.payload);

        if (
          promise.code != returnCode.Success &&
          promise.code != returnCode.ErrRecordNotFound
        ) {
          let p = new Promise(function(resolve, reject) {
            let message;
            dispatch("getTranslation", "errorNotLoaded").then(
              (response) => {
                message = task.description + " " + response;

                const notification = {
                  show: true,
                  result: false,
                  message: message,
                };

                dispatch("showSnackbar", notification);
                dispatch("setSawCalcLoading", false);
                reject({ code: promise.Code, message: promise.Description });
              },
              (error) => {
                // eslint-disable-next-line
                console.error(error);
              }
            );
          });

          return p;
        }
      } catch (error) {
        let p = new Promise(function(resolve, reject) {
          let message;
          dispatch("getTranslation", "errorNotLoaded").then(
            (response) => {
              message = task.description + " " + response;

              const notification = {
                show: true,
                result: false,
                message: message,
              };

              dispatch("showSnackbar", notification);

              reject(error);
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });

        return p;
      }
    }

    commit("SET_MATERIALS_READY", true);

    let p = new Promise(function(resolve, reject) {
      dispatch("getTranslation", "materialsUpdated").then(
        (response) => {
          resolve({ message: response });
        },
        (error) => {
          reject(error);
        }
      );
    });

    return p;
  },
  getMaterialStandards({ commit, dispatch, getters }) {
    commit("SET_MATERIAL_STANDARDS", null);

    return new Promise((resolve, reject) => {
      const url = config.url() + "GetMaterialStandards/1/1";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_MATERIAL_STANDARDS", res.List);

              dispatch("saveSettings", {
                key: "materialStandards",
                value: getters.materialStandards,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_MATERIAL_STANDARDS", null);

              dispatch("saveSettings", {
                key: "materialStandards",
                value: null,
              });

              let message;
              dispatch("getTranslation", "materialStandardsNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_MATERIAL_STANDARDS", null);

          dispatch("saveSettings", {
            key: "materialStandards",
            value: null,
          });

          dispatch("getTranslation", "errorGettingMaterialStandards").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting materialStandards: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting materialStandards: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getUNSCategories({ commit, dispatch, getters }) {
    commit("SET_UNS_CATEGORIES", null);

    return new Promise((resolve, reject) => {
      const url = config.url() + "GetUNSCategories/1/1";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_UNS_CATEGORIES", res.List);

              dispatch("saveSettings", {
                key: "unsCategories",
                value: getters.unsCategories,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_UNS_CATEGORIES", null);

              dispatch("saveSettings", {
                key: "unsCategories",
                value: null,
              });

              let message;
              dispatch("getTranslation", "unsCategoriesNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_UNS_CATEGORIES", null);

          dispatch("saveSettings", {
            key: "unsCategories",
            value: null,
          });

          dispatch("getTranslation", "errorGettingUNSCategories").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting unsCategories: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting unsCategories: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getUNSAlloyGroups({ commit, dispatch, getters }) {
    commit("SET_UNS_ALLOY_GROUPS", null);

    return new Promise((resolve, reject) => {
      const url = config.url() + "GetUNSAlloyGroups/1/1";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_UNS_ALLOY_GROUPS", res.List);

              dispatch("saveSettings", {
                key: "unsAlloyGroups",
                value: getters.unsAlloyGroups,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_UNS_ALLOY_GROUPS", null);

              dispatch("saveSettings", {
                key: "unsAlloyGroups",
                value: null,
              });

              let message;
              dispatch("getTranslation", "unsAlloyGroupsNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_UNS_ALLOY_GROUPS", null);

          dispatch("saveSettings", {
            key: "unsAlloyGroups",
            value: null,
          });

          dispatch("getTranslation", "errorGettingUNSAlloyGroups").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting unsAlloyGroups: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting unsAlloyGroups: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getSawCalcGroups({ commit, dispatch, getters }) {
    commit("SET_SAW_CALC_GROUPS", null);

    return new Promise((resolve, reject) => {
      const url = config.url() + "GetSawCalcGroups/1/1";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_SAW_CALC_GROUPS", res.SawCalcGroupsList);

              dispatch("saveSettings", {
                key: "sawCalcGroups",
                value: getters.sawCalcGroups,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_SAW_CALC_GROUPS", null);

              dispatch("saveSettings", {
                key: "sawCalcGroups",
                value: null,
              });

              let message;
              dispatch("getTranslation", "sawCalcGroupsNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_SAW_CALC_GROUPS", null);

          dispatch("saveSettings", {
            key: "sawCalcGroups",
            value: null,
          });

          dispatch("getTranslation", "errorGettingSawCalcGroups").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting sawCalcGroups: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting sawCalcGroups: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getUNSGeneralDescriptions({ commit, dispatch, getters }) {
    commit("SET_UNS_GENERAL_DESCRIPTIONS", null);

    return new Promise((resolve, reject) => {
      const url = config.url() + "GetUNSGeneralDescriptions/1/1";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_UNS_GENERAL_DESCRIPTIONS", res.List);

              dispatch("saveSettings", {
                key: "unsGeneralDescriptions",
                value: getters.unsGeneralDescriptions,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_UNS_GENERAL_DESCRIPTIONS", null);

              dispatch("saveSettings", {
                key: "unsGeneralDescriptions",
                value: null,
              });

              let message;
              dispatch("getTranslation", "unsGeneralDescriptionsNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_UNS_GENERAL_DESCRIPTIONS", null);

          dispatch("saveSettings", {
            key: "unsGeneralDescriptions",
            value: null,
          });

          dispatch("getTranslation", "errorGettingUNSGeneralDescriptions").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting unsGeneralDescriptions: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting unsGeneralDescriptions: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getSubCategories({ commit, dispatch, getters }) {
    commit("SET_SUB_CATEGORIES", null);

    return new Promise((resolve, reject) => {
      const url = config.url() + "GetSubCategories/1/1";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_SUB_CATEGORIES", res.List);

              dispatch("saveSettings", {
                key: "subCategories",
                value: getters.subCategories,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_SUB_CATEGORIES", null);

              dispatch("saveSettings", {
                key: "subCategories",
                value: null,
              });

              let message;
              dispatch("getTranslation", "subCategoriesNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_SUB_CATEGORIES", null);

          dispatch("saveSettings", {
            key: "subCategories",
            value: null,
          });

          dispatch("getTranslation", "errorGettingMaterialSubCategories").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting subCategories: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting subCategories: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getMaterials({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetMaterials/1/1";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_MATERIALS", res.MaterialsList);

              dispatch("saveSettings", {
                key: "materials",
                value: getters.materials,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_MATERIALS", null);

              dispatch("saveSettings", {
                key: "materials",
                value: null,
              });

              let message;
              dispatch("getTranslation", "materialsNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_MATERIALS", null);

          dispatch("saveSettings", {
            key: "materials",
            value: null,
          });

          dispatch("getTranslation", "errorGettingMaterials").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting materials: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting materials: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  getMaterialDetails({ commit, dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      const materialID = payload.id;

      const url = config.url() + "GetMaterialDetails/" + materialID;

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.Material;

              dispatch("getMaterialFromResponse", item).then((response) => {
                response.hardness = response.defaultHBHardness;

                dispatch("setMaterial", response);
                resolve({ message: res.Description, material: response });
              });
            } else {
              dispatch("getTranslation", "errorGettingMaterial").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("setMaterial", null);

          dispatch("getTranslation", "errorGettingMaterial").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error getting material: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting material: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  getMaterialFromResponse({ commit, getters }, item) {
    if (!item) {
      return;
    }

    return new Promise((resolve) => {
      const material = {
        materialID: item.MaterialID,
        designation: item.Designation,
        materialStandardID: item.MaterialStandardID,
        materialStandard: item.MaterialStandard,
        country: item.Country,
        standard: item.Standard,
        uns: item.UNS,
        unsCategoryID: item.UNSCategoryID,
        unsCategory: item.UNSCategory,
        unsAlloyGroupID: item.UNSAlloyGroupID,
        unsAlloyGroup: item.UNSAlloyGroup,
        sawCalcGroupID: item.SawCalcGroupID,
        sawCalcGroupName: item.SawCalcGroupName,
        sawCalcGroupDescription: item.sawCalcGroupDescription,
        machinabilityGroupBiMetal: item.MachinabilityGroupBiMetal,
        machinabilityGroupCarbide: item.MachinabilityGroupCarbide,
        machinabilityRating: item.MachinabilityRating,
        defaultHBHardness: item.DefaultHBHardness,
        compositeUNS: item.CompositeUNS,
        unsGeneralDescriptionID: item.UNSGeneralDescriptionID,
        unsGeneralDescription: item.UNSGeneralDescription,
        subCategoryID: item.SubCategoryID,
        subCategory: item.SubCategory,
        chemicalComposition: JSON.parse(item.ChemicalComposition),
        quickSearch: item.QuickSearch,
        hardness: item.Hardness,
        heavyScaleForged: item.HeavyScaleForged,
        htQuenched: item.HTQuenched,
      };

      if (item.ScaleAbbreviation) {
        const scale = {
          abbreviation: item.ScaleAbbreviation,
          description: item.ScaleDescription,
        };

        material.scale = scale;
      } else if (getters.scales) {
        material.scale = getters.scales[0];
      }

      material.hardnessDescription =
        material.hardness + " " + material.scale.abbreviation;

      if (material.machinabilityGroupBiMetal == undefined) {
        material.machinabilityGroupBiMetal = "";
      }

      if (material.machinabilityGroupCarbide == undefined) {
        material.machinabilityGroupCarbide = "";
      }

      material.machinability =
        "Group: " +
        material.machinabilityGroupBiMetal +
        "/" +
        material.machinabilityGroupCarbide +
        " " +
        "Rating: " +
        material.machinabilityRating;

      resolve(material);
    });
  },
  setMaterial({ commit }, material) {
    commit("SET_MATERIAL", material);
  },
  getScales({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetScales";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_SCALES", res.ScalesList);

              dispatch("saveSettings", {
                key: "scales",
                value: getters.scales,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_SCALES", null);

              dispatch("saveSettings", {
                key: "scales",
                value: null,
              });

              let message;
              dispatch("getTranslation", "scalesNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_SCALES", null);

          dispatch("saveSettings", {
            key: "scales",
            value: null,
          });

          dispatch("getTranslation", "errorGettingScales").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error getting scales: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting scales: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  setScale({ commit, dispatch }, payload) {
    const scale = payload.scale;

    commit("SET_SCALE", scale);

    let hardness = payload.hardness;
    const hardnessCalculated = payload.hardnessCalculated;

    if (hardnessCalculated != undefined && hardnessCalculated > 0) {
      if (hardnessCalculated - hardness < 1) {
        hardness = hardnessCalculated;
      }
    }

    if (scale.abbreviation == "HRC") {
      hardness = calculations.HBtoHRC(hardness);
    } else if (scale.abbreviation == "HB") {
      hardness = calculations.HRCtoHB(hardness);
    }

    dispatch("setHardness", hardness);
  },
  // eslint-disable-next-line no-unused-vars
  getHardness({ commit }, payload) {
    const scale = payload.scale;

    let hardness = payload.hardness;
    const hardnessCalculated = payload.hardnessCalculated;

    if (hardnessCalculated != undefined && hardnessCalculated > 0) {
      if (hardnessCalculated - hardness < 1) {
        hardness = hardnessCalculated;
      }
    }

    if (scale.abbreviation == "HRC") {
      hardness = calculations.HBtoHRC(hardness);
    } else if (scale.abbreviation == "HB") {
      hardness = calculations.HRCtoHB(hardness);
    }

    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      resolve({ hardness: parseInt(hardness), hardnessCalculated: hardness });
    });
  },
  setHardness({ commit }, hardness) {
    commit("SET_HARDNESS", hardness);
  },
  setHeavyScaleForged({ commit }, heavyScaleForged) {
    commit("SET_HEAVY_SCALE_FORGED", heavyScaleForged);
  },
  setHTQuenched({ commit }, htQuenched) {
    commit("SET_HT_QUENCHED", htQuenched);
  },
  getUserMaterials({ commit, dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const showNotification = payload.showNotification;
    let userID = payload.userID;

    if (!userID) {
      userID = getters.user.userID;
    }

    return new Promise((resolve, reject) => {
      const url = config.url() + "GetUserMaterials/_/" + userID + "/_/1/1";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_USER_MATERIALS", res.UserMaterialsList);

              dispatch("saveSettings", {
                key: "userMaterials",
                value: getters.userMaterials,
              });

              if (showNotification) {
                resolve({ code: res.Code, message: res.Description });
              } else {
                resolve({ code: res.Code, message: "" });
              }
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_USER_MATERIALS", null);

              dispatch("saveSettings", {
                key: "userMaterials",
                value: null,
              });

              let message;
              dispatch("getTranslation", "materialsNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_USER_MATERIALS", null);

          dispatch("saveSettings", {
            key: "userMaterials",
            value: null,
          });

          dispatch("getTranslation", "errorGettingMaterials").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error getting machines: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting machines: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getUserMaterial({ commit, dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    return new Promise((resolve, reject) => {
      const userMaterialID = payload.userMaterialID;

      const url = config.url() + "GetUserMaterial/" + userMaterialID;

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.UserMaterial;

              dispatch("getMaterialFromResponse", item).then((response) => {
                response.userMaterialID = item.UserMaterialID;
                response.userID = item.UserID;
                response.materialName = item.MaterialName;
                response.defined = true;

                commit("SET_USER_MATERIAL", response);

                resolve({ message: res.Description, material: response });
              });
            } else {
              dispatch("getTranslation", "errorGettingMaterial").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          commit("SET_USER_MATERIAL", null);

          dispatch("getTranslation", "errorGettingMaterial").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error getting material: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting material: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  addUserMaterial({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const materialID = payload.materialID;
    const scaleDescription = payload.scale.description;
    const scaleAbbreviation = payload.scale.abbreviation;
    let hardness = payload.hardness;
    if (hardness == 0) {
      hardness = getters.defaultHardness;
    }
    const heavyScaleForged = payload.heavyScaleForged;
    const htQuenched = payload.htQuenched;

    const url = config.url() + "AddUserMaterial";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            UserID: getters.user.userID,
            MaterialID: materialID,
            ScaleDescription: scaleDescription,
            ScaleAbbreviation: scaleAbbreviation,
            Hardness: hardness,
            HeavyScaleForged: heavyScaleForged,
            HTQuenched: htQuenched,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.UserMaterial;

              dispatch("getMaterialFromResponse", item).then((response) => {
                response.userMaterialID = item.UserMaterialID;
                response.materialName = item.MaterialName;

                dispatch("getUserMaterials", { showNotification: false });

                resolve({ message: res.Description, material: response });
              });
            } else if (res.Code == returnCode.ErrRecordExists) {
              dispatch("getTranslation", "materialExists").then(
                (response) => {
                  reject({ code: res.Code, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              dispatch("getTranslation", "errorCreatingMaterial").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("setMaterial", null);

          dispatch("getTranslation", "errorCreatingMaterial").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error creating user material: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error creating user material: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  removeUserMaterial({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const userID = getters.user.userID;
    const userMaterialID = payload.userMaterialID;

    if (!userID || !userMaterialID) {
      return;
    }

    const url =
      config.url() + "RemoveUserMaterial/" + userID + "/" + userMaterialID;

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              dispatch("getUserMaterials", { showNotification: false });

              resolve({ message: res.Description });
            } else {
              dispatch("getTranslation", "errorRemovingMaterial").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorRemovingMaterial").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error removing user material: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error removing user material: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  updateUserMaterial({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }
    
    const userMaterialID = payload.userMaterialID;
    const materialName = payload.materialName;
    const scaleDescription = payload.scale.description;
    const scaleAbbreviation = payload.scale.abbreviation;
    let hardness = payload.hardness;
    if (hardness == 0) {
      hardness = getters.defaultHardness;
    }
    const heavyScaleForged = payload.heavyScaleForged;
    const htQuenched = payload.htQuenched;

    const url = config.url() + "UpdateUserMaterial";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            UserID: getters.user.userID,
            UserMaterialID: userMaterialID,
            MaterialName: materialName,
            ScaleDescription: scaleDescription,
            ScaleAbbreviation: scaleAbbreviation,
            Hardness: hardness,
            HeavyScaleForged: heavyScaleForged,
            HTQuenched: htQuenched,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.UserMaterial;

              dispatch("getMaterialFromResponse", item).then((response) => {
                response.userMaterialID = item.UserMaterialID;
                response.materialName = item.MaterialName;

                dispatch("getUserMaterials", { showNotification: false });

                resolve({ message: res.Description, material: response });
              });
            } else if (res.Code == returnCode.ErrRecordExists) {
              dispatch("getTranslation", "materialExists").then(
                (response) => {
                  reject({ code: res.Code, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              dispatch("getTranslation", "errorCreatingMaterial").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("setMaterial", null);

          dispatch("getTranslation", "errorCreatingMaterial").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error creating user material: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error creating user material: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  copyUserMaterial({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const userID = getters.user.userID;
    const userMaterialID = payload.userMaterialID;

    if (!userID || !userMaterialID) {
      return;
    }

    const url =
      config.url() + "CopyUserMaterial/" + userID + "/" + userMaterialID;

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else {
              dispatch("getTranslation", "errorCreatingMaterial").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorCreatingMaterial").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error creating user material: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error creating user material: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },

  // Dimensions

  async getDimensionsData({ commit, dispatch }, payload) {
    commit("SET_DIMENSIONS_READY", false);
    dispatch("setSawCalcLoading", true);

    const tasks = [...payload.tasks];

    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];

      let promise;
      try {
        promise = await dispatch(task.function, task.payload);

        if (
          promise.code != returnCode.Success &&
          promise.code != returnCode.ErrRecordNotFound
        ) {
          let p = new Promise(function(resolve, reject) {
            let message;
            dispatch("getTranslation", "errorNotLoaded").then(
              (response) => {
                message = task.description + " " + response;

                const notification = {
                  show: true,
                  result: false,
                  message: message,
                };

                dispatch("showSnackbar", notification);
                dispatch("setSawCalcLoading", false);
                reject({ code: promise.Code, message: promise.Description });
              },
              (error) => {
                // eslint-disable-next-line
                console.error(error);
              }
            );
          });

          return p;
        }
      } catch (error) {
        let p = new Promise(function(resolve, reject) {
          let message;
          dispatch("getTranslation", "errorNotLoaded").then(
            (response) => {
              message = task.description + " " + response;

              const notification = {
                show: true,
                result: false,
                message: message,
              };

              dispatch("showSnackbar", notification);

              reject(error);
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });

        return p;
      }
    }

    commit("SET_DIMENSIONS_READY", true);

    let p = new Promise(function(resolve, reject) {
      dispatch("getTranslation", "dimensionsUpdated").then(
        (response) => {
          resolve({ message: response });
        },
        (error) => {
          reject(error);
        }
      );
    });

    return p;
  },
  getIBeamStandards({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetIBeamStandards/_/_/_/_";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_IBEAM_GROUPS_STANDARDS", res.IBeamStandardsList);

              dispatch("saveSettings", {
                key: "iBeamGroups",
                value: getters.iBeamGroups,
              });

              dispatch("saveSettings", {
                key: "iBeamStandards",
                value: getters.iBeamStandards,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_IBEAM_GROUPS_STANDARDS", null);

              dispatch("saveSettings", {
                key: "iBeamGroups",
                value: null,
              });

              dispatch("saveSettings", {
                key: "iBeamStandards",
                value: null,
              });

              let message;
              dispatch("getTranslation", "iBeamStandardsNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_IBEAM_GROUPS_STANDARDS", null);

          dispatch("saveSettings", {
            key: "iBeamGroups",
            value: null,
          });

          dispatch("saveSettings", {
            key: "iBeamStandards",
            value: null,
          });

          dispatch("getTranslation", "errorGettingIBeamStandards").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting IBeamStandards: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting IBeamStandards: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },

  // Vising

  async getVisingData({ commit, dispatch, getters }) {
    commit("SET_VISING_READY", true);

    dispatch("saveSettings", {
      key: "visingReady",
      value: getters.visingReady,
    });

    dispatch("setSawCalcLoading", true);

    let p = new Promise(function(resolve, reject) {
      dispatch("getTranslation", "visingUpdated").then(
        (response) => {
          resolve({ message: response });
        },
        (error) => {
          reject(error);
        }
      );
    });

    return p;
  },

  // Conditions

  async getConditionsData({ commit, dispatch, getters }) {
    commit("SET_CONDITIONS_READY", true);

    dispatch("saveSettings", {
      key: "conditionsReady",
      value: getters.conditionsReady,
    });

    dispatch("setSawCalcLoading", true);

    let p = new Promise(function(resolve, reject) {
      dispatch("getTranslation", "conditionsUpdated").then(
        (response) => {
          resolve({ message: response });
        },
        (error) => {
          reject(error);
        }
      );
    });

    return p;
  },

  // Product Lines

  async getProductLinesData({ commit, dispatch }, payload) {
    commit("SET_PRODUCT_LINES_READY", false);
    dispatch("setSawCalcLoading", true);

    const tasks = [...payload.tasks];

    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];

      let promise;
      try {
        promise = await dispatch(task.function, task.payload);

        if (
          promise.code != returnCode.Success &&
          promise.code != returnCode.ErrRecordNotFound
        ) {
          let p = new Promise(function(resolve, reject) {
            let message;
            dispatch("getTranslation", "errorNotLoaded").then(
              (response) => {
                message = task.description + " " + response;

                const notification = {
                  show: true,
                  result: false,
                  message: message,
                };

                dispatch("showSnackbar", notification);
                dispatch("setSawCalcLoading", false);
                reject({ code: promise.Code, message: promise.Description });
              },
              (error) => {
                // eslint-disable-next-line
                console.error(error);
              }
            );
          });

          return p;
        }
      } catch (error) {
        let p = new Promise(function(resolve, reject) {
          let message;
          dispatch("getTranslation", "errorNotLoaded").then(
            (response) => {
              message = task.description + " " + response;

              const notification = {
                show: true,
                result: false,
                message: message,
              };

              dispatch("showSnackbar", notification);

              reject(error);
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });

        return p;
      }
    }

    commit("SET_PRODUCT_LINES_READY", true);

    let p = new Promise(function(resolve, reject) {
      dispatch("getTranslation", "productLinesUpdated").then(
        (response) => {
          resolve({ message: response });
        },
        (error) => {
          reject(error);
        }
      );
    });

    return p;
  },
  getProductLinesShort({ commit, dispatch, getters }) {
    commit("SET_PRODUCT_LINES_SHORT", null);

    return new Promise((resolve, reject) => {
      const url = config.url() + "GetProductLinesShort/1/1";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_PRODUCT_LINES_SHORT", res.ProductLinesList);

              dispatch("saveSettings", {
                key: "productLinesShort",
                value: getters.productLinesShort,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_PRODUCT_LINES_SHORT", null);

              dispatch("saveSettings", {
                key: "productLinesShort",
                value: null,
              });

              let message;
              dispatch("getTranslation", "productLinesNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_PRODUCT_LINES_SHORT", null);

          dispatch("saveSettings", {
            key: "productLinesShort",
            value: null,
          });

          dispatch("getTranslation", "errorGettingProductLines").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting product lines: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting product lines: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getBWLRefs({ commit, dispatch, getters }) {
    commit("SET_BWL_REFS", null);

    return new Promise((resolve, reject) => {
      const url = config.url() + "GetBWLRefs/1/1";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_BWL_REFS", res.BWLRefsList);

              dispatch("saveSettings", {
                key: "bwlRefs",
                value: getters.bwlRefs,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_BWL_REFS", null);

              dispatch("saveSettings", {
                key: "bwlRefs",
                value: null,
              });

              let message;
              dispatch("getTranslation", "bwlRefsNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_BWL_REFS", null);

          dispatch("saveSettings", {
            key: "bwlRefs",
            value: null,
          });

          dispatch("getTranslation", "errorGettingBWLRefs").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error getting bwlRefs: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting bwlRefs: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },

  // SawCalc

  resetSawCalcReady({ dispatch, getters }) {
    dispatch("setMachine", null);
    dispatch("setMaterial", null);

    if (getters.savedInputMachine) {
      dispatch("setInputMachine", getters.savedInputMachine);
    } else {
      dispatch("setInputMachine", null);
    }

    if (getters.savedInputMaterial) {
      dispatch("setInputMaterial", getters.savedInputMaterial);
    } else {
      dispatch("setInputMaterial", null);
    }

    dispatch("setInputDimensions", null);
    dispatch("setInputVising", null);
    dispatch("setInputConditions", null);

    dispatch("setMachinesReady", false);
    dispatch("setMaterialsReady", false);
    dispatch("setDimensionsReady", false);
    dispatch("setVisingReady", false);
    dispatch("setConditionsReady", false);
    dispatch("setProductLinesReady", false);
  },
  getSawCalcData({ dispatch, getters }) {
    if (!getters.machinesReady) {
      const tasks = [...getters.machineTasks];

      if (getters.user) {
        tasks.unshift({
          description: "User Machines",
          function: "getUserMachines",
          payload: { showNotification: true },
        });
      }

      dispatch("getMachinesData", { tasks: tasks }).then(
        (response) => {
          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          dispatch("showSnackbar", notification);

          dispatch("getSawCalcData");

          // eslint-disable-next-line no-unused-vars
          return new Promise((resolve, reject) => {
            resolve({ status: true, response: response });
          });
        },
        (error) => {
          if (api.authenticationError(error.Code)) {
            dispatch("logoff");
          }

          // eslint-disable-next-line no-unused-vars
          return new Promise((resolve, reject) => {
            dispatch("setSawCalcLoading", false);

            const notification = {
              show: true,
              result: false,
              message: error.message,
            };

            dispatch("showSnackbar", notification);

            resolve({ status: false, error: error });
          });
        }
      );
    } else if (!getters.materialsReady) {
      const tasks = [...getters.materialTasks];

      if (getters.user) {
        tasks.unshift({
          description: "User Materials",
          function: "getUserMaterials",
          payload: {
            showNotification: true,
          },
        });
      }

      dispatch("getMatrialsData", { tasks: tasks }).then(
        (response) => {
          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          dispatch("showSnackbar", notification);

          dispatch("getSawCalcData");

          // eslint-disable-next-line no-unused-vars
          return new Promise((resolve, reject) => {
            resolve({ status: true, response: response });
          });
        },
        (error) => {
          if (api.authenticationError(error.Code)) {
            dispatch("logoff");
          }

          // eslint-disable-next-line no-unused-vars
          return new Promise((resolve, reject) => {
            dispatch("setSawCalcLoading", false);

            const notification = {
              show: true,
              result: false,
              message: error.message,
            };

            dispatch("showSnackbar", notification);

            resolve({ status: false, error: error });
          });
        }
      );
    } else if (!getters.dimensionsReady) {
      const tasks = [...getters.dimensionTasks];

      dispatch("getDimensionsData", { tasks: tasks }).then(
        (response) => {
          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          dispatch("showSnackbar", notification);

          dispatch("getSawCalcData");

          // eslint-disable-next-line no-unused-vars
          return new Promise((resolve, reject) => {
            resolve({ status: true, response: response });
          });
        },
        (error) => {
          if (api.authenticationError(error.Code)) {
            dispatch("logoff");
          }

          // eslint-disable-next-line no-unused-vars
          return new Promise((resolve, reject) => {
            dispatch("setSawCalcLoading", false);

            const notification = {
              show: true,
              result: false,
              message: error.message,
            };

            dispatch("showSnackbar", notification);

            resolve({ status: false, error: error });
          });
        }
      );
    } else if (!getters.visingReady) {
      dispatch("getVisingData").then(
        (response) => {
          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          dispatch("showSnackbar", notification);

          dispatch("getSawCalcData");

          // eslint-disable-next-line no-unused-vars
          return new Promise((resolve, reject) => {
            resolve({ status: true, response: response });
          });
        },
        (error) => {
          if (api.authenticationError(error.Code)) {
            dispatch("logoff");
          }

          // eslint-disable-next-line no-unused-vars
          return new Promise((resolve, reject) => {
            dispatch("setSawCalcLoading", false);

            const notification = {
              show: true,
              result: false,
              message: error.message,
            };

            dispatch("showSnackbar", notification);

            resolve({ status: false, error: error });
          });
        }
      );
    } else if (!getters.conditionsReady) {
      dispatch("getConditionsData").then(
        (response) => {
          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          dispatch("showSnackbar", notification);

          dispatch("getSawCalcData");

          // eslint-disable-next-line no-unused-vars
          return new Promise((resolve, reject) => {
            resolve({ status: true, response: response });
          });
        },
        (error) => {
          if (api.authenticationError(error.Code)) {
            dispatch("logoff");
          }

          // eslint-disable-next-line no-unused-vars
          return new Promise((resolve, reject) => {
            dispatch("setSawCalcLoading", false);

            const notification = {
              show: true,
              result: false,
              message: error.message,
            };

            dispatch("showSnackbar", notification);

            resolve({ status: false, error: error });
          });
        }
      );
    } else if (!getters.productLinesReady) {
      const tasks = [...getters.productLineTasks];

      dispatch("getProductLinesData", { tasks: tasks }).then(
        (response) => {
          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          dispatch("showSnackbar", notification);

          dispatch("getSawCalcData");

          // eslint-disable-next-line no-unused-vars
          return new Promise((resolve, reject) => {
            resolve({ status: true, response: response });
          });
        },
        (error) => {
          if (api.authenticationError(error.Code)) {
            dispatch("logoff");
          }

          // eslint-disable-next-line no-unused-vars
          return new Promise((resolve, reject) => {
            dispatch("setSawCalcLoading", false);

            const notification = {
              show: true,
              result: false,
              message: error.message,
            };

            dispatch("showSnackbar", notification);

            resolve({ status: false, error: error });
          });
        }
      );
    }

    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      if (getters.sawCalcReady) {
        dispatch("getTranslation", "sawCalcUpdated").then((response) => {
          const message = response;

          const notification = {
            show: true,
            result: true,
            message: message,
          };

          dispatch("showSnackbar", notification);

          dispatch("saveSettings", {
            key: "dbSyncDate",
            value: new Date().getTime(),
          });
        });

        dispatch("setSawCalcLoading", false);
        dispatch("checkInputs");
      }

      if (getters.selectedRecommendationForCustom != null) {
        dispatch("closeCustomRecommendation");
      }

      resolve({ status: true });
    });
  },
  setSawCalcLoading({ commit }, payload) {
    commit("SET_SAW_CALC_LOADING", payload);
  },

  selectRecommendation({ commit }, payload) {
    commit("SELECT_RECOMMENDATION", {
      recommendation: payload,
    });
  },
  customize({ commit, getters }, payload) {
    const recommendationIndex = payload;

    return new Promise((resolve) => {
      commit("CUSTOMIZE", {
        recommendationIndex: recommendationIndex,
        recommendation: getters.recommendations[recommendationIndex],
        costCalculation: getters.costCalculations[recommendationIndex],
      });

      commit("RELOAD_RECOMMENDATION_COMPONENT");

      resolve();
    });
  },
  getRecommendations({ commit, dispatch, getters }) {
  if (
      getters.input.machine.cantedHead &&
      getters.input.conditions.canting == 0
    ) {
      getters.input.conditions.canting = getters.defaultCantingDegrees;
    }

    if (!getters.input.machine.cantedHead) {
      getters.input.conditions.canting = 0;
    }

    const input = JSON.parse(JSON.stringify(getters.input));
    delete input.material.chemicalComposition;

    const url = config.url() + "GetRecommendations";

    dispatch("setSawCalcLoading", true);

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            Json: JSON.stringify(input),
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("setSawCalcLoading", false);
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_RECOMMENDATIONS", res.RecommendationUIsList);
              commit("RECOMMENDATION_UPDATED");

              dispatch("updateCostCalculations");

              try { 
                  //clearing data for old storage/recommendation
                  window.localStorage.removeItem("recommendationIndex");
                  window.localStorage.removeItem("lastOverrideValue");
                  window.localStorage.removeItem("recommendationDataVal"); 

                  //setting new values. it will be used during customization
                  let mapData = {bandSpeedMinImperial:input.machine.bandSpeedMinImperial,bandSpeedMaxImperial:input.machine.bandSpeedMaxImperial,bandSpeedMinMetric:input.machine.bandSpeedMinMetric,bandSpeedMaxMetric:input.machine.bandSpeedMaxMetric}
                  window.localStorage.setItem("machineMapsData", JSON.stringify(mapData));
              } catch (error) {
                  // eslint-disable-next-line
              }

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrEngine) {
              commit("SET_RECOMMENDATIONS", null);

              const notification = {
                show: true,
                result: false,
                message: res.Description,
              };

              dispatch("showSnackbar", notification);

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_RECOMMENDATIONS", null);
              let message;
              dispatch("getTranslation", "recommendationsNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          dispatch("setSawCalcLoading", false);
          commit("SET_RECOMMENDATIONS", null);

          dispatch("getTranslation", "errorGettingRecommedations").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting recommendations: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting recommendations: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getRecommendationCustom({ dispatch, getters }, payload) {
    const input = JSON.parse(JSON.stringify(getters.input));
    delete input.material.chemicalComposition;
    input.custom = payload;
    const url = config.url() + "GetRecommendations";
   
    dispatch("setSawCalcLoading", true);
   //   return false;
    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            Json: JSON.stringify(input),
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("setSawCalcLoading", false);
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              let recommendation = null;

              if (
                res.RecommendationUIsList != null &&
                res.RecommendationUIsList.length > 0
              ) {
                recommendation = res.RecommendationUIsList[0];
              }

              try {
                  //clearing data for old storage/recommendation
                  window.localStorage.removeItem("recommendationIndex");
                  window.localStorage.removeItem("lastOverrideValue"); 
                  window.localStorage.removeItem("recommendationDataVal"); 

                  //setting new values. it will be used during customization
                  let mapData = {bandSpeedMinImperial:input.machine.bandSpeedMinImperial,bandSpeedMaxImperial:input.machine.bandSpeedMaxImperial,bandSpeedMinMetric:input.machine.bandSpeedMinMetric,bandSpeedMaxMetric:input.machine.bandSpeedMaxMetric}
                  window.localStorage.setItem("machineMapsData", JSON.stringify(mapData));
              } catch (error) {
                  // eslint-disable-next-line
              }

              const costCalculation =
                getters.costCalculations[recommendations.Custom];
              costCalculation.uom = getters.measurementUOM;

              const cuttingRate = getters.input.metric
                ? recommendation.CuttingRateMetric
                : recommendation.CuttingRateImperial;

              dispatch("updateCostCalculation", {
                cutTime: recommendation.CutTime,
                cuttingRate: cuttingRate,
                costCalculation: costCalculation,
                recommendationIndex: recommendations.Custom,
              })
                .then(() => {})
                .catch(() => {});

              resolve({
                code: res.Code,
                message: res.Description,
                recommendation: recommendation,
              });
            } else if (res.Code == returnCode.ErrEngine) {
              const notification = {
                show: true,
                result: false,
                message: res.Description,
              };

              dispatch("showSnackbar", notification);

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              let message;
              dispatch("getTranslation", "recommendationsNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          dispatch("setSawCalcLoading", false);
          dispatch("getTranslation", "errorGettingRecommedations").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting recommendations: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting recommendations: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  updateCostCalculations({ dispatch, getters }) {
    if (getters.recommendations.length > 0) {
      for (let i = 0; i < getters.recommendations.length; i++) {
        const recommendation = getters.recommendations[i];

        const costCalculation = getters.costCalculations[i];
        costCalculation.uom = getters.measurementUOM;

        const cuttingRate = getters.input.metric
          ? recommendation.cuttingRateMetric
          : recommendation.cuttingRateImperial;

        dispatch("updateCostCalculation", {
          cutTime: recommendation.cutTime,
          cuttingRate: cuttingRate,
          costCalculation: costCalculation,
          recommendationIndex: i,
        })
          .then(() => {})
          .catch(() => {});
      }
    } else {
      dispatch("resetOutputs");
    }
  },
  resetOutputs({ commit }) {
    commit("SET_RECOMMENDATIONS", null);
    commit("RECOMMENDATION_UPDATED");
    commit("RESET_COST_CALCULATIONS");
  },

  closeCustomRecommendation({ commit }) {
    commit("CLOSE_CUSTOM_RECOMMENDATION");
    commit("RELOAD_RECOMMENDATION_COMPONENT");
  },
  updateFromCustom({ commit }, payload) {
    commit("UPDATE_FROM_CUSTOM", payload);
    commit("CLOSE_CUSTOM_RECOMMENDATION");
    commit("RELOAD_RECOMMENDATION_COMPONENT");
  },

  // Home

  // eslint-disable-next-line no-unused-vars
  getImageUrl({ commit, dispatch }, image) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        let message;
        dispatch("getTranslation", "imageUrlFound").then(
          (response) => {
            message = response;
            resolve({ message: message, url: reader.result });
          },
          (error) => {
            // eslint-disable-next-line
            console.error(error);
          }
        );
      };

      reader.onerror = () => {
        dispatch("getTranslation", "errorGettingImageData").then(
          (response) => {
            reject({ code: returnCode.ErrBadRequest, message: response });
          },
          (error) => {
            // eslint-disable-next-line
            console.error(error);
          }
        );
      };

      if (image) {
        reader.readAsDataURL(image);
      }
    });
  },
  // eslint-disable-next-line no-unused-vars
  getImageData({ commit, dispatch }, url) {
    return new Promise((resolve, reject) => {
      var request = new XMLHttpRequest();
      request.responseType = "blob";

      request.onload = () => {
        const image = request.response;

        let message;
        dispatch("getTranslation", "imageDataReceived").then(
          (response) => {
            message = response;
            resolve({ message: message, image: image });
          },
          (error) => {
            // eslint-disable-next-line
            console.error(error);
          }
        );
      };

      request.onerror = () => {
        dispatch("getTranslation", "errorGettingImageData").then(
          (response) => {
            reject({ code: returnCode.ErrBadRequest, message: response });
          },
          (error) => {
            // eslint-disable-next-line
            console.error(error);
          }
        );
      };

      request.open("GET", url);
      request.send();
    });
  },

  // Login

  showLoginDialog({ commit }, show) {
    commit("SHOW_LOGIN_DIALOG", show);
  },
  loginLoading({ commit }, loading) {
    commit("LOGIN_LOADING", loading);
  },
  login({ dispatch, getters }, payload) {
    dispatch("resetSawCalcReady");

    const email = payload.email;
    const password = payload.password;

    const url = config.url() + "Login";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            Email: email,
            Password: password,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              dispatch("updateUser", { user: res.User, save: true });
              resolve({ message: res.Description });
            } else {
              dispatch("updateUser", { user: null, save: true });
              reject({ message: res.Description });
            }
          }
        })
        .catch((error) => {
          dispatch("updateUser", { user: null, save: true });
          reject(error.message);
        });
    });
  },

  // Reports

  getRecommendationReport({ commit, dispatch, getters }) {
    const input = JSON.parse(JSON.stringify(getters.input));
    delete input.material.chemicalComposition;

    const sawCalcOutput = [];
    let count = getters.recommendations.length;
    if (count > 3) {
      count = 3;
    }

    for (let i = 0; i < count; i++) {
      const defaultCostCalculations = {
        bladeLife: 1,
        costsPerCut: "",
        costsPerMeasurement: "",
        cutsPerBlade: "",
        laborBurdenRate: "",
        measurement: {
          measurementImperial: 0,
          measurementMetric: 0,
        },
        pricePerBlade: "",
        title: "",
      };

      const costCalculations =
        getters.costCalculations[i].costsPerCut != undefined &&
        getters.costCalculations[i].costsPerCut != null
          ? getters.costCalculations[i]
          : defaultCostCalculations;

      if (
        costCalculations.measurement.measurementImperial == undefined ||
        costCalculations.measurement.measurementImperial == null
      ) {
        costCalculations.measurement.measurementImperial = 0;
      }

      if (
        costCalculations.measurement.measurementMetric == undefined ||
        costCalculations.measurement.measurementMetric == null
      ) {
        costCalculations.measurement.measurementMetric = 0;
      }

      sawCalcOutput.push({
        Recommendation: getters.recommendations[i],
        CostCalculation: costCalculations,
      });
    }

    const obj = {
      Input: input,
      SawCalcOutput: sawCalcOutput,
    };

    const url = config.url() + "GetRecommendationReport";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    commit("SET_SAW_CALC_LOADING", true);

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            Json: JSON.stringify(obj),
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
            responseType: "blob",
          }
        )
        .then((response) => {
          commit("SET_SAW_CALC_LOADING", false);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            const file = new Blob([res], { type: "application/pdf" });
            const objectURL = URL.createObjectURL(file);

            window.open(objectURL);
            URL.revokeObjectURL(file);

            let message;
            dispatch("getTranslation", "reportCreated").then(
              (response) => {
                message = response;
                resolve({ code: returnCode.Success, message: message });
              },
              (error) => {
                // eslint-disable-next-line
                console.error(error);
              }
            );
          }
        })
        .catch((error) => {
          commit("SET_SAW_CALC_LOADING", false);

          reject(error);
        });
    });
  },
  emailRecommendationReport({ commit, dispatch, getters }) {
    if (!getters.user) {
      return;
    }

    const input = JSON.parse(JSON.stringify(getters.input));
    delete input.material.chemicalComposition;

    const sawCalcOutput = [];
    let count = getters.recommendations.length;
    if (count > 3) {
      count = 3;
    }

    for (let i = 0; i < count; i++) {
      const defaultCostCalculations = {
        bladeLife: 1,
        costsPerCut: "",
        costsPerMeasurement: "",
        cutsPerBlade: "",
        laborBurdenRate: "",
        measurement: {
          measurementImperial: 0,
          measurementMetric: 0,
        },
        pricePerBlade: "",
        title: "",
      };

      const costCalculations =
        getters.costCalculations[i].costsPerCut != undefined &&
        getters.costCalculations[i].costsPerCut != null
          ? getters.costCalculations[i]
          : defaultCostCalculations;

      if (
        costCalculations.measurement.measurementImperial == undefined ||
        costCalculations.measurement.measurementImperial == null
      ) {
        costCalculations.measurement.measurementImperial = 0;
      }

      if (
        costCalculations.measurement.measurementMetric == undefined ||
        costCalculations.measurement.measurementMetric == null
      ) {
        costCalculations.measurement.measurementMetric = 0;
      }

      sawCalcOutput.push({
        Recommendation: getters.recommendations[i],
        CostCalculation: costCalculations,
      });
    }

    const obj = {
      Input: input,
      SawCalcOutput: sawCalcOutput,
    };

    const url = config.url() + "EmailRecommendationReport";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    commit("SET_SAW_CALC_LOADING", true);

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            Json: JSON.stringify(obj),
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          commit("SET_SAW_CALC_LOADING", false);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ code: res.Code, message: res.Description });
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_SAW_CALC_LOADING", false);

          reject(error);
        });
    });
  },
  getCuttingChartReport({ commit, dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const input = payload.input;
    input.userID = getters.user.userID;

    const obj = {
      Input: input,
      Recommendations: payload.recommendations,
    };

    const url = config.url() + "GetCuttingChartReport";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    commit("SET_SAW_CALC_LOADING", true);

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            Json: JSON.stringify(obj),
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
            responseType: "blob",
          }
        )
        .then((response) => {
          commit("SET_SAW_CALC_LOADING", false);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            const file = new Blob([res], { type: "application/pdf" });
            const objectURL = URL.createObjectURL(file);

            window.open(objectURL);
            URL.revokeObjectURL(file);

            let message;
            dispatch("getTranslation", "reportCreated").then(
              (response) => {
                message = response;
                resolve({ code: returnCode.Success, message: message });
              },
              (error) => {
                // eslint-disable-next-line
                console.error(error);
              }
            );
          }
        })
        .catch((error) => {
          commit("SET_SAW_CALC_LOADING", false);

          reject(error);
        });
    });
  },
  openCuttingChartReport({ commit, dispatch, getters }, payload) {
    const cuttingChartID = payload.cuttingChartID;
    const cuttingChartName = payload.cuttingChartName;
    const userID = payload.userID;
    const metric = payload.metric;
    const machine = payload.machine;
    const productImperial = payload.productImperial;
    const productMetric = payload.productMetric;
    const dimensionMin = payload.dimensionMin;
    const dimensionMax = payload.dimensionMax;
    const dimensionRange = payload.dimensionRange;
    const cuttingChartMaterialGroups = payload.cuttingChartMaterialGroups;

    const url = config.url() + "GetSavedCuttingChartReport";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    commit("SET_SAW_CALC_LOADING", true);

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            CuttingChartID: cuttingChartID,
            CuttingChartName: cuttingChartName,
            UserID: userID,
            Metric: metric,
            Machine: machine,
            ProductImperial: productImperial,
            ProductMetric: productMetric,
            DimensionMin: dimensionMin,
            DimensionMax: dimensionMax,
            DimensionRange: dimensionRange,
            CuttingChartMaterialGroups: cuttingChartMaterialGroups,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
            responseType: "blob",
          }
        )
        .then((response) => {
          commit("SET_SAW_CALC_LOADING", false);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            const file = new Blob([res], { type: "application/pdf" });
            const objectURL = URL.createObjectURL(file);

            window.open(objectURL);
            URL.revokeObjectURL(file);

            let message;
            dispatch("getTranslation", "reportCreated").then(
              (response) => {
                message = response;
                resolve({ code: returnCode.Success, message: message });
              },
              (error) => {
                // eslint-disable-next-line
                console.error(error);
              }
            );
          }
        })
        .catch((error) => {
          commit("SET_SAW_CALC_LOADING", false);

          reject(error);
        });
    });
  },
  openCuttingChartExcelReport({ commit, dispatch, getters }, payload) {
    const cuttingChartID = payload.cuttingChartID;
    const cuttingChartName = payload.cuttingChartName;
    const userID = payload.userID;
    const metric = payload.metric;
    const machine = payload.machine;
    const productImperial = payload.productImperial;
    const productMetric = payload.productMetric;
    const dimensionMin = payload.dimensionMin;
    const dimensionMax = payload.dimensionMax;
    const dimensionRange = payload.dimensionRange;
    const cuttingChartMaterialGroups = payload.cuttingChartMaterialGroups;


    // console.log("cuttingChartID = " + cuttingChartID);
    // console.log("cuttingChartName = " +cuttingChartName);
    // console.log("userID = " +userID);
    // console.log("machine = " +machine);
    // console.log("productImperial = " +productImperial);
    // console.log("productMetric = " +productMetric);
    // console.log("dimensionMin = " +dimensionMin);
    // console.log("dimensionMax = " +dimensionMax);
    // console.log("dimensionRange = " +dimensionRange);
    // console.log("cuttingChartMaterialGroups = " +cuttingChartMaterialGroups);
    // console.log(cuttingChartMaterialGroups);

    const url = config.url() + "GetSavedCuttingChartExcelReport";
    //console.log(url);

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    commit("SET_SAW_CALC_LOADING", true);

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            CuttingChartID: cuttingChartID,
            CuttingChartName: cuttingChartName,
            UserID: userID,
            Metric: metric,
            Machine: machine,
            ProductImperial: productImperial,
            ProductMetric: productMetric,
            DimensionMin: dimensionMin,
            DimensionMax: dimensionMax,
            DimensionRange: dimensionRange,
            CuttingChartMaterialGroups: cuttingChartMaterialGroups,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
            responseType: "blob",
          }
        )
        .then((response) => {
          commit("SET_SAW_CALC_LOADING", false);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            const file = new Blob([res], { type: "application/vnd.ms-excel" });
            const objectURL = URL.createObjectURL(file);
            window.open(objectURL);
            URL.revokeObjectURL(file);

            
            //const objectURL = URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
            
            //const link = document.createElement('a')
            //link.href = objectURL
            //link.setAttribute('download', 'test.xls')
            //document.body.appendChild(link)
            //link.click()

            let message;
            dispatch("getTranslation", "reportCreated").then(
              (response) => {
                message = response;
                resolve({ code: returnCode.Success, message: message });
              },
              (error) => {
                // eslint-disable-next-line
                console.error(error);
              }
            );
          }
        })
        .catch((error) => {
          commit("SET_SAW_CALC_LOADING", false);

          reject(error);
        });
    });
  },
  emailCuttingChartReport({ commit, dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }
    const cuttingChartID = payload.cuttingChartID;
    const cuttingChartName = payload.cuttingChartName;
    const userID = payload.userID;
    const metric = payload.metric;
    const machine = payload.machine;
    const productImperial = payload.productImperial;
    const productMetric = payload.productMetric;
    const dimensionMin = payload.dimensionMin;
    const dimensionMax = payload.dimensionMax;
    const dimensionRange = payload.dimensionRange;
    const cuttingChartMaterialGroups = payload.cuttingChartMaterialGroups;

    const url = config.url() + "EmailSavedCuttingChartReport";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    commit("SET_SAW_CALC_LOADING", true);

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            CuttingChartID: cuttingChartID,
            CuttingChartName: cuttingChartName,
            UserID: userID,
            Metric: metric,
            Machine: machine,
            ProductImperial: productImperial,
            ProductMetric: productMetric,
            DimensionMin: dimensionMin,
            DimensionMax: dimensionMax,
            DimensionRange: dimensionRange,
            CuttingChartMaterialGroups: cuttingChartMaterialGroups,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          commit("SET_SAW_CALC_LOADING", false);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ code: res.Code, message: res.Description });
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_SAW_CALC_LOADING", false);

          reject(error);
        });
    });
  },

  // Logoff

  logoff({ dispatch }) {
    return new Promise((resolve) => {
      dispatch("resetSawCalcReady");

      dispatch("deleteDB");

      dispatch("updateUser", { user: null, save: true });
      dispatch("resetInputs");

      let message;
      dispatch("getTranslation", "logoffSuccessful").then(
        (response) => {
          message = response;
          resolve({ message: message });
        },
        (error) => {
          // eslint-disable-next-line
          console.error(error);
        }
      );
    });
  },

  // Register

  showRegisterDialog({ commit }, show) {
    commit("SHOW_REGISTER_DIALOG", show);
  },
  registerLoading({ commit }, loading) {
    commit("REGISTER_LOADING", loading);
  },
  register({ dispatch, getters }, payload) {
    const companyName = payload.companyName;
    const street = payload.street;
    const city = payload.city;
    const postalCode = payload.postalCode;
    const country = payload.country;
    const state = payload.state;
    const phone = payload.phone;
    const firstName = payload.firstName;
    const lastName = payload.lastName;
    const email = payload.email;
    const password = payload.password;
    const marketingEmail = payload.marketingEmail;

    const url = config.url() + "Register";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            CompanyName: companyName,
            Street: street,
            City: city,
            PostalCode: postalCode,
            Country: country,
            State: state,
            Phone: phone,
            FirstName: firstName,
            LastName: lastName,
            Email: email,
            Password: password,
            MarketingEmail: marketingEmail,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              dispatch("updateUser", { user: res.User, save: true });
              resolve({ message: res.Description });
            } else {
              dispatch("updateUser", { user: null, save: true });
              reject({ message: res.Description });
            }
          }
        })
        .catch((error) => {
          dispatch("updateUser", { user: null, save: true });
          reject(error);
        });
    });
  },

  // Password Reset

  showPasswordResetDialog({ commit }, show) {
    commit("SHOW_PASSWORD_RESET_DIALOG", show);
  },
  passwordResetLoading({ commit }, loading) {
    commit("PASSWORD_RESET_LOADING", loading);
  },
  passwordReset({ dispatch, getters }, payload) {
    const email = payload.email;

    const url = config.url() + "PasswordReset";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            Email: email,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              dispatch("updateUser", { user: null, save: true });
              resolve({ message: res.Description });
            } else {
              dispatch("updateUser", { user: null, save: true });
              reject({ message: res.Description });
            }
          }
        })
        .catch((error) => {
          dispatch("updateUser", { user: null, save: true });
          reject(error);
        });
    });
  },

  // Update Password

  updatePassword({ dispatch, getters }, payload) {
    const password = payload.password;
    const accessToken = payload.accessToken;

    const url = config.url() + "UpdatePassword";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            AccessToken: accessToken,
            Password: password,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              dispatch("updateUser", { user: null, save: true });
              resolve({ message: res.Description });
            } else {
              dispatch("updateUser", { user: null, save: true });
              reject({ message: res.Description });
            }
          }
        })
        .catch((error) => {
          dispatch("updateUser", { user: null, save: true });
          reject(error);
        });
    });
  },

  emailVerification({ dispatch, getters }, payload) {
    const verificationCode = payload.verificationCode;
    const lang = payload.lang;

    const url = config.url() + "UpdateUserEmailVerified";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    const headers = api.headers(getters);
    headers["Base-Locale"] = lang != null ? lang : "";

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            UUID: verificationCode,
          },
          {
            headers: headers,
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ code: res.Code, message: res.Description });
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // User

  getUser({ commit, dispatch, getters }) {
    if (!getters.user) {
      return;
    }

    const userID = getters.user.userID;
    //console.log("userID = " + userID);

    return new Promise((resolve, reject) => {
      const url = config.url() + "GetUser/" + userID;

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            //console.log(res.User);

            if (res.Code == returnCode.Success) {
              commit("SET_USER", res.User);

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_USER", null);

              let message;
              dispatch("getTranslation", "usersNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_USER", null);

          dispatch("getTranslation", "errorGettingUsers").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error getting users: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting users: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },

  getUserOnEdit({ dispatch, getters },payload) {
    if (!payload) {
      return;
    }

    let userID =0;

    if (payload && payload.userID !=undefined) {
        userID = payload.userID;
    }
   
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetUser/" + userID;

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;
            
            if (res.Code == returnCode.Success) {
              resolve({ code: res.Code, message: res.Description, user : res.User });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              let message;
              dispatch("getTranslation", "usersNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          

          dispatch("getTranslation", "errorGettingUsers").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error getting users: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting users: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  updateUser({ commit, dispatch }, payload) {
    commit("SET_USER", payload.user);
    commit("UPDATE_TOOLBAR_NAVIGATION_BY_USER");

    if (payload.save) {
      dispatch("saveSettings", { key: "user", value: payload.user });
    }
  },

  // Token

  setToken({ commit }, payload) {
    commit("SET_TOKEN", payload);
  },
  updateToken({ commit, dispatch }, response) {
    let token = "";

    if (
      response != null &&
      response.headers != null &&
      response.headers.token != null &&
      response.headers.token != ""
    ) {
      token = response.headers.token;
      dispatch("saveSettings", { key: "token", value: token });
      commit("SET_TOKEN", token);
    }
  },

  // Axios

  setAxiosSource({ commit }, payload) {
    commit("SET_AXIOS_SOURCE", payload);
  },
  cancelRequest({ commit, getters }) {
    if (getters.axiosSource) {
      commit("CANCEL_REQUEST");
    }
  },

  // Users
  getDashboardUsers({ commit, dispatch, getters }) {
    
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetDashboardUsers/_/_/_/_";
      try {
        axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_USERS", res.UsersList);

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_USERS", null);

              let message;
              dispatch("getTranslation", "usersNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_USERS", null);

          dispatch("getTranslation", "errorGettingUsers").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
               // console.error("Error getting users: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
               // console.error("Error getting users: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
      } catch (exp) {
         // eslint-disable-next-line
        console.error(exp)
      }
   
    });
  },

  getUsers({ commit, dispatch, getters }) {
    
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetUsers/_/_/_/_";
      try {
        axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_USERS", res.UsersList);

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_USERS", null);

              let message;
              dispatch("getTranslation", "usersNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_USERS", null);

          dispatch("getTranslation", "errorGettingUsers").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
               // console.error("Error getting users: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
               // console.error("Error getting users: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
      } catch (exp) {
         // eslint-disable-next-line
        console.error(exp)
      }
   
    });
  },

  updateDbUser({ commit, dispatch, getters }, payload) {
    if (!payload) {
      return;
    }

    const userID = payload.userID;
    const marketingEmail = payload.marketingEmail;
    const technicalGroup = payload.technicalGroup;
    const adminGroup = payload.adminGroup;

    const url = config.url() + "UpdateUser";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            UserID: userID,
            MarketingEmail: marketingEmail,
            TechnicalGroup: technicalGroup,
            AdminGroup: adminGroup,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("UPDATE_DB_USER", res.User);
              commit("RELOAD_USERS_COMPONENT");

              resolve({ code: res.Code, message: res.Description });
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateUserAdmin({ commit, dispatch, getters }, payload) {
    if (!payload) {
      return;
    }

    const user = payload.item;
    const userID = user.userID;
    const admin = payload.admin;

    if (
      userID == null ||
      userID == undefined ||
      admin == null ||
      admin == undefined
    ) {
      return;
    }

    const url = config.url() + "UpdateUserAdmin/" + userID + "/" + admin;

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              user.admin = admin;
              commit("UPDATE_DB_USER", user);
              commit("RELOAD_USERS_COMPONENT");

              resolve({ code: res.Code, message: res.Description });
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateUserActive({ commit, dispatch, getters }, payload) {
    if (!payload) {
      return;
    }

    const user = payload.item;
    const userID = user.userID;
    const active = payload.active;

    if (
      userID == null ||
      userID == undefined ||
      active == null ||
      active == undefined
    ) {
      return;
    }

    const url = config.url() + "UpdateUserActive/" + userID + "/" + active;

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              user.active = active;
              commit("UPDATE_DB_USER", user);
              commit("RELOAD_USERS_COMPONENT");

              resolve({ code: res.Code, message: res.Description });
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  deleteDbUser({ commit, dispatch, getters }, payload) {
    if (!payload) {
      return;
    }

    const user = payload;
    const userID = user.userID;

    if (userID == null || userID == undefined) {
      return;
    }

    const url = config.url() + "DeleteAccount/" + userID;

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("DELETE_DB_USER", userID);
              commit("RELOAD_USERS_COMPONENT");

              resolve({ code: res.Code, message: res.Description });
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  sendEmailVerification({ commit, dispatch, getters }, payload) {
    if (!payload) {
      return;
    }

    const user = payload.item;
    const userID = user.userID;

    if (userID == null || userID == undefined) {
      return;
    }

    const url = config.url() + "SendEmailVerification/" + userID;

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ code: res.Code, message: res.Description });
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateAccount({ commit, dispatch, getters }, payload) {
    if (!payload) {
      return;
    }

    const userID = payload.userID;
    const companyName = payload.companyName;
    const street = payload.street;
    const city = payload.city;
    const state = payload.state;
    const postalCode = payload.postalCode;
    const country = payload.country;
    const phone = payload.phone;
    const firstName = payload.firstName;
    const lastName = payload.lastName;
    const marketingEmail = payload.marketingEmail;

    const DistributorfirstName= payload.DistributorfirstName;
    const DistributorlastName= payload.DistributorlastName;
    const DistributorEmail= payload.DistributorEmail;

    const LENOXcontactfirstName= payload.LENOXcontactfirstName;
    const LENOXcontactlastName= payload.LENOXcontactlastName;
    const LENOXcontactEmail= payload.LENOXcontactEmail;

    const url = config.url() + "UpdateAccount";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            UserID: userID,
            CompanyName: companyName,
            Street: street,
            City: city,
            State: state,
            PostalCode: postalCode,
            Country: country,
            Phone: phone,
            FirstName: firstName,
            LastName: lastName,
            MarketingEmail: marketingEmail,
            DistributorfirstName: DistributorfirstName,
            DistributorlastName: DistributorlastName,
            DistributorEmail: DistributorEmail,
            LENOXcontactfirstName: LENOXcontactfirstName,
            LENOXcontactlastName: LENOXcontactlastName,
            LENOXcontactEmail: LENOXcontactEmail,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("UPDATE_ACCOUNT", res.User);

              resolve({ code: res.Code, message: res.Description });
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  deleteAccount({ commit, dispatch, getters }, payload) {
    if (!payload) {
      return;
    }

    const user = payload;
    const userID = user.userID;

    if (userID == null || userID == undefined) {
      return;
    }

    const url = config.url() + "DeleteAccount/" + userID;

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              dispatch("deleteDB");

              dispatch("logoff").then(() => {
                resolve({ code: res.Code, message: res.Description });
              });
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  deleteDB({ dispatch }) {
    const req = indexedDB.deleteDatabase("SawCalcDB");
    req.onsuccess = function() {
      // eslint-disable-next-line
      //console.log("Deleted database successfully");
    };
    req.onerror = function() {
      // eslint-disable-next-line
      //console.log("Couldn't delete database");
    };
    req.onblocked = function() {
      // eslint-disable-next-line
      //console.log("Couldn't delete database due to the operation being blocked" );
    };
  },
  getCountries({ commit, dispatch, getters }) {
    dispatch("registerLoading", true);

    return new Promise((resolve, reject) => {
      const url = config.url() + "GetCountryList/_/_";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          dispatch("registerLoading", false);
          dispatch("updateToken", response);
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_COUNTRIES", res.CountryList);

              dispatch("saveSettings", {
                key: "countries",
                value: getters.countries,
              });

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_COUNTRIES", null);

              dispatch("saveSettings", {
                key: "countries",
                value: null,
              });

              let message;
              dispatch("getTranslation", "statsNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          dispatch("registerLoading", false);
          commit("SET_COUNTRIES", null);

          dispatch("saveSettings", {
            key: "countries",
            value: null,
          });

          dispatch("getTranslation", "errorGettingStats").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error getting stats: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting stats: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  reloadUserLinksComponent({ commit }) {
    commit("RELOAD_USER_LINKS_COMPONENT");
  },
  updateUserLinks({ commit, dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const parentID = payload.parentID;

    const list = payload.list.map((o) => ({
      UserLinkID: o.userLinkID,
      ParentID: o.parentID,
      UserID: o.userID,
      LinkName: o.linkName,
    }));

    const url = config.url() + "UpdateUserLinks";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            List: list,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("UPDATE_USER_LINKS", {
                parentID: parentID,
                list: res.UserLinkList,
              });

              resolve({ code: res.Code, message: res.Description });
            } else {
              dispatch("getTranslation", "errorUpdatingUserLinks").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorUpdatingUserLinks").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error updating user links: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error updating user links: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },

  // Stats

  getStats({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetStats/_/_/_";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_STATS", res.StatsList);

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_STATS", null);

              let message;
              dispatch("getTranslation", "statsNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_STATS", null);

          dispatch("getTranslation", "errorGettingStats").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error getting stats: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting stats: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },

  // UI

  showSawCalcDrawer({ commit }, show) {
    commit("SHOW_SAW_CALC_DRAWER", show);
  },
  checkInputs({ dispatch, getters }) {
    let valid = false;

    if (
      getters.inputMachine.manufacturerName != null &&
      getters.inputMaterial.designation != null &&
      getters.inputDimensions.shape != null &&
      getters.inputVising.arrangement != null &&
      getters.inputConditions.lubrication != null
    ) {
      valid = true;
    }

    if (!valid) {
      setTimeout(() => dispatch("showSawCalcDrawer", true), 500);
      setTimeout(() => dispatch("showSawCalcDrawer", false), 1000);
    }
  },
  getImages({ commit, dispatch, getters }) {
    const url = config.url() + "GetImagesList";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    axios
      .create()
      .get(url, {
        headers: api.headers(getters),
        cancelToken: getters.axiosSource.token,
      })
      .then((response) => {
        const error = api.responseError(response);

        if (error) {
          if (api.authenticationError(error.Code)) {
            dispatch("logoff");
          }
        } else {
          const res = response.data;

          if (res.Code == returnCode.Success) {
            commit("GET_IMAGES", res.ImagesList);

            const homeCover = getters.images.home.cover;

            if (homeCover.fileName) {
              dispatch("getImageSource", {
                id: "homeCover",
                image: homeCover,
              });
            }
          }
        }
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error("Error getting images: " + error);
      });
  },
  getImageSource({ commit, dispatch }, payload) {
    const id = payload.id;
    const image = payload.image;

    const fileName = image.fileName;

    if (!fileName) {
      return;
    }

    const url = config.url() + "GetImage/" + fileName;

    axios
      .create()
      .get(url)
      .then((response) => {
        const error = api.responseError(response.headers);

        if (error) {
          if (api.authenticationError(error.Code)) {
            dispatch("logoff");
          }

          commit("SET_IMAGE_SOURCE", { id: id, image: null });
        } else {
          const res = response.data;

          const base64string = imageConversion.arrayBufferToBase64(res);

          commit("SET_IMAGE_SOURCE", {
            id: id,
            image: image,
            data: base64string,
          });
        }
      })
      .catch(() => {
        // eslint-disable-next-line
        console.error("Error getting image");
      });
  },
  updateImage({ commit, dispatch, getters }, payload) {
    const page = payload.page;
    const name = payload.name;
    const file = payload.file;
    const fileName = file.name;

    const url =
      config.url() + "UpdateAppFormImage/" + page + "/" + name + "/" + fileName;

    const form = new FormData();
    form.append("image", file);

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(url, form, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data.UpdateAppFormImageResult;

            if (res.Code == returnCode.Success) {
              const reader = new FileReader();
              reader.onloadend = () => {
                let base64string = reader.result;

                commit("UPDATE_IMAGE", {
                  page: page,
                  name: name,
                  fileName: fileName,
                  data: base64string,
                });

                resolve({ code: res.Code, message: res.Description });
              };
              reader.readAsDataURL(file);
            } else {
              commit("UPDATE_IMAGE", {
                page: page,
                name: name,
                fileName: fileName,
                data: null,
              });

              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  imageLoading({ commit }, payload) {
    const page = payload.page;
    const name = payload.name;
    const loading = payload.loading;

    commit("IMAGE_LOADING", { page: page, name: name, loading: loading });
  },
  showDataTableSettingsDialog({ commit }, show) {
    commit("SHOW_DATA_TABLE_SETTINGS_DIALOG", show);
  },
  showDataTableUOMSettingsDialog({ commit }, show) {
    commit("SHOW_DATA_TABLE_UOM_SETTINGS_DIALOG", show);
  },

  // Machines

  saveMachinesAdminSettings({ commit, dispatch, getters }, payload) {
    commit("SET_MACHINES_ADMIN_SETTINGS", payload);

    dispatch("saveSettings", {
      key: "machinesAdminSettings",
      value: getters.machinesAdminSettings,
    });
  },
  reloadMachinesAdminSettingsComponent({ commit }) {
    commit("RELOAD_DATA_TABLE_UOM_SETTINGS_COMPONENT");
  },
  saveMachinesUserSettings({ commit, dispatch, getters }, payload) {
    commit("SET_MACHINES_USER_SETTINGS", payload);

    dispatch("saveSettings", {
      key: "machinesUserSettings",
      value: getters.machinesUserSettings,
    });
  },
  reloadMachinesUserSettingsComponent({ commit }) {
    commit("RELOAD_DATA_TABLE_UOM_SETTINGS_COMPONENT");
  },

  // Materials

  saveMaterialsAdminSettings({ commit, dispatch, getters }, payload) {
    commit("SET_MATERIALS_ADMIN_SETTINGS", payload);

    dispatch("saveSettings", {
      key: "materialsAdminSettings",
      value: getters.materialsAdminSettings,
    });
  },
  reloadMaterialsAdminSettingsComponent({ commit }) {
    commit("RELOAD_DATA_TABLE_SETTINGS_COMPONENT");
  },
  saveMaterialsUserSettings({ commit, dispatch, getters }, payload) {
    commit("SET_MATERIALS_USER_SETTINGS", payload);

    dispatch("saveSettings", {
      key: "materialsUserSettings",
      value: getters.materialsUserSettings,
    });
  },
  reloadMaterialsUserSettingsComponent({ commit }) {
    commit("RELOAD_DATA_TABLE_SETTINGS_COMPONENT");
  },
  reloadMaterialDialogComponent({ commit }) {
    commit("RELOAD_MATERIAL_DIALOG_COMPONENT");
  },
  reloadChemicalCompositionsDialogComponent({ commit }) {
    commit("RELOAD_CHEMICAL_COMPOSITIONS_DIALOG_COMPONENT");
  },

  // ProductLines

  saveProductLinesSettings({ commit, dispatch, getters }, payload) {
    commit("SET_PRODUCT_LINES_SETTINGS", payload);

    dispatch("saveSettings", {
      key: "productLinesSettings",
      value: getters.productLinesSettings,
    });
  },
  reloadProductLinesSettingsComponent({ commit }) {
    commit("RELOAD_DATA_TABLE_SETTINGS_COMPONENT");
  },
  reloadProductLineDialogComponent({ commit }) {
    commit("RELOAD_PRODUCT_LINES_DIALOG_COMPONENT");
  },

  // DictionaryUI

  // eslint-disable-next-line no-unused-vars
  getDictionaryUISections({ commit, dispatch, getters }, locale) {
    return new Promise((resolve, reject) => {
      if (!locale) {
        let message;
        dispatch("getTranslation", "languageIsRequired").then(
          (response) => {
            message = response;
            reject(message);
          },
          (error) => {
            // eslint-disable-next-line
            console.error(error);
          }
        );

        return;
      }

      const url = config.url() + "GetDictionaryUISections/" + locale;

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description, items: res.List });
            } else {
              dispatch("getTranslation", "errorGettingSections").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error("Error getting sections: " + error);

          dispatch("getTranslation", "errorGettingSections").then(
            (response) => {
              reject({ code: returnCode.ErrBadRequest, message: response });
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
              reject({ code: returnCode.ErrNetworkError, message: error });
            }
          );
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  getDictionaryUI({ commit, dispatch, getters }, payload) {
    const locale = payload.locale;
    const section = payload.section;

    return new Promise((resolve, reject) => {
      if (!locale) {
        let message;
        dispatch("getTranslation", "languageIsRequired").then(
          (response) => {
            message = response;
            reject(message);
          },
          (error) => {
            // eslint-disable-next-line
            console.error(error);
          }
        );

        return;
      }
      if (!section) {
        let message;
        dispatch("getTranslation", "sectionIsRequired").then(
          (response) => {
            message = response;
            reject(message);
          },
          (error) => {
            // eslint-disable-next-line
            console.error(error);
          }
        );

        return;
      }

      const url = config.url() + "GetDictionaryUI/" + locale + "/" + section;

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description, item: res.DictionaryUI });
            } else {
              dispatch("getTranslation", "errorGettingContent").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error("Error getting content: " + error);

          dispatch("getTranslation", "errorGettingContent").then(
            (response) => {
              reject({ code: returnCode.ErrBadRequest, message: response });
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
              reject({ code: returnCode.ErrNetworkError, message: error });
            }
          );
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  updateDictionaryUI({ commit, dispatch, getters }, payload) {
    const id = payload.id;
    const jsonContent = payload.jsonContent;

    const url = config.url() + "UpdateDictionaryUI/" + id;

    const form = new FormData();
    form.append("jsonContent", jsonContent);

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(url, form, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data.UpdateDictionaryUIResult;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description, item: res.DictionaryUI });
            } else {
              reject({ message: res.Description });
            }
          }
        })
        .catch((error) => {
          reject(error.message);
          reject({ code: returnCode.ErrNetworkError, message: error });
        });
    });
  },

  // DictionaryWS

  // eslint-disable-next-line no-unused-vars
  getDictionaryWSSections({ commit, dispatch, getters }, locale) {
    return new Promise((resolve, reject) => {
      if (!locale) {
        let message;
        dispatch("getTranslation", "languageIsRequired").then(
          (response) => {
            message = response;
            reject(message);
          },
          (error) => {
            // eslint-disable-next-line
            console.error(error);
          }
        );

        return;
      }

      const url = config.url() + "GetDictionaryWSSections/" + locale;

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description, items: res.List });
            } else {
              dispatch("getTranslation", "errorGettingSections").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error("Error getting sections: " + error);

          dispatch("getTranslation", "errorGettingSections").then(
            (response) => {
              reject({ code: returnCode.ErrBadRequest, message: response });
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
              reject({ code: returnCode.ErrNetworkError, message: error });
            }
          );
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  getDictionaryWS({ commit, dispatch, getters }, payload) {
    const locale = payload.locale;
    const section = payload.section;

    return new Promise((resolve, reject) => {
      if (!locale) {
        let message;
        dispatch("getTranslation", "languageIsRequired").then(
          (response) => {
            message = response;
            reject(message);
          },
          (error) => {
            // eslint-disable-next-line
            console.error(error);
          }
        );

        return;
      }
      if (!section) {
        let message;
        dispatch("getTranslation", "sectionIsRequired").then(
          (response) => {
            message = response;
            reject(message);
          },
          (error) => {
            // eslint-disable-next-line
            console.error(error);
          }
        );

        return;
      }

      const url = config.url() + "GetDictionaryWS/" + locale + "/" + section;

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description, item: res.DictionaryWS });
            } else {
              dispatch("getTranslation", "errorGettingContent").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error("Error getting content: " + error);

          dispatch("getTranslation", "errorGettingContent").then(
            (response) => {
              reject({ code: returnCode.ErrBadRequest, message: response });
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
              reject({ code: returnCode.ErrNetworkError, message: error });
            }
          );
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  updateDictionaryWS({ commit, dispatch, getters }, payload) {
    const id = payload.id;
    const content = payload.content;

    const url = config.url() + "UpdateDictionaryWS/" + id;

    const form = new FormData();
    form.append("content", content);

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(url, form, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data.UpdateDictionaryWSResult;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description, item: res.DictionaryWS });
            } else {
              reject({ message: res.Description });
            }
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },

  // DictionaryStatic

  // eslint-disable-next-line no-unused-vars
  getDictionaryPages({ commit, dispatch, getters }, locale) {
    return new Promise((resolve, reject) => {
      if (!locale) {
        let message;
        dispatch("getTranslation", "languageIsRequired").then(
          (response) => {
            message = response;
            reject(message);
          },
          (error) => {
            // eslint-disable-next-line
            console.error(error);
          }
        );

        return;
      }

      const url = config.url() + "GetDictionaryPages/" + locale;

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description, items: res.List });
            } else {
              dispatch("getTranslation", "errorGettingPages").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error("Error getting pages: " + error);

          dispatch("getTranslation", "errorGettingPages").then(
            (response) => {
              reject({ code: returnCode.ErrBadRequest, message: response });
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
              reject({ code: returnCode.ErrNetworkError, message: error });
            }
          );
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  getDictionaryStatic({ commit, dispatch, getters }, payload) {
    const locale = payload.locale;
    const page = payload.page;

    return new Promise((resolve, reject) => {
      if (!locale) {
        let message;
        dispatch("getTranslation", "languageIsRequired").then(
          (response) => {
            message = response;
            reject(message);
          },
          (error) => {
            // eslint-disable-next-line
            console.error(error);
          }
        );

        return;
      }
      if (!page) {
        let message;
        dispatch("getTranslation", "pageIsRequired").then(
          (response) => {
            message = response;
            reject(message);
          },
          (error) => {
            // eslint-disable-next-line
            console.error(error);
          }
        );

        return;
      }

      const url = config.url() + "GetDictionaryStatic/" + locale + "/" + page;

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description, item: res.DictionaryStatic });
            } else {
              dispatch("getTranslation", "errorGettingContent").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error("Error getting content: " + error);

          dispatch("getTranslation", "errorGettingContent").then(
            (response) => {
              reject({ code: returnCode.ErrBadRequest, message: response });
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
              reject({ code: returnCode.ErrNetworkError, message: error });
            }
          );
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  updateDictionaryStatic({ commit, dispatch, getters }, payload) {
    const id = payload.id;
    const title = payload.title;
    const videos = payload.videos;
    const content = payload.content;

    const url = config.url() + "UpdateDictionaryStatic/" + id;

    const form = new FormData();
    form.append("title", title);
    form.append("videos", videos);
    form.append("content", content);

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(url, form, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data.UpdateDictionaryStaticResult;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description, item: res.DictionaryStatic });
            } else {
              reject({ message: res.Description });
            }
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },

  // Admin

  // Machines

  getAdminMachines({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetMachines/_/_/_/_/1/1";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_MACHINES_ADMIN", res.MachinesList);

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_MACHINES_ADMIN", null);

              let message;
              dispatch("getTranslation", "machinesNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_MACHINES_ADMIN", null);

          dispatch("getTranslation", "errorGettingMachines").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error getting machines: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting machines: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  async createMachine({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const machine = await dispatch("getMachineFromInput", {
      machine: payload.machine,
      uom: payload.uom,
    });
    const url = config.url() + "CreateMachine";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            ManufacturerName: machine.manufacturerName,
            Model: machine.model,
            BladeWidthIn: machine.bladeWidthIn,
            BladeWidthFr: machine.bladeWidthFr,
            BladeWidthMetric: machine.bladeWidthMetric,
            BladeThicknessImperial: machine.bladeThicknessImperial,
            BladeThicknessMetric: machine.bladeThicknessMetric,
            BladeLengthFt: machine.bladeLengthFt,
            BladeLengthIn: machine.bladeLengthIn,
            BladeLengthFr: machine.bladeLengthFr,
            BladeLengthImperial: machine.bladeLengthImperial,
            BladeLengthMetric: machine.bladeLengthMetric,
            Hortizontal: machine.hortizontal,
            ScissorPivot: machine.scissorPivot,
            CantedHead: machine.cantedHead,
            BandSpeedMinImperial: machine.bandSpeedMinImperial,
            BandSpeedMaxImperial: machine.bandSpeedMaxImperial,
            BandSpeedMinMetric: machine.bandSpeedMinMetric,
            BandSpeedMaxMetric: machine.bandSpeedMaxMetric,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.Machine;

              dispatch("getMachineFromResponse", item).then((response) => {
                resolve({ message: res.Description, machine: response });
              });
            } else if (res.Code == returnCode.ErrRecordExists) {
              dispatch("getTranslation", "machineExists").then(
                (response) => {
                  reject({ code: res.Code, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              dispatch("getTranslation", "errorCreatingMachine").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorCreatingMachine").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error creating machine: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error creating machine: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  async updateMachine({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const machine = await dispatch("getMachineFromInput", {
      machine: payload.machine,
      uom: payload.uom,
    });
    
    const url = config.url() + "UpdateMachine";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            MachineID: machine.machineID,
            ManufacturerName: machine.manufacturerName,
            Model: machine.model,
            BladeWidthIn: machine.bladeWidthIn,
            BladeWidthFr: machine.bladeWidthFr,
            BladeWidthMetric: machine.bladeWidthMetric,
            BladeThicknessImperial: machine.bladeThicknessImperial,
            BladeThicknessMetric: machine.bladeThicknessMetric,
            BladeLengthFt: machine.bladeLengthFt,
            BladeLengthIn: machine.bladeLengthIn,
            BladeLengthFr: machine.bladeLengthFr,
            BladeLengthImperial: machine.bladeLengthImperial,
            BladeLengthMetric: machine.bladeLengthMetric,
            Hortizontal: machine.hortizontal,
            ScissorPivot: machine.scissorPivot,
            CantedHead: machine.cantedHead,
            BandSpeedMinImperial: machine.bandSpeedMinImperial,
            BandSpeedMaxImperial: machine.bandSpeedMaxImperial,
            BandSpeedMinMetric: machine.bandSpeedMinMetric,
            BandSpeedMaxMetric: machine.bandSpeedMaxMetric,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.Machine;

              dispatch("getMachineFromResponse", item).then((response) => {
                resolve({ message: res.Description, machine: response });
              });
            } else {
              dispatch("getTranslation", "errorUpdatingMachine").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorUpdatingMachine").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error updating machine: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error updating machine: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  deleteMachine({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const machineID = payload.machine.machineID;

    const url = config.url() + "DeleteMachine/" + machineID;

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else {
              dispatch("getTranslation", "errorDeletingMachine").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorDeletingMachine").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error deleting machine: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error deleting machine: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },

  // Materials

  async getAdminMatrialsData({ commit, dispatch, getters }) {
    commit("SET_MATERIALS_LOADING", true);

    const tasks = [...getters.materialTasks];

    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];

      let promise;
      try {
        promise = await dispatch(task.function, task.payload);

        if (
          promise.code != returnCode.Success &&
          promise.code != returnCode.ErrRecordNotFound
        ) {
          let p = new Promise(function(resolve, reject) {
            let message;
            dispatch("getTranslation", "errorNotLoaded").then(
              (response) => {
                message = task.description + " " + response;

                const notification = {
                  show: true,
                  result: false,
                  message: message,
                };

                dispatch("showSnackbar", notification);
                commit("SET_MATERIALS_LOADING", false);
                reject({ code: promise.Code, message: promise.Description });
              },
              (error) => {
                // eslint-disable-next-line
                console.error(error);
              }
            );
          });

          return p;
        }
      } catch (error) {
        let p = new Promise(function(resolve, reject) {
          let message;
          dispatch("getTranslation", "errorNotLoaded").then(
            (response) => {
              message = task.description + " " + response;

              const notification = {
                show: true,
                result: false,
                message: message,
              };

              dispatch("showSnackbar", notification);
              commit("SET_MATERIALS_LOADING", false);

              reject(error);
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });

        return p;
      }
    }

    commit("SET_MATERIALS_ADMIN");
    commit("SET_MATERIALS_LOADING", false);

    let p = new Promise(function(resolve, reject) {
      dispatch("getTranslation", "materialsUpdated").then(
        (response) => {
          resolve({ message: response });
        },
        (error) => {
          reject(error);
        }
      );
    });

    return p;
  },
  getAdminMaterials({ commit, dispatch, getters }) {
    commit("SET_MATERIALS_LOADING", true);

    return new Promise((resolve, reject) => {
      const url = config.url() + "GetMaterials/1/1";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_MATERIALS", res.MaterialsList);
              commit("SET_MATERIALS_ADMIN");
              commit("SET_MATERIALS_LOADING", false);

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_MATERIALS", null);
              commit("SET_MATERIALS_ADMIN");
              commit("SET_MATERIALS_LOADING", false);

              let message;
              dispatch("getTranslation", "materialsNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_MATERIALS", null);
          commit("SET_MATERIALS_ADMIN");
          commit("SET_MATERIALS_LOADING", false);

          dispatch("getTranslation", "errorGettingMaterials").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting materials: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting materials: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  createMaterialStandard({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const id = payload.id;
    const value = payload.value;
    const orderInList = payload.orderInList;

    const url = config.url() + "CreateMaterialStandard";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            ID: id,
            Value: value,
            OrderInList: orderInList,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else if (res.Code == returnCode.ErrRecordExists) {
              dispatch("getTranslation", "itemExists").then(
                (response) => {
                  reject({ code: res.Code, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              dispatch("getTranslation", "errorCreatingItem").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorCreatingItem").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error creating item: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error creating item: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  updateMaterialStandard({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const id = payload.id;
    const value = payload.value;
    const orderInList = payload.orderInList;

    const url = config.url() + "UpdateMaterialStandard";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            ID: id,
            Value: value,
            OrderInList: orderInList,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else {
              dispatch("getTranslation", "errorUpdatingItem").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorUpdatingItem").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error updating item: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error updating item: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  createUNSCategory({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const id = payload.id;
    const value = payload.value;
    const orderInList = payload.orderInList;

    const url = config.url() + "CreateUNSCategory";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            ID: id,
            Value: value,
            OrderInList: orderInList,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else if (res.Code == returnCode.ErrRecordExists) {
              dispatch("getTranslation", "itemExists").then(
                (response) => {
                  reject({ code: res.Code, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              dispatch("getTranslation", "errorCreatingItem").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorCreatingItem").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error creating item: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error creating item: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  updateUNSCategory({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const id = payload.id;
    const value = payload.value;
    const orderInList = payload.orderInList;

    const url = config.url() + "UpdateUNSCategory";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            ID: id,
            Value: value,
            OrderInList: orderInList,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else {
              dispatch("getTranslation", "errorUpdatingItem").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorUpdatingItem").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error updating item: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error updating item: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  createUNSAlloyGroup({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const id = payload.id;
    const value = payload.value;
    const orderInList = payload.orderInList;

    const url = config.url() + "CreateUNSAlloyGroup";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            ID: id,
            Value: value,
            OrderInList: orderInList,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else if (res.Code == returnCode.ErrRecordExists) {
              dispatch("getTranslation", "itemExists").then(
                (response) => {
                  reject({ code: res.Code, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              dispatch("getTranslation", "errorCreatingItem").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorCreatingItem").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error creating item: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error creating item: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  updateUNSAlloyGroup({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const id = payload.id;
    const value = payload.value;
    const orderInList = payload.orderInList;

    const url = config.url() + "UpdateUNSAlloyGroup";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            ID: id,
            Value: value,
            OrderInList: orderInList,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else {
              dispatch("getTranslation", "errorUpdatingItem").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorUpdatingItem").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error updating item: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error updating item: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  createSawCalcGroup({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const sawCalcGroupID = payload.sawCalcGroupID;
    const sawCalcGroupName = payload.sawCalcGroupName;
    const sawCalcGroupDescription = payload.sawCalcGroupDescription;
    const machinabilityGroupBiMetal = payload.machinabilityGroupBiMetal;
    const machinabilityGroupCarbide = payload.machinabilityGroupCarbide;
    const machinabilityRating = parseFloat(payload.machinabilityRating);
    const defaultHBHardness = payload.defaultHBHardness;
    const orderInList = payload.orderInList;

    const url = config.url() + "CreateSawCalcGroup";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            SawCalcGroupID: sawCalcGroupID,
            SawCalcGroupName: sawCalcGroupName,
            SawCalcGroupDescription: sawCalcGroupDescription,
            MachinabilityGroupBiMetal: machinabilityGroupBiMetal,
            MachinabilityGroupCarbide: machinabilityGroupCarbide,
            MachinabilityRating: machinabilityRating,
            DefaultHBHardness: defaultHBHardness,
            OrderInList: orderInList,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else if (res.Code == returnCode.ErrRecordExists) {
              dispatch("getTranslation", "itemExists").then(
                (response) => {
                  reject({ code: res.Code, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              dispatch("getTranslation", "errorCreatingItem").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorCreatingItem").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error creating item: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error creating item: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  updateSawCalcGroup({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const sawCalcGroupID = payload.sawCalcGroupID;
    const sawCalcGroupName = payload.sawCalcGroupName;
    const sawCalcGroupDescription = payload.sawCalcGroupDescription;
    const machinabilityGroupBiMetal = payload.machinabilityGroupBiMetal;
    const machinabilityGroupCarbide = payload.machinabilityGroupCarbide;
    const machinabilityRating = parseFloat(payload.machinabilityRating);
    const defaultHBHardness = payload.defaultHBHardness;
    const orderInList = payload.orderInList;

    const url = config.url() + "UpdateSawCalcGroup";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            SawCalcGroupID: sawCalcGroupID,
            SawCalcGroupName: sawCalcGroupName,
            SawCalcGroupDescription: sawCalcGroupDescription,
            MachinabilityGroupBiMetal: machinabilityGroupBiMetal,
            MachinabilityGroupCarbide: machinabilityGroupCarbide,
            MachinabilityRating: machinabilityRating,
            DefaultHBHardness: defaultHBHardness,
            OrderInList: orderInList,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else {
              dispatch("getTranslation", "errorUpdatingItem").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorUpdatingItem").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error updating item: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error updating item: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  createUNSGeneralDescription({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const id = payload.id;
    const value = payload.value;
    const orderInList = payload.orderInList;

    const url = config.url() + "CreateUNSGeneralDescription";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            ID: id,
            Value: value,
            OrderInList: orderInList,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else if (res.Code == returnCode.ErrRecordExists) {
              dispatch("getTranslation", "itemExists").then(
                (response) => {
                  reject({ code: res.Code, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              dispatch("getTranslation", "errorCreatingItem").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorCreatingItem").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error creating item: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error creating item: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  updateUNSGeneralDescription({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const id = payload.id;
    const value = payload.value;
    const orderInList = payload.orderInList;

    const url = config.url() + "UpdateUNSGeneralDescription";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            ID: id,
            Value: value,
            OrderInList: orderInList,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else {
              dispatch("getTranslation", "errorUpdatingItem").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorUpdatingItem").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error updating item: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error updating item: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  createSubCategory({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const id = payload.id;
    const value = payload.value;
    const orderInList = payload.orderInList;

    const url = config.url() + "CreateSubCategory";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            ID: id,
            Value: value,
            OrderInList: orderInList,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else if (res.Code == returnCode.ErrRecordExists) {
              dispatch("getTranslation", "itemExists").then(
                (response) => {
                  reject({ code: res.Code, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              dispatch("getTranslation", "errorCreatingItem").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorCreatingItem").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error creating item: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error creating item: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  updateSubCategory({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const id = payload.id;
    const value = payload.value;
    const orderInList = payload.orderInList;

    const url = config.url() + "UpdateSubCategory";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            ID: id,
            Value: value,
            OrderInList: orderInList,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else {
              dispatch("getTranslation", "errorUpdatingItem").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorUpdatingItem").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error updating item: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error updating item: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  createMaterial({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const id = payload.id;
    const designation = payload.designation;
    const materialStandardID = payload.materialStandardID;
    const uns = payload.uns;
    const unsCategoryID = payload.unsCategoryID;
    const unsAlloyGroupID = payload.unsAlloyGroupID;
    const sawCalcGroupID = payload.sawCalcGroupID;
    const compositeUNS = payload.compositeUNS;
    const unsGeneralDescriptionID = payload.unsGeneralDescriptionID;
    const subCategoryID = payload.subCategoryID;
    const quickSearch = payload.quickSearch;
    const orderInList = payload.orderInList;

    const url = config.url() + "CreateMaterial";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            MaterialID: id,
            Designation: designation,
            MaterialStandardID: materialStandardID,
            UNS: uns,
            UNSCategoryID: unsCategoryID,
            UNSAlloyGroupID: unsAlloyGroupID,
            SawCalcGroupID: sawCalcGroupID,
            CompositeUNS: compositeUNS,
            UNSGeneralDescriptionID: unsGeneralDescriptionID,
            SubCategoryID: subCategoryID,
            QuickSearch: quickSearch,
            OrderInList: orderInList,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else if (res.Code == returnCode.ErrRecordExists) {
              dispatch("getTranslation", "itemExists").then(
                (response) => {
                  reject({ code: res.Code, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              dispatch("getTranslation", "errorCreatingItem").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorCreatingItem").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error creating item: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error creating item: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  updateMaterial({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const id = payload.id;
    const designation = payload.designation;
    const materialStandardID = payload.materialStandardID;
    const uns = payload.uns;
    const unsCategoryID = payload.unsCategoryID;
    const unsAlloyGroupID = payload.unsAlloyGroupID;
    const sawCalcGroupID = payload.sawCalcGroupID;
    const compositeUNS = payload.compositeUNS;
    const unsGeneralDescriptionID = payload.unsGeneralDescriptionID;
    const subCategoryID = payload.subCategoryID;
    const quickSearch = payload.quickSearch;
    const orderInList = payload.orderInList;

    const url = config.url() + "UpdateMaterial";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            MaterialID: id,
            Designation: designation,
            MaterialStandardID: materialStandardID,
            UNS: uns,
            UNSCategoryID: unsCategoryID,
            UNSAlloyGroupID: unsAlloyGroupID,
            SawCalcGroupID: sawCalcGroupID,
            CompositeUNS: compositeUNS,
            UNSGeneralDescriptionID: unsGeneralDescriptionID,
            SubCategoryID: subCategoryID,
            QuickSearch: quickSearch,
            OrderInList: orderInList,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else {
              dispatch("getTranslation", "errorUpdatingItem").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorUpdatingItem").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error updating item: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error updating item: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  deleteMaterial({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const materialID = payload.material.id;

    const url = config.url() + "DeleteMaterial/" + materialID;

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else {
              dispatch("getTranslation", "errorDeletingMaterial").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorDeletingMaterial").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error deleting material: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error deleting material: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  materialsCleanup({ dispatch, getters }) {
    if (!getters.user) {
      return;
    }

    const url = config.url() + "MaterialsCleanup";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else {
              dispatch("getTranslation", "errorMaterialsCleanup").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorMaterialsCleanup").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error materials cleanup: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error materials cleanup: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getChemicalCompositions({ dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      const materialID = payload;

      const url = config.url() + "GetChemicalCompositions/" + materialID;

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const list = res.ChemicalCompositionList;

              let chemicalCompositions = list.map((o) => ({
                materialElementID: o.MaterialElementID,
                materialID: o.MaterialID,
                elementID: o.ElementID,
                name: o.Name,
                symbol: o.Symbol,
                minPercent: o.MinPercent,
                maxPercent: o.MaxPercent,
                orderInList: o.OrderInList,
                elementDescription: o.Symbol + " - " + o.Name,
              }));

              resolve({
                code: res.Code,
                message: res.Description,
                list: chemicalCompositions,
              });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              let message;
              dispatch("getTranslation", "chemicalCompositionsNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorGettingChemicalCompositions").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting chemical compositions: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting chemical compositions: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  updateChemicalCompositions({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const materialID = payload.id;

    const list = payload.list.map((o) => ({
      MaterialElementID: o.materialElementID,
      MaterialID: o.materialID,
      ElementID: o.elementID,
      MinPercent: o.minPercent,
      MaxPercent: o.maxPercent,
    }));

    const url = config.url() + "UpdateChemicalCompositions";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            MaterialID: materialID,
            List: list,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ code: res.Code, message: res.Description });
            } else {
              dispatch(
                "getTranslation",
                "errorUpdatingChemicalCompositions"
              ).then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorUpdatingChemicalCompositions").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error updating chemical compositions: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error updating chemical compositions: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },

  // ProductLines

  getProductLines({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetProductLines/_/_";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_PRODUCT_LINES", res.ProductLinesList);

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_PRODUCT_LINES", null);

              let message;
              dispatch("getTranslation", "productLinesNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_PRODUCT_LINES", null);

          dispatch("getTranslation", "errorGettingProductLines").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting productLines: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting productLines: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  createProductLine({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const productLine = payload.productLine;

    const url = config.url() + "CreateProductLine";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            ProductFamily: productLine.productFamily,
            ProductType: productLine.productType,
            PitchLevel: productLine.pitchLevel,
            TPI: productLine.tpi,
            AveragePitch: productLine.averagePitch,
            AverageGulletArea: productLine.averageGulletArea,
            GulletFill: productLine.gulletFill,
            BWL1: productLine.bwl1,
            BWL2: productLine.bwl2,
            BWL3: productLine.bwl3,
            BWL4: productLine.bwl4,
            BWL5: productLine.bwl5,
            BWL6: productLine.bwl6,
            BWL7: productLine.bwl7,
            BWL8: productLine.bwl8,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.ProductLine;

              resolve({ message: res.Description, productLine: item });
            } else if (res.Code == returnCode.ErrRecordExists) {
              dispatch("getTranslation", "productLineExists").then(
                (response) => {
                  reject({ code: res.Code, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              dispatch("getTranslation", "errorCreatingProductLine").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorCreatingProductLine").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error creating productLine: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error creating productLine: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  updateProductLine({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const productLine = payload.productLine;

    const url = config.url() + "UpdateProductLine";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            ProductLineID: productLine.productLineID,
            ProductFamily: productLine.productFamily,
            ProductType: productLine.productType,
            PitchLevel: productLine.pitchLevel,
            TPI: productLine.tpi,
            AveragePitch: productLine.averagePitch,
            AverageGulletArea: productLine.averageGulletArea,
            GulletFill: productLine.gulletFill,
            BWL1: productLine.bwl1,
            BWL2: productLine.bwl2,
            BWL3: productLine.bwl3,
            BWL4: productLine.bwl4,
            BWL5: productLine.bwl5,
            BWL6: productLine.bwl6,
            BWL7: productLine.bwl7,
            BWL8: productLine.bwl8,
            Active: productLine.active,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.ProductLine;

              resolve({ message: res.Description, productLine: item });
            } else {
              dispatch("getTranslation", "errorUpdatingProductLine").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorUpdatingProductLine").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error updating productLine: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error updating productLine: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  deleteProductLine({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const productLineID = payload.productLine.productLineID;

    const url = config.url() + "DeleteProductLine/" + productLineID;

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else {
              dispatch("getTranslation", "errorDeletingProductLine").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorDeletingProductLine").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error deleting productLine: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error deleting productLine: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },

  // ProductTypes

  getProductTypes({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetProductTypes/1/1";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_PRODUCT_TYPES", res.ProductTypesList);

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_PRODUCT_TYPES", null);

              let message;
              dispatch("getTranslation", "productTypesNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_PRODUCT_TYPES", null);

          dispatch("getTranslation", "errorGettingProductTypes").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting productTypes: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting productTypes: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  createProductType({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const productType = payload;

    const url = config.url() + "CreateProductType";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            ProductTypeName: productType.productTypeName,
            ProductTypeDescription: productType.productTypeDescription,
            OrderInList: productType.orderInList,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.ProductType;

              resolve({ message: res.Description, productType: item });
            } else if (res.Code == returnCode.ErrRecordExists) {
              dispatch("getTranslation", "productTypeExists").then(
                (response) => {
                  reject({ code: res.Code, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              dispatch("getTranslation", "errorCreatingProductType").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorCreatingProductType").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error creating productType: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error creating productType: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  updateProductType({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const productType = payload;

    const url = config.url() + "UpdateProductType";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            ProductTypeID: productType.productTypeID,
            ProductTypeName: productType.productTypeName,
            ProductTypeDescription: productType.productTypeDescription,
            OrderInList: productType.orderInList,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.ProductType;

              resolve({ message: res.Description, productType: item });
            } else {
              dispatch("getTranslation", "errorUpdatingProductType").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorUpdatingProductType").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error updating productType: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error updating productType: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },

  // Recommendations

  getAdminRecommendations({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetRecommendationDownloads";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit(
                "SET_RECOMMENDATIONS_ADMIN",
                res.RecommendationDownloadsList
              );

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_RECOMMENDATIONS_ADMIN", null);

              let message;
              dispatch("getTranslation", "recommendationsNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_RECOMMENDATIONS_ADMIN", null);

          dispatch("getTranslation", "errorGettingRecommendations").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting recommendations: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting recommendations: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  saveAdminRecommendationsSettings({ commit, dispatch, getters }, payload) {
    commit("SET_RECOMMENDATIONS_ADMIN_SETTINGS", payload);

    dispatch("saveSettings", {
      key: "recommendationsAdminSettings",
      value: getters.recommendationsAdminSettings,
    });
  },
  reloadAdminRecommendationsSettingsComponent({ commit }) {
    commit("RELOAD_DATA_TABLE_SETTINGS_COMPONENT");
  },
  saveRecommendationDownloads({ commit, dispatch, getters }) {
    if (!getters.user) {
      return;
    }

    const numberOfRecords = 5000;

    const url = config.url() + "SaveRecommendationDownloads/" + numberOfRecords;

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    commit("SET_SAW_CALC_LOADING", true);

    return new Promise((resolve, reject) => {
      axios.create();
      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
          responseType: "blob",
        })
        .then((response) => {
          commit("SET_SAW_CALC_LOADING", false);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            const fileName = calculations.downloadFileName(
              "Recommendations History",
              ".csv"
            );

            const fileURL = window.URL.createObjectURL(new Blob([res]));

            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(fileURL, fileName);
            } else {
              const fileLink = document.createElement("a");
              fileLink.href = fileURL;
              fileLink.setAttribute("download", fileName);

              document.body.appendChild(fileLink);

              fileLink.click();
              document.body.removeChild(fileLink);
            }

            let message;
            dispatch("getTranslation", "reportCreated").then(
              (response) => {
                message = response;
                resolve({ code: returnCode.Success, message: message });
              },
              (error) => {
                // eslint-disable-next-line
                console.error(error);
              }
            );
          }
        })
        .catch((error) => {
          commit("SET_SAW_CALC_LOADING", false);

          reject(error);
        });
    });
  },
  deleteRecommendationDownloads({ dispatch, getters }) {
    if (!getters.user) {
      return;
    }
    const numberOfRecords = 5000;

    const url =
      config.url() + "DeleteRecommendationDownloads/" + numberOfRecords;

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else {
              dispatch("getTranslation", "errorDeletingRecords").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorDeletingRecords").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error deleting records: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error deleting records: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },

  // Parameters

  getParameters({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetParameters";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_PARAMETERS", res.ParametersList);

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_PARAMETERS", null);

              let message;
              dispatch("getTranslation", "parametersNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_PARAMETERS", null);

          dispatch("getTranslation", "errorGettingParameters").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting parameters: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting parameters: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  updateParameter({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const parameter = payload;

    const url = config.url() + "UpdateParameter";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            ParameterID: parameter.parameterID,
            ParameterName: parameter.parameterName,
            ParameterValue: parameter.parameterValue,
            ParameterDescription: parameter.parameterDescription,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.ProductType;

              resolve({ message: res.Description, productType: item });
            } else {
              dispatch("getTranslation", "errorUpdatingParameter").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorUpdatingParameter").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error updating parameter: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error updating parameter: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getProductSelections({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetProductSelections";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_PRODUCT_SELECTIONS", res.ProductSelectionsList);

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_PRODUCT_SELECTIONS", null);

              let message;
              dispatch("getTranslation", "productSelectionsNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_PRODUCT_SELECTIONS", null);

          dispatch("getTranslation", "errorGettingProductSelections").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting productSelections: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting productSelections: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  updateProductSelection({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const productSelection = payload;

    const url = config.url() + "UpdateProductSelection";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            ProductSelectionID: productSelection.productSelectionID,
            ApplicationScenario: productSelection.applicationScenario,
            Good: productSelection.good,
            Better: productSelection.better,
            Best: productSelection.best,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.ProductType;

              resolve({ message: res.Description, productType: item });
            } else {
              dispatch("getTranslation", "errorUpdatingProductSelection").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorUpdatingProductSelection").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error updating productSelection: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error updating productSelection: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getProductRecommendations({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      const url = config.url() + "GetProductRecommendations";

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_PRODUCT_RECOMMENDATIONS", res.ProductRecommendationsList);

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_PRODUCT_RECOMMENDATIONS", null);

              let message;
              dispatch("getTranslation", "productRecommendationsNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_PRODUCT_RECOMMENDATIONS", null);

          dispatch("getTranslation", "errorGettingProductRecommendations").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting product recommendations: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting product recommendations: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  updateProductRecommendation({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const productRecommendation = payload;

    const url = config.url() + "UpdateProductRecommendation";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            ProductRecommendationID: productRecommendation.productRecommendationID,
            MRG: productRecommendation.mrg,
            ColumnIndex: productRecommendation.columnIndex,
            ColumnName: productRecommendation.columnName,
            ProductType: productRecommendation.productType,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.ProductType;

              resolve({ message: res.Description, productType: item });
            } else {
              dispatch("getTranslation", "errorUpdatingProductRecommendation").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorUpdatingProductRecommendation").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error updating product recommendation: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error updating product recommendation: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },

  // User

  // Logo

  getUserLogoSource({ commit, dispatch }, payload) {
    const fileName = payload.fileName;

    if (!fileName) {
      return;
    }

    const url = config.url() + "GetImage/" + fileName;

    axios
      .create()
      .get(url)
      .then((response) => {
        const error = api.responseError(response.headers);

        if (error) {
          if (api.authenticationError(error.Code)) {
            dispatch("logoff");
          }

          commit("SET_USER_LOGO_URL", null);
        } else {
          const res = response.data;

          const base64string = imageConversion.arrayBufferToBase64(res);

          commit("SET_USER_LOGO_URL", base64string);
        }
      })
      .catch(() => {
        commit("SET_USER_LOGO_URL", null);

        // eslint-disable-next-line
        console.error("Error getting image");
      });
  },
  updateUserLogo({ commit, dispatch, getters }, payload) {
    const userID = getters.user.userID;
    const imageID = payload.imageID;
    const file = payload.file;
    const fileName = file.name;

    const url =
      config.url() +
      "UpdateUserLogo/" +
      userID +
      "/" +
      imageID +
      "/" +
      fileName;

    const form = new FormData();
    form.append("image", file);

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(url, form, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data.UpdateUserLogoResult;

            if (res.Code == returnCode.Success) {
              let imageID = null;
              let fileName = "";

              if (res.ImageID) {
                imageID = parseInt(res.ImageID);
              }

              if (res.FileName) {
                fileName = res.FileName;
              }

              const reader = new FileReader();
              reader.onloadend = () => {
                let base64string = reader.result;

                commit("SET_USER_LOGO_SOURCE", {
                  url: base64string,
                  imageID: imageID,
                  fileName: fileName,
                });

                dispatch("saveSettings", {
                  key: "user",
                  value: getters.user,
                });

                resolve({ code: res.Code, message: res.Description });
              };
              reader.readAsDataURL(file);
            } else {
              commit("SET_USER_LOGO_SOURCE", {
                data: null,
              });

              dispatch("saveSettings", {
                key: "user",
                value: getters.user,
              });

              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_USER_LOGO_SOURCE", {
            data: null,
          });

          dispatch("saveSettings", {
            key: "user",
            value: getters.user,
          });

          reject(error.message);
        });
    });
  },
  removeUserLogo({ commit, dispatch, getters }) {
    const userID = getters.user.userID;

    const url = config.url() + "RemoveUserLogo/" + userID;

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_USER_LOGO_SOURCE", {
                data: null,
              });

              dispatch("saveSettings", {
                key: "user",
                value: getters.user,
              });

              resolve({ code: res.Code, message: res.Description });
            } else {
              commit("SET_USER_LOGO_SOURCE", {
                data: null,
              });

              dispatch("saveSettings", {
                key: "user",
                value: getters.user,
              });

              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_USER_LOGO_SOURCE", {
            data: null,
          });

          dispatch("saveSettings", {
            key: "user",
            value: getters.user,
          });

          reject(error.message);
        });
    });
  },

  // Machines

  async createUserMachine({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const machine = await dispatch("getMachineFromInput", {
      machine: payload.machine,
      uom: payload.uom,
    });
    const url = config.url() + "CreateMachine";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            ManufacturerName: machine.manufacturerName,
            Model: machine.model,
            BladeWidthIn: machine.bladeWidthIn,
            BladeWidthFr: machine.bladeWidthFr,
            BladeWidthMetric: machine.bladeWidthMetric,
            BladeThicknessImperial: machine.bladeThicknessImperial,
            BladeThicknessMetric: machine.bladeThicknessMetric,
            BladeLengthFt: machine.bladeLengthFt,
            BladeLengthIn: machine.bladeLengthIn,
            BladeLengthFr: machine.bladeLengthFr,
            BladeLengthImperial: machine.bladeLengthImperial,
            BladeLengthMetric: machine.bladeLengthMetric,
            Hortizontal: machine.hortizontal,
            ScissorPivot: machine.scissorPivot,
            CantedHead: machine.cantedHead,
            BandSpeedMinImperial: machine.bandSpeedMinImperial,
            BandSpeedMaxImperial: machine.bandSpeedMaxImperial,
            BandSpeedMinMetric: machine.bandSpeedMinMetric,
            BandSpeedMaxMetric: machine.bandSpeedMaxMetric,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.Machine;

              dispatch("getMachineFromResponse", item).then((response) => {
                resolve({ message: res.Description, machine: response });
              });
            } else if (res.Code == returnCode.ErrRecordExists) {
              dispatch("getTranslation", "machineExists").then(
                (response) => {
                  reject({ code: res.Code, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              dispatch("getTranslation", "errorCreatingMachine").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorCreatingMachine").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error creating machine: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error creating machine: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  async updateUserMachine({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const machine = await dispatch("getMachineFromInput", {
      machine: payload.machine,
      uom: payload.uom,
    });
    machine.userMachineID = payload.machine.userMachineID;
    machine.machineName = payload.machine.machineName;

   

    const url = config.url() + "UpdateUserMachine";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            UserMachineID: machine.userMachineID,
            MachineName: machine.machineName,
            ManufacturerName: machine.manufacturerName,
            Model: machine.model,
            BladeWidthIn: machine.bladeWidthIn,
            BladeWidthFr: machine.bladeWidthFr,
            BladeWidthMetric: machine.bladeWidthMetric,
            BladeThicknessImperial: machine.bladeThicknessImperial,
            BladeThicknessMetric: machine.bladeThicknessMetric,
            BladeLengthFt: machine.bladeLengthFt,
            BladeLengthIn: machine.bladeLengthIn,
            BladeLengthFr: machine.bladeLengthFr,
            BladeLengthImperial: machine.bladeLengthImperial,
            BladeLengthMetric: machine.bladeLengthMetric,
            Hortizontal: machine.hortizontal,
            ScissorPivot: machine.scissorPivot,
            CantedHead: machine.cantedHead,
            BandSpeedMinImperial: machine.bandSpeedMinImperial,
            BandSpeedMaxImperial: machine.bandSpeedMaxImperial,
            BandSpeedMinMetric: machine.bandSpeedMinMetric,
            BandSpeedMaxMetric: machine.bandSpeedMaxMetric,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.UserMachine;

              dispatch("getMachineFromResponse", item).then((response) => {
                response.userMachineID = item.UserMachineID;
                response.machineName = item.MachineName;

                resolve({ message: res.Description, machine: response });
              });
            } else {
              dispatch("getTranslation", "errorUpdatingMachine").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorUpdatingMachine").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error updating machine: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error updating machine: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  deleteUserMachine({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const machineID = payload.machine.machineID;

    const url = config.url() + "DeleteMachine/" + machineID;

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else {
              dispatch("getTranslation", "errorDeletingMachine").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorDeletingMachine").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error("Error deleting machine: " + error.response.data);
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error deleting machine: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  getUserMachineImages({ dispatch }, payload) {
    const list = payload.list;

    list.forEach(async (item) => {
      await dispatch("getUserMachineImageSource", {
        id: item.userMachineID,
        fileName: item.fileName,
      });
    });
  },
  getUserMachineImageSource({ commit, dispatch }, payload) {
    const id = payload.id;
    const fileName = payload.fileName;

    if (!fileName) {
      return;
    }

    const url = config.url() + "GetImage/" + fileName;

    axios
      .create()
      .get(url)
      .then((response) => {
        const error = api.responseError(response.headers);

        if (error) {
          if (api.authenticationError(error.Code)) {
            dispatch("logoff");
          }

          commit("SET_USER_MACHINE_IMAGE_SOURCE", { id: id, data: null });
        } else {
          const res = response.data;

          const base64string = imageConversion.arrayBufferToBase64(res);

          commit("SET_USER_MACHINE_IMAGE_SOURCE", {
            id: id,
            data: base64string,
          });
        }
      })
      .catch(() => {
        commit("SET_USER_MACHINE_IMAGE_SOURCE", { id: id, data: null });

        // eslint-disable-next-line
        console.error("Error getting image");
      });
  },
  updateUserMachineImage({ commit, dispatch, getters }, payload) {
    const userID = getters.user.userID;
    const userMachineID = payload.userMachineID;
    const imageID = payload.imageID;
    const file = payload.file;
    const fileName = file.name;

    const url =
      config.url() +
      "UpdateUserMachineImage/" +
      userID +
      "/" +
      userMachineID +
      "/" +
      imageID +
      "/" +
      fileName;

    const form = new FormData();
    form.append("image", file);

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(url, form, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data.UpdateUserMachineImageResult;

            if (res.Code == returnCode.Success) {
              let imageID = null;

              if (res.ImageID) {
                imageID = parseInt(res.ImageID);
              }

              const reader = new FileReader();
              reader.onloadend = () => {
                let base64string = reader.result;

                commit("SET_USER_MACHINE_IMAGE_SOURCE", {
                  id: userMachineID,
                  url: base64string,
                  imageID: imageID,
                });

                resolve({ code: res.Code, message: res.Description });
              };
              reader.readAsDataURL(file);
            } else {
              commit("SET_USER_MACHINE_IMAGE_SOURCE", {
                id: userMachineID,
                data: null,
              });

              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_USER_MACHINE_IMAGE_SOURCE", {
            id: userMachineID,
            data: null,
          });

          reject(error.message);
        });
    });
  },
  removeUserMachineImage({ commit, dispatch, getters }, payload) {
    const userID = getters.user.userID;
    const userMachineID = payload.userMachineID;

    const url =
      config.url() + "RemoveUserMachineImage/" + userID + "/" + userMachineID;

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_USER_MACHINE_IMAGE_SOURCE", {
                id: userMachineID,
                data: null,
              });

              resolve({ code: res.Code, message: res.Description });
            } else {
              commit("SET_USER_MACHINE_IMAGE_SOURCE", {
                id: userMachineID,
                data: null,
              });

              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_USER_MACHINE_IMAGE_SOURCE", {
            id: userMachineID,
            data: null,
          });

          reject(error.message);
        });
    });
  },

  // Materials

  getMachineFromInput({ getters }, payload) {
    const machine = payload.machine;
    const uom = payload.uom;

    if (machine == null || uom == null) {
      return;
    }

    const machineID = machine.machineID;
    const manufacturerID = machine.manufacturerID;
    const manufacturerName = machine.manufacturerName;
    const model = machine.model;

    let bladeWidthIn = 0;
    let bladeWidthFr = null;
    let bladeWidthMetric = 0;
    let bladeThicknessImperial = 0;
    let bladeThicknessMetric = 0;
    let bladeLengthFt = 0;
    let bladeLengthIn = null;
    let bladeLengthFr = null;
    let bladeLengthImperial = 0;
    let bladeLengthMetric = 0;

    const hortizontal = machine.machineOrientation == 0;
    const scissorPivot = machine.scissorPivot != 1 ? false : true;
    const cantedHead = machine.cantedHead !=1 ? false : true;

    let bandSpeedMinImperial = 0;
    let bandSpeedMaxImperial = 0;
    let bandSpeedMinMetric = 0;
    let bandSpeedMaxMetric = 0;

    if (uom == 0) {
      if (machine.bladeWidthImperial) {
        bladeWidthIn = parseInt(machine.bladeWidthImperial.value);
        bladeWidthFr = machine.bladeWidthImperial.value - bladeWidthIn;

        const bladeWidth = calculations.getBladeWidth(machine, false, getters.bladeWidthImperialList, getters.bladeWidthMetricList);
        bladeWidthMetric = bladeWidth.mm;
      }

      bladeThicknessImperial = machine.bladeThicknessImperial;

      const bladeThickness = calculations.getBladeThickness(machine, false, getters.bladeThicknessImperialList, getters.bladeThicknessMetricList);
      bladeThicknessMetric= bladeThickness.mm;

      bladeLengthFt = parseInt(machine.bladeLengthFt);

      if (machine.bladeLengthIn != null) {
        bladeLengthIn = parseInt(machine.bladeLengthIn);
      }

      if (machine.bladeLengthFr) {
        bladeLengthFr = machine.bladeLengthFr.value;
      }

      bladeLengthImperial = calculations.lengthImperial(
        bladeLengthFt,
        bladeLengthIn,
        bladeLengthFr
      );

      const bladeLength = calculations.getBladeLength(machine, false)
      bladeLengthMetric = bladeLength.mm;
      bladeLengthMetric = bladeLengthMetric ? parseInt(bladeLengthMetric) : 0;

      bandSpeedMinImperial = parseInt(machine.bandSpeedMinImperial);
      bandSpeedMaxImperial = parseInt(machine.bandSpeedMaxImperial);

      bandSpeedMinMetric = parseInt(calculations.fpmToMpm(bandSpeedMinImperial));
      bandSpeedMaxMetric = parseInt(calculations.fpmToMpm(bandSpeedMaxImperial));

    } else {
      bladeWidthMetric = machine.bladeWidthMetric;
      bladeThicknessMetric = machine.bladeThicknessMetric;
      bladeLengthMetric = parseInt(machine.bladeLengthMetric);
      bandSpeedMinMetric = parseInt(machine.bandSpeedMinMetric);
      bandSpeedMaxMetric = parseInt(machine.bandSpeedMaxMetric);

      const bladeWidth = calculations.getBladeWidth(machine, true, getters.bladeWidthImperialList, getters.bladeWidthMetricList);
      bladeWidthIn = bladeWidth.in;
      bladeWidthFr = bladeWidth.fr;

      const bladeThickness = calculations.getBladeThickness(machine, true, getters.bladeThicknessImperialList, getters.bladeThicknessMetricList);
      bladeThicknessImperial = bladeThickness.in;

      const bladeLength = calculations.getBladeLength(machine, true);
      bladeLengthImperial = bladeLength.imperial;
      bladeLengthFt = bladeLength.ft;
      bladeLengthIn = bladeLength.in;
      bladeLengthFr = bladeLength.fr;
      
      bandSpeedMinImperial = calculations.mpmToFpm(machine.bandSpeedMinMetric);
      bandSpeedMaxImperial = calculations.mpmToFpm(machine.bandSpeedMaxMetric);
    }

    return new Promise((resolve) => {
      const item = {
        machineID: machineID,
        manufacturerID: manufacturerID,
        manufacturerName: manufacturerName,
        model: model,
        bladeWidthIn: bladeWidthIn,
        bladeWidthFr: bladeWidthFr,
        bladeWidthMetric: bladeWidthMetric,
        bladeThicknessImperial: bladeThicknessImperial,
        bladeThicknessMetric: bladeThicknessMetric,
        bladeLengthFt: bladeLengthFt,
        bladeLengthIn: bladeLengthIn,
        bladeLengthFr: bladeLengthFr,
        bladeLengthImperial: bladeLengthImperial,
        bladeLengthMetric: bladeLengthMetric,
        hortizontal: hortizontal,
        scissorPivot: scissorPivot,
        cantedHead: cantedHead,
        bandSpeedMinImperial: bandSpeedMinImperial,
        bandSpeedMaxImperial: bandSpeedMaxImperial,
        bandSpeedMinMetric: bandSpeedMinMetric,
        bandSpeedMaxMetric: bandSpeedMaxMetric,
      };

      resolve(item);
    });
  },

  // UserRecommendations

  getUserRecommendations({ commit, dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    let userID = payload.userID;

    if (!userID) {
      userID = getters.user.userID;
    }

    return new Promise((resolve, reject) => {
      const url = config.url() + "GetUserRecommendations/" + userID;

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_USER_RECOMMENDATIONS", res.UserRecommendationsList);

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_USER_RECOMMENDATIONS", null);

              let message;
              dispatch("getTranslation", "userRecommendationsNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_USER_RECOMMENDATIONS", null);

          dispatch("getTranslation", "errorGettingUserRecommendations").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting userRecommendations: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting userRecommendations: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  createUserRecommendation({ commit, dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const recommendation = payload.recommendation;
    const custom = payload.custom;
    const costCalculation = payload.costCalculation;

    if (costCalculation != undefined && costCalculation != null) {
      const measurement = costCalculation.measurement;

      if (measurement != undefined && measurement != null) {
        if (
          measurement.measurementImperial == undefined ||
          measurement.measurementImperial == null
        ) {
          measurement.measurementImperial = 0;
        }

        if (
          measurement.measurementMetric == undefined ||
          measurement.measurementMetric == null
        ) {
          measurement.measurementMetric = 0;
        }
      }
    }

    const input = JSON.parse(JSON.stringify(getters.input));
    delete input.material.chemicalComposition;

    const now = new Date();
    const today =
      now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();
    const recommendationTitle = recommendation.title + " " + today;

    const url = config.url() + "CreateUserRecommendation";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    commit("SET_SAW_CALC_LOADING", true);

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            UserID: getters.user.userID,
            UserMachineID: input.machine.userMachineID,
            UserMaterialID: input.material.userMaterialID,
            RecommendationName: recommendationTitle,
            Input: JSON.stringify(input),
            Recommendation: JSON.stringify(recommendation),
            Custom: JSON.stringify(custom),
            CostCalculation: JSON.stringify(costCalculation),
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          commit("SET_SAW_CALC_LOADING", false);

          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.UserRecommendation;

              resolve({ message: res.Description, userRecommendation: item });
            } else {
              dispatch(
                "getTranslation",
                "errorCreatingUserRecommendation"
              ).then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          commit("SET_SAW_CALC_LOADING", false);

          dispatch("getTranslation", "errorCreatingUserRecommendation").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error creating userRecommendation: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error creating userRecommendation: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  updateUserRecommendation({ commit, dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const userRecommendationID = payload.userRecommendationID;
    const recommendationName = payload.recommendationName;
    const input = payload.input;
    const recommendation = payload.recommendation;
    const custom = payload.custom;
    const costCalculation = payload.costCalculation;

    delete input.material.chemicalComposition;

    const url = config.url() + "UpdateUserRecommendation";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    commit("SET_SAW_CALC_LOADING", true);

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            UserRecommendationID: userRecommendationID,
            UserID: getters.user.userID,
            UserMachineID: input.machine.userMachineID,
            UserMaterialID: input.material.userMaterialID,
            RecommendationName: recommendationName,
            Input: JSON.stringify(input),
            Recommendation: JSON.stringify(recommendation),
            Custom: JSON.stringify(custom),
            CostCalculation: JSON.stringify(costCalculation),
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          commit("SET_SAW_CALC_LOADING", false);

          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.UserRecommendation;

              resolve({ message: res.Description, userRecommendation: item });
            } else {
              dispatch(
                "getTranslation",
                "errorUpdatingUserRecommendation"
              ).then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          commit("SET_SAW_CALC_LOADING", false);

          dispatch("getTranslation", "errorUpdatingUserRecommendation").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error updating userRecommendation: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error updating userRecommendation: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  deleteUserRecommendation({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const userRecommendationID = payload.userRecommendationID;

    const url =
      config.url() + "DeleteUserRecommendation/" + userRecommendationID;

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else {
              dispatch(
                "getTranslation",
                "errorDeletingUserRecommendation"
              ).then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorDeletingUserRecommendation").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error deleting userRecommendation: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error deleting userRecommendation: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  saveRecommendationsUserSettings({ commit, dispatch, getters }, payload) {
    commit("SET_RECOMMENDATIONS_USER_SETTINGS", payload);

    dispatch("saveSettings", {
      key: "recommendationsUserSettings",
      value: getters.recommendationsUserSettings,
    });
  },
  reloadUserRecommendationsSettingsComponent({ commit }) {
    commit("RELOAD_DATA_TABLE_SETTINGS_COMPONENT");
  },
  openRecommendationReport({ commit, dispatch, getters }, payload) {
    const userRecommendation = payload;

    const input = JSON.parse(JSON.stringify(userRecommendation.input));
    delete input.material.chemicalComposition;

    let recommendation = JSON.parse(
      JSON.stringify(userRecommendation.recommendation)
    );

    const custom = userRecommendation.custom;

    if (
      custom != undefined &&
      custom != null &&
      custom.recommendation != undefined &&
      custom.recommendation != null
    ) {
      recommendation = custom.recommendation;
    }

    const sawCalcOutput = [
      {
        Recommendation: recommendation,
        CostCalculation: userRecommendation.costCalculation,
      },
    ];

    const obj = {
      Input: input,
      SawCalcOutput: sawCalcOutput,
    };

    const url = config.url() + "GetRecommendationReport";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    commit("SET_SAW_CALC_LOADING", true);

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            Json: JSON.stringify(obj),
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
            responseType: "blob",
          }
        )
        .then((response) => {
          commit("SET_SAW_CALC_LOADING", false);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            const file = new Blob([res], { type: "application/pdf" });
            const objectURL = URL.createObjectURL(file);

            window.open(objectURL);
            URL.revokeObjectURL(file);

            let message;
            dispatch("getTranslation", "reportCreated").then(
              (response) => {
                message = response;
                resolve({ code: returnCode.Success, message: message });
              },
              (error) => {
                // eslint-disable-next-line
                console.error(error);
              }
            );
          }
        })
        .catch((error) => {
          commit("SET_SAW_CALC_LOADING", false);

          reject(error);
        });
    });
  },
  emailRecommendationSavedReport({ commit, dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const userRecommendation = payload;

    const input = JSON.parse(JSON.stringify(userRecommendation.input));
    delete input.material.chemicalComposition;

    const sawCalcOutput = [
      {
        Recommendation: userRecommendation.recommendation,
        CostCalculation: userRecommendation.costCalculation,
      },
    ];

    const obj = {
      Input: input,
      SawCalcOutput: sawCalcOutput,
    };

    const url = config.url() + "EmailRecommendationReport";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    commit("SET_SAW_CALC_LOADING", true);

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            Json: JSON.stringify(obj),
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          commit("SET_SAW_CALC_LOADING", false);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ code: res.Code, message: res.Description });
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_SAW_CALC_LOADING", false);

          reject(error);
        });
    });
  },

  // CuttingCharts

  getCuttingCharts({ commit, dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    let userID = payload.userID;

    if (!userID) {
      userID = getters.user.userID;
    }

    return new Promise((resolve, reject) => {
      const url = config.url() + "GetCuttingCharts/" + userID;

      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
        })
        .then((response) => {
          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              commit("SET_CUTTING_CHARTS", res.CuttingChartsList);

              resolve({ code: res.Code, message: res.Description });
            } else if (res.Code == returnCode.ErrRecordNotFound) {
              commit("SET_CUTTING_CHARTS", null);

              let message;
              dispatch("getTranslation", "cuttingChartsNotFound").then(
                (response) => {
                  message = response;
                  resolve({ code: res.Code, message: message });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            } else {
              reject({ code: res.Code, message: res.Description });
            }
          }
        })
        .catch((error) => {
          commit("SET_CUTTING_CHARTS", null);

          dispatch("getTranslation", "errorGettingCuttingCharts").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error getting cuttingCharts: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error getting cuttingCharts: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  updateCuttingChart({ commit, dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const cuttingChartID = payload.cuttingChartID;
    const cuttingChartName = payload.cuttingChartName;
    const userID = payload.userID;
    const metric = payload.metric;
    const machine = payload.machine;
    const productImperial = payload.productImperial;
    const productMetric = payload.productMetric;
    const dimensionMin = payload.dimensionMin;
    const dimensionMax = payload.dimensionMax;
    const dimensionRange = payload.dimensionRange;
    const cuttingChartMaterialGroups = payload.cuttingChartMaterialGroups;

    const url = config.url() + "UpdateCuttingChart";

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    commit("SET_SAW_CALC_LOADING", true);

    return new Promise((resolve, reject) => {
      axios
        .create()
        .post(
          url,
          {
            CuttingChartID: cuttingChartID,
            CuttingChartName: cuttingChartName,
            UserID: userID,
            Metric: metric,
            Machine: machine,
            ProductImperial: productImperial,
            ProductMetric: productMetric,
            DimensionMin: dimensionMin,
            DimensionMax: dimensionMax,
            DimensionRange: dimensionRange,
            CuttingChartMaterialGroups: cuttingChartMaterialGroups,
          },
          {
            headers: api.headers(getters),
            cancelToken: getters.axiosSource.token,
          }
        )
        .then((response) => {
          commit("SET_SAW_CALC_LOADING", false);

          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              const item = res.CuttingChart;

              resolve({ message: res.Description, cuttingChart: item });
            } else {
              dispatch("getTranslation", "errorUpdatingCuttingChart").then(
                (response) => {
                  reject({
                    code: returnCode.ErrBadRequest,
                    message: response,
                  });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          commit("SET_SAW_CALC_LOADING", false);

          dispatch("getTranslation", "errorUpdatingCuttingChart").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error updating cuttingChart: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error updating cuttingChart: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  deleteCuttingChart({ dispatch, getters }, payload) {
    if (!getters.user) {
      return;
    }

    const cuttingChartID = payload.cuttingChartID;

    const url = config.url() + "DeleteCuttingChart/" + cuttingChartID;

    dispatch("cancelRequest");
    dispatch("setAxiosSource", axios.CancelToken.source());

    return new Promise((resolve, reject) => {
      axios
        .create()
        .get(url, {
          headers: api.headers(getters),
          cancelToken: getters.axiosSource.token,
        })
        .then((response) => {
          dispatch("updateToken", response);

          const error = api.responseError(response);

          if (error) {
            if (api.authenticationError(error.Code)) {
              dispatch("logoff");
            }

            reject({ code: error.Code, message: error.Description });
          } else {
            const res = response.data;

            if (res.Code == returnCode.Success) {
              resolve({ message: res.Description });
            } else {
              dispatch("getTranslation", "errorDeletingCuttingChart").then(
                (response) => {
                  reject({ code: returnCode.ErrBadRequest, message: response });
                },
                (error) => {
                  // eslint-disable-next-line
                  console.error(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          dispatch("getTranslation", "errorDeletingCuttingChart").then(
            (response) => {
              if (error.response) {
                // eslint-disable-next-line
                console.error(
                  "Error deleting cuttingChart: " + error.response.data
                );
                reject({ code: error.response.status, message: response });
              } else {
                // eslint-disable-next-line
                console.error("Error deleting cuttingChart: " + error);
                reject({ code: returnCode.ErrNetworkError, message: error });
              }
            },
            (error) => {
              // eslint-disable-next-line
              console.error(error);
            }
          );
        });
    });
  },
  saveCuttingChartsSettings({ commit, dispatch, getters }, payload) {
    commit("SET_CUTTING_CHARTS_SETTINGS", payload);

    dispatch("saveSettings", {
      key: "cuttingChartsSettings",
      value: getters.cuttingChartsSettings,
    });
  },
  reloadCuttingChartsSettingsComponent({ commit }) {
    commit("RELOAD_DATA_TABLE_SETTINGS_COMPONENT");
  },

  // Snackbar
  showSnackbar({ commit }, payload) {
    const show = payload.show;
    const result = payload.result;
    const message = payload.message;
    const delay = payload.delay;

    commit("SHOW_SNACKBAR", {
      show: show,
      result: result,
      message: message,
      delay: delay,
    });
  },
  closeSnackbar({ commit }) {
    commit("SHOW_SNACKBAR", {
      show: false,
    });
  },
};
