<template>
  <v-dialog
    v-model="passwordResetDialog.show"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    max-width="400px"
  >
    <v-card>
      <v-card-title class="card-title">
        <h3>
          {{ $t("passwordReset.title") }}
        </h3>
        <v-spacer />
        <span @click="login()">
          <span class="action-link">{{ $t("register.login") }}</span
          >&nbsp;
          <span class="action-link-text">{{ $t("register.instead") }}</span>
        </span>
      </v-card-title>

      <v-form ref="form">
        <v-card-text>
          <v-container class="pb-0">
            <v-row>
              <v-col
                cols="12"
                :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
              >
                <v-text-field
                  autofocus
                  :label="$t('passwordReset.emailLabel')"
                  :disabled="passwordResetDialog.loading"
                  v-model="email"
                  prepend-icon="mdi-email"
                  :rules="inputRules"
                  @keydown.enter="passwordReset()"
                >
                  <v-icon slot="prepend" color="primary lighten-1"
                    >mdi-email</v-icon
                  >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions
          :class="{
            'padding-action-small': $vuetify.breakpoint.smAndDown,
            'padding-action': $vuetify.breakpoint.mdAndUp,
          }"
        >
          <v-container class="px-1 py-0">
            <v-row>
              <v-col cols="12" sm="6" class="pt-0">
                <v-btn
                  depressed
                  tile
                  v-html="$t('passwordReset.cancelButton')"
                  class="primary white--text full-width"
                  @click="cancel"
                ></v-btn>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0">
                <v-btn
                  depressed
                  tile
                  v-html="$t('passwordReset.submitButton')"
                  class="primary white--text full-width"
                  :disabled="passwordResetDialog.loading"
                  :loading="passwordResetDialog.loading"
                  @click="passwordReset"
                ></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

export default {
  name: "Login",

  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      inputRules: [
        (v) => (!!v && v.length > 0) || this.$t("passwordReset.inputRules"),
      ],
    };
  },

  computed: {
    ...mapGetters(["passwordResetDialog", "user", "axiosSource"]),
  },

  methods: {
    cancel() {
      this.$store.dispatch("cancelRequest");
      this.$store.dispatch("passwordResetLoading", false);
      this.$store.dispatch("showPasswordResetDialog", false);
      this.resetForm();
    },
    passwordReset() {
      if (this.$refs.form.validate()) {
        const form = {
          email: this.email,
        };

        this.$store.dispatch("passwordResetLoading", true);

        this.$store.dispatch("passwordReset", form).then(
          (response) => {
            this.$store.dispatch("passwordResetLoading", false);
            this.$store.dispatch("showPasswordResetDialog", false);
            this.resetForm();

            const notification = {
              show: true,
              result: true,
              message: response.message,
              delay: 8000,
            };

            this.$store.dispatch("showSnackbar", notification);

            if (this.user) {
              if (this.user.admin) {
                router.push({ name: "DashboardAdmin" }).catch(() => {});
              } else {
                router.push({ name: "Dashboard" }).catch(() => {});
              }
            }
          },
          (error) => {
            this.$store.dispatch("passwordResetLoading", false);

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }

            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };

            this.$store.dispatch("showSnackbar", notification);

            // eslint-disable-next-line
            console.error("error: ", error);
          }
        );
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
    login() {
      if (this.passwordResetDialog.loading) {
        return;
      }

      this.$store.dispatch("showPasswordResetDialog", false);
      this.$store.dispatch("showLoginDialog", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__title {
  padding: 20px 20px 0px 20px;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 0px 20px 0px 10px;
}

.padding-input {
  padding: 0 10px;
}

.padding-action {
  padding: 0px 20px 20px 20px;
}

.padding-action-small {
  padding: 5px 20px 10px 20px;
}
</style>
