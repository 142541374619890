const navigation = {
  toolbar: {
    Title: 0,
    Phone: 2,
    Language: 3,
    Login: 4,
    Logoff: 5,
  },
  tabs: {
    Home: 0,
    SawCalc: 1,
    BandSawGuide: 2,
    BladeTerminology: 0,
    BladeConstruction: 1,
    ToothOptions: 2,
    CuttingFactors: 3,
    BeamStrength: 4,
    Lubrication: 5,
    BreakInInstructions: 6,
    BasicMaintenanceTips: 7,
    CommonProblems: 8,
    GlossaryOfBandsawTerms: 9,
    LENOXAdvantage: 3,
  },
  tabsUser: {
    Dashboard: 0,
    Library: 1,
    Machines: 0,
    Materials: 1,
    SawCalc: 2,
    BandSawGuide: 3,
    BladeTerminology: 0,
    BladeConstruction: 1,
    ToothOptions: 2,
    CuttingFactors: 3,
    BeamStrength: 4,
    Lubrication: 5,
    BreakInInstructions: 6,
    BasicMaintenanceTips: 7,
    CommonProblems: 8,
    GlossaryOfBandsawTerms: 9,
    LENOXAdvantage: 4,
    Account: 5,
  },
  tabsAdmin: {
    Dashboard: 0,
    Library: 1,
    Machines: 0,
    Materials: 1,
    ProductLines: 2,
    Recommendations: 3,
    EngineParameters: 4,
    DictionaryUI: 5,
    DictionaryWS: 6,
    Translation: 7,
    SawCalc: 2,
    BandSawGuide: 3,
    BladeTerminology: 0,
    BladeConstruction: 1,
    ToothOptions: 2,
    CuttingFactors: 3,
    BeamStrength: 4,
    Lubrication: 5,
    BreakInInstructions: 6,
    BasicMaintenanceTips: 7,
    CommonProblems: 8,
    GlossaryOfBandsawTerms: 9,
    LENOXAdvantage: 4,
    Account: 5,
  },
  side: {
    Home: 2,
    SawCalc: 3,
    BandSawGuide: 4,
    BladeTerminology: 0,
    BladeConstruction: 1,
    ToothOptions: 2,
    CuttingFactors: 3,
    BeamStrength: 4,
    Lubrication: 5,
    BreakInInstructions: 6,
    BasicMaintenanceTips: 7,
    CommonProblems: 8,
    GlossaryOfBandsawTerms: 9,
    LENOXAdvantage: 5,
    Phone: 7,
    Login: 8,
    Logoff: 0,
  },
  sideUser: {
    Home: 2,
    Dashboard: 3,
    Library: 4,
    Machines: 0,
    Materials: 1,
    SawCalc: 5,
    BandSawGuide: 6,
    BladeTerminology: 0,
    BladeConstruction: 1,
    ToothOptions: 2,
    CuttingFactors: 3,
    BeamStrength: 4,
    Lubrication: 5,
    BreakInInstructions: 6,
    BasicMaintenanceTips: 7,
    CommonProblems: 8,
    GlossaryOfBandsawTerms: 9,
    LENOXAdvantage: 7,
    Account: 9,
    Phone: 11,
    Logoff: 12,
  },
  sideAdmin: {
    Home: 2,
    Dashboard: 3,
    Library: 4,
    Machines: 0,
    Materials: 1,
    ProductLines: 2,
    Recommendations: 3,
    EngineParameters: 4,
    DictionaryUI: 5,
    DictionaryWS: 6,
    Translation: 7,
    SawCalc: 5,
    BandSawGuide: 6,
    BladeTerminology: 0,
    BladeConstruction: 1,
    ToothOptions: 2,
    CuttingFactors: 3,
    BeamStrength: 4,
    Lubrication: 5,
    BreakInInstructions: 6,
    BasicMaintenanceTips: 7,
    CommonProblems: 8,
    GlossaryOfBandsawTerms: 9,
    LENOXAdvantage: 7,
    Account: 9,
    Phone: 11,
    Logoff: 12,
  },
  menu: {
    BandSawGuide: 0,
    Library: 1,
  },
  footer: {
    TermsOfUse: 0,
    PrivacyPolicy: 2,
    CookieSettings: 4,
    Copywrite: 5,
  },
};

export { navigation };
