import { returnCode } from "@/constants/ReturnCode";

const api = {
  headers(getters) {
    const headers = {
      "Base-Locale": getters.language != null ? getters.language.locale : "",
      "User-Email": getters.user != null ? getters.user.email : "",
      "Access-Token": getters.token,
    };

    return headers;
  },
  responseError(response) {
    if (!response || response == undefined) {
      return { code: returnCode.ErrBadRequest, message: "" };
    } else if (response.status == undefined) {
      return null;
    } else if (response.status == 200) {
      const headers = response.headers;

      if (
        headers != undefined &&
        headers != null &&
        headers.error != null &&
        headers.error != ""
      ) {
        return JSON.parse(headers.error);
      } else {
        return null;
      }
    } else {
      return { code: response.status, message: response.statusText };
    }
  },
  authenticationError(code) {
    const authenticationErrors = [
      returnCode.ErrAccessTokenChanged,
      returnCode.ErrAICInvalidAccessToken,
      returnCode.ErrAICAuthCodeNotValid,
      returnCode.ErrAICTokenExpired,
    ];

    if (authenticationErrors.includes(code)) {
      return true;
    } else {
      return false;
    }
  },
  authErrorRedirectTimeout: 2000,
};

export { api };
