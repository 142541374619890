const browserCheck = {
    getBrowser() {
        var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
          tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
          return { name: 'IE', version: (tem[1] || '') };
        }
        if (M[1] === 'Chrome') {
          tem = ua.match(/\bOPR\/(\d+)/)
          if (tem != null) { return { name: 'Opera', version: tem[1] }; }
        }
        if (window.navigator.userAgent.indexOf("Edge") > -1) {
          // eslint-disable-next-line no-useless-escape
          tem = ua.match(/\Edge\/(\d+)/)
          if (tem != null) { return { name: 'Edge', version: tem[1] }; }      
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
       
        return {
          name: M[0],
          version: +M[1]
        };
      },
      supportedBrowser() {
        var browser = this.getBrowser();
        
        var supported = false;
       
        if (browser.name === "Chrome" && browser.version > 78) {
          supported = true;

        } else if (browser.name === "Firefox" && browser.version >= 83) {
          supported = true;
        } else if (browser.name === "Safari" && browser.version >= 11) {
          supported = true;
        } else if (browser.name === "Opera" && browser.version >= 72) {
          supported = true;
        } else if ((browser.name === "MSIE" || browser.name === "IE")) {
          supported = false;
        } else if (browser.name === "Edge") {
          supported = false;
        }
        
        return supported;
      }
}

export { browserCheck };