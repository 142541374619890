const footer = {
  statusBar: {
    TermsOfUse: 0,
    PrivacyPolicy: 2,
    CookieSettings: 4,
    Copywrite: 5,
  },
  info: {
    contact: {
      LenoxTools: 0,
      Contact: 1,
    },
    social: {},
  },
};

export { footer };
