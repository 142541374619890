<template>
  <nav>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      top
      :color="snackbar.color"
    >
      <span>{{ snackbar.message }}</span>
      <template v-slot:action>
        <v-btn color="white" dark text @click="closeSnackbar">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-app-bar app flat light class="white">
      <v-app-bar-nav-icon
        v-if="appBarNavIconVisible"
        @click.stop="openNavigationDrawer(false)"
      ></v-app-bar-nav-icon>

      <template v-for="(item, i) in navigation.toolbar">
        <template v-if="item.heading && item.visible">
          <v-toolbar-title
            :key="`${i}-toolbar-link`"
            @click="navigationClicked(item)"
          >
            <img src="@/assets/logo.png"  class="logo" />
          </v-toolbar-title>
        </template>

        <v-spacer v-else-if="item.spacer" :key="`${i}-toolbar-link`"></v-spacer>

        <template v-else-if="item.hiddenXsOnlyButton && item.visible">
          <v-toolbar-items :key="`${i}-toolbar-link`">
            <v-btn
              text
              class="nav primary--text hidden-xs-only"
              @click="navigationClicked(item)"
              >{{ item.title }}</v-btn
            >
          </v-toolbar-items>
        </template>

        <template v-else-if="item.hiddenXsOnlyIcon && item.visible">
          <v-toolbar-items :key="`${i}-toolbar-link`">
            <v-btn
              icon
              class="primary--text hidden-xs-only"
              @click="navigationClicked(item)"
            >
              <v-icon>{{ item.icon }}</v-icon>
            </v-btn>
          </v-toolbar-items>
        </template>

        <template v-else-if="item.hiddenSmAndDownButton && item.visible">
          <v-toolbar-items :key="`${i}-toolbar-link`">
            <v-btn
              text
              class="nav primary--text hidden-sm-and-down"
              @click="navigationClicked(item)"
              >{{ item.title }}</v-btn
            >
          </v-toolbar-items>
        </template>

        <template v-else-if="item.hiddenSmAndDownMenu && item.visible">
          <v-toolbar-items :key="`${i}-toolbar-link`">
            <v-menu left bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon class="nav primary--text" v-on="on"
                  ><flag
                    :iso="language.flag"
                    v-bind:squared="false"
                    class="flag"
                  />{{ language.locale }}</v-btn
                >
              </template>

              <v-list>
                <v-list-item
                  v-for="language in item.items"
                  :key="language.title"
                  @click="changeLanguage(language)"
                >
                  <v-list-item-title
                    ><flag
                      :iso="language.flag"
                      v-bind:squared="false"
                      class="flag"
                    />{{ language.title }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar-items>
        </template>
      </template>

      <template v-slot:extension v-if="tabsVisible">
        <v-tabs
          v-for="(item, i) in tabs"
          :key="`${i}-tab-link`"
          fixed-tabs
          grow
          background-color="transparent"
          slider-color="primary"
          slider-size="2"
        >
          <template v-if="item.tab">
            <v-tab
              :to="{ name: item.name, params: { url: item.url } }"
              class="nav"
              :class="{
                'primary--text': !tabActive(item.route, item.menuIndex),
                'secondary--text': tabActive(item.route, item.menuIndex),
              }"
              >{{ item.title }}</v-tab
            >
          </template>

          <template v-else-if="item.menu">
            <v-menu v-if="item.items" offset-y>
              <template v-slot:activator="{ on }">
                <v-tab
                  class="nav"
                  :class="{
                    'primary--text': !tabActive(item.route, item.menuIndex),
                    'secondary--text': tabActive(item.route, item.menuIndex),
                  }"
                  v-on="on"
                  >{{ item.title }}</v-tab
                >
              </template>

              <v-list class="grey lighten-3">
                <v-list-item-group>
                  <v-list-item
                    v-for="(itm, i) in item.items"
                    :key="`${i}-tab-menu-link`"
                    class="primary--text"
                    :class="{
                      'list-item-active': menuItemActive(itm),
                      'list-item-not-active': !menuItemActive(itm),
                    }"
                    @click="navigationClicked(itm)"
                  >
                    {{ itm.title }}
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </template>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" temporary width="320px">
      <v-container>
        <v-row>
          <v-col class="pa-0 px-2">
            <v-card flat>
              <v-list class="pt-1">
                <template v-for="(item, i) in side">
                  <v-container
                    v-if="item.heading"
                    :key="`${item.title}-sidebar-link`"
                    align-center
                    class="pa-0 pb-3"
                  >
                    <v-row>
                      <v-col cols="10">
                        <img
                          src="@/assets/logo.svg"
                          class="pl-4 logo"
                          @click="navigationClicked(item)"
                        />
                      </v-col>
                      <v-col cols="2" class="pa-0">
                        <v-btn
                          v-if="sawCalcNavIconVisible"
                          icon
                          class="pr-5"
                          large
                          color="primary"
                          @click="openSawCalcDrawer()"
                        >
                          <v-icon>mdi-calculator</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>

                  <v-divider v-else-if="item.divider" :key="i" dark />

                  <template v-else-if="item.tab && item.action !== 'link'">
                    <v-list-item
                      :key="`${item.title}-sidebar-link`"
                      :class="{
                        'primary--text': !tabActive(item.route, item.menuIndex),
                        'secondary--text': tabActive(
                          item.route,
                          item.menuIndex
                        ),
                      }"
                      @click="navigationClicked(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <template v-else-if="item.tab && item.action === 'link'">
                    <v-list-item
                      :to="{ name: item.name, params: { url: item.url } }"
                      :key="`${item.title}-sidebar-link`"
                      :class="{
                        'primary--text': !tabActive(item.route, item.menuIndex),
                        'secondary--text': tabActive(
                          item.route,
                          item.menuIndex
                        ),
                      }"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <v-list-group
                    v-else-if="item.menu"
                    no-action
                    :key="`${item.title}-sidebar-link`"
                    :class="{
                      'primary--text': !tabActive(item.route, item.menuIndex),
                      'secondary--text': tabActive(item.route, item.menuIndex),
                    }"
                    :value="tabActive(item.route, item.menuIndex)"
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.title }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item
                      v-for="itm in item.items"
                      :to="{ name: itm.name, params: { url: itm.url } }"
                      :key="`${itm.title}-sidebar-link`"
                      link
                    >
                      <v-list-item-title>
                        {{ itm.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-group>
                </template>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>

    <v-navigation-drawer
      app
      v-model="sawCalcDrawerLocal"
      temporary
      width="320px"
    >
      <v-container>
        <v-row>
          <v-col cols="10">
            <img
              src="@/assets/logo.svg"
              class="pl-4 logo"
              @click="navigationClicked(homeLink)"
            />
          </v-col>
          <v-col cols="2" class="pa-0">
            <v-btn
              v-if="sawCalcNavIconVisible"
              icon
              class="pr-5"
              large
              color="primary"
              @click="openNavigationDrawer(true)"
            >
              <v-icon>mdi-backburger</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0 ma-0 primary" style="height: 2px;"></v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="py-0">
            <InputUOM />
            <InputMachine />
            <InputMaterial />
            <InputDimensions />
            <InputVising />
            <InputConditions />
            <InputCurrency />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pl-5">
            <v-container
              fluid
              class="pa-0"
              :class="{
                'controls-small-max': $vuetify.breakpoint.mdAndDown,
                'controls-large-max': $vuetify.breakpoint.lgAndUp,
              }"
            >
              <v-row>
                <v-col class="pa-0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="clear()">
                        <v-icon>mdi-restart-alert</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('sawCalc.navBar.clear') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col class="pa-0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="loading || !reportEnabled"
                        @click="getRecommendationReport()">
                        <v-icon>mdi-printer</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('sawCalc.navBar.print') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col v-if="user" class="pa-0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="loading || !reportEnabled"
                        @click="emailRecommendationReport()">
                        <v-icon>mdi-email</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('sawCalc.navBar.email') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col class="pa-0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="refresh()">
                        <v-icon>mdi-cached</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('sawCalc.navBar.refresh') }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row>
                <v-col style="height: 80px;">
                  &nbsp;
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";
import { navigation } from "@/constants/Navigation";

import InputUOM from "@/components/public/SawCalc/Inputs/InputUOMSide";
import InputMachine from "@/components/public/SawCalc/Inputs/InputMachineSide";
import InputMaterial from "@/components/public/SawCalc/Inputs/InputMaterialSide";
import InputDimensions from "@/components/public/SawCalc/Inputs/InputDimensionsSide";
import InputVising from "@/components/public/SawCalc/Inputs/InputVisingSide";
import InputConditions from "@/components/public/SawCalc/Inputs/InputConditionsSide";
import InputCurrency from "@/components/public/SawCalc/Inputs/InputCurrencySide";

import { api } from "@/helpers/ApiCalls";

export default {
  name: "NavBar",

  components: {
    InputUOM,
    InputMachine,
    InputMaterial,
    InputDimensions,
    InputVising,
    InputConditions,
    InputCurrency,
  },

  data() {
    return {
      snackbarTest: true,
      drawer: false,
      sawCalcDrawerLocal: false,
      loading: false,
      reportEnabled: false,
    };
  },

  computed: {
    ...mapGetters([
      "user",
      "navigation",
      "languages",
      "language",
      "snackbar",
      "sawCalcDrawer",
      "recommendations",
      "recommendationUpdated",
    ]),
    tabs() {
      if (!this.user) {
        return this.navigation.tabs;
      } else if (!this.user.admin) {
        return this.navigation.tabsUser;
      } else if (this.user.admin) {
        return this.navigation.tabsAdmin;
      } else {
        return this.navigation.tabs;
      }
    },
    side() {
      if (!this.user) {
        return this.navigation.side;
      } else if (!this.user.admin) {
        return this.navigation.sideUser;
      } else if (this.user.admin) {
        return this.navigation.sideAdmin;
      } else {
        return this.navigation.side;
      }
    },
    bandSawGuide() {
      if (!this.user) {
        return navigation.tabs.BandSawGuide;
      } else if (!this.user.admin) {
        return navigation.tabsUser.BandSawGuide;
      } else if (this.user.admin) {
        return navigation.tabsAdmin.BandSawGuide;
      } else {
        return navigation.tabs.BandSawGuide;
      }
    },
    library() {
      if (!this.user) {
        return navigation.tabs.Library;
      } else if (!this.user.admin) {
        return navigation.tabsUser.Library;
      } else if (this.user.admin) {
        return navigation.tabsAdmin.Library;
      } else {
        return navigation.tabs.Library;
      }
    },
    appBarNavIconVisible() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return true;
        case "md":
        case "lg":
        case "xl":
        default:
          return this.$route.name == "SawCalc";
      }
    },
    sawCalcNavIconVisible() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return this.$route.name == "SawCalc";
        case "md":
        case "lg":
        case "xl":
        default:
          return false;
      }
    },
    tabsVisible() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return false;
        case "md":
        case "lg":
        case "xl":
        default:
          return (
            this.$route.name != "SawCalc" &&
            this.$route.name != "UpdatePassword" &&
            this.$route.name != "EmailVerification"
          );
      }
    },
    homeLink() {
      return { action: "link", name: "Home" };
    },
  },

  methods: {
    changeLanguage(language) {
      this.loading = true;

      this.$store
        .dispatch("changeLanguage", { language: language, reload: false })
        .then(
          // eslint-disable-next-line no-unused-vars
          (response) => {
            this.loading = false;
          },
          (error) => {
            this.loading = false;

            const notification = {
              show: true,
              result: false,
              message: error,
            };

            this.$store.dispatch("showSnackbar", notification);

            // eslint-disable-next-line
            console.error("response: ", error);
          }
        );
    },
    closeSnackbar() {
      this.$store.dispatch("closeSnackbar");
    },
    tabActive(path, menuIndex) {
      const currentPath = router.currentRoute.fullPath;

      if (menuIndex != null) {
        if (menuIndex === navigation.menu.BandSawGuide) {
          return this.tabActiveMenu(path, currentPath, this.bandSawGuide);
        } else if (menuIndex === navigation.menu.Library) {
          return this.tabActiveMenu(path, currentPath, this.library);
        }
      }

      return currentPath.substring(1, currentPath.length) === path;
    },
    tabActiveMenu(path, currentPath, index) {
      const items = this.tabs[index].items;

      const result = items.find(
        ({ route }) => route === currentPath.substring(1, currentPath.length)
      );

      if (result) {
        const route = this.tabs[index].route;
        return path === route;
      }
    },
    menuItemActive(item) {
      const currentPath = router.currentRoute.fullPath;
      return item.route == currentPath.substring(1, currentPath.length);
    },
    navigationClicked(link) {
      if (link.action == "link") {
        this.routeLink(link.name);
      } else if (link.action == "url") {
        this.openUrl(link.path);
      } else if (link.action == "phone") {
        this.dialPhone(link.path);
      } else if (link.action == "showLoginDialog") {
        this.$store.dispatch("showLoginDialog", true);
      } else if (link.action == "logoff") {
        this.logoff();
      }
    },
    routeLink(name) {
      router.push({ name: name }).catch(() => {});
    },
    openUrl(url) {
      window.open(url);
    },
    dialPhone(phone) {
      window.open("tel:" + phone.replace(/[^0-9]/g, ""), "_self", false);
    },
    logoff() {
      this.loading = true;

      this.$store.dispatch("logoff").then(
        (response) => {
          this.loading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
          router.push({ name: "Home" }).catch(() => {});
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);

          if (api.authenticationError(error.code)) {
            setTimeout(function() {
              router.push({ name: "Home" }).catch(() => {});
            }, api.authErrorRedirectTimeout);
          }
        }
      );
    },
    openNavigationDrawer(home) {
      if (
        !home &&
        this.sawCalcNavIconVisible &&
        this.$route.name === "SawCalc"
      ) {
        this.openSawCalcDrawer();
        return;
      }

      this.sawCalcDrawerLocal = false;
      this.drawer = true;
    },
    openSawCalcDrawer() {
      this.drawer = false;
      this.sawCalcDrawerLocal = true;
    },
    clear() {
      this.$store.dispatch("resetInputs", null);

      this.drawer = false;
      this.sawCalcDrawerLocal = false;
      this.$store.dispatch("checkInputs");
    },
    getRecommendationReport() {
      this.loading = true;

      this.$store.dispatch("getRecommendationReport").then(
        (response) => {
          this.loading = false;
          const notification = {
            show: true,
            result: true,
            message: response.message,
            delay: 8000,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    emailRecommendationReport() {
      this.loading = true;

      this.$store.dispatch("emailRecommendationReport").then(
        (response) => {
          this.loading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
            delay: 8000,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    refresh() {
      this.$store.dispatch("resetSawCalcReady");
      this.$store.dispatch("getSawCalcData");

      this.drawer = false;
      this.sawCalcDrawerLocal = false;
    },
    updateControls() {
      this.reportEnabled = false;

      this.recommendations.forEach((item) => {
        if (item != null && item.cutTime > 0) {
          this.reportEnabled = true;
          return;
        }
      });
    },
  },

  watch: {
    sawCalcDrawer(val) {
      if (val && this.sawCalcNavIconVisible) {
        this.sawCalcDrawerLocal = val;
      }
    },
    recommendationUpdated() {
      this.updateControls();
    },
  },

  mounted() {
    this.updateControls();
  },
};
</script>

<style lang="scss" scoped>
.flag {
  margin-right: 7px;
}

.nav {
  color: map-get($theme, primary-color);
  position: relative;
  cursor: pointer;
  letter-spacing: -0.2px;
  font-size: map-get($theme, font-size-secondary);
  font-family: map-get($theme, font-family-primary), sans-serif;
  font-weight: map-get($theme, font-weight-primary-navigation);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
}

.logo {
  display: block;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 35px;
  cursor: pointer;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: map-get($theme, primary-color);
}

.theme--light.v-tabs > .v-tabs-bar .v-tab--active,
.theme--light.v-tabs > .v-tabs-bar .v-tab--active > .v-icon {
  color: map-get($theme, secondary-color);
}

.v-tab:before {
  background-color: transparent;
}

.v-tabs-slider {
  width: 100%;
}

.list-item-active {
  border-left: 3px solid map-get($theme, secondary-color);
}

.list-item-not-active {
  border-left: none;
}

.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 40px;
}

.v-list-item--link:before {
  background-color: transparent;
}

.button-small {
  width: 146px;
}

.button-large {
  width: 184px;
}
</style>
