import Vue from "vue";
import Vuetify from "vuetify/lib";
//import variables from "../styles/_variables.scss";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  theme: {
    light: true,
    themes: {
      light: {
        primary:  '#0067b1',
        secondary: '#e21836',
        accent: '#82b1ff',
        error: '#ff5252',
        info: '#2196f3',
        success: '#4caf50',
        warning:  '#fb8c00'
      }

      // light: {
      //   primary: variables.primaryColor,
      //   secondary: variables.secondaryColor,
      //   accent: variables.accentColor,
      //   error: variables.errorColor,
      //   info: variables.infoColor,
      //   success: variables.successColor,
      //   warning: variables.warningColor
      // }
    }
  }
});
